import { API_ROOT, API, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { AxiosRequestConfig } from "axios"
import {
  fetchDealIabContentCategoriesSuccess,
  fetchDealIabContentCategoriesFailed,
  fetchDealIabContentCategories,
} from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"

export function* fetchDealIabContentCategoriesSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/iab-content-categories/find`,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchDealIabContentCategoriesSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchDealIabContentCategoriesFailed, err)
  }
}

export function* watchFetchDealIabContentCategories(): SagaIterator {
  yield takeEvery(fetchDealIabContentCategories, fetchDealIabContentCategoriesSaga)
}
