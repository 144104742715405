import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchSiteBlockTypes,
  fetchSiteBlockTypesFailed,
  fetchSiteBlockTypesSuccess,
  setSiteFormLoadingBlockTypesFlag,
} from "../actions/siteActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { SagaIterator } from "redux-saga"

export function* fetchSiteBlockTypesSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/block-types/find`,
  }
  yield put(setSiteFormLoadingBlockTypesFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchSiteBlockTypesSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchSiteBlockTypesFailed, err)
  }
  yield put(setSiteFormLoadingBlockTypesFlag(false))
}

export function* watchFetchSiteBlockTypes(): SagaIterator {
  yield takeEvery(fetchSiteBlockTypes, fetchSiteBlockTypesSaga)
}
