import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Hidden,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import {
  CreateCompanyPayload,
  UpdateCompanyPayload,
} from "../../../../../../redux/types/companyTypes"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
import { createCompany, updateCompany } from "../../../../../../redux/actions/companyActions"
import {
  generateSegmentVendorDropdownOptions,
  trimPayloadProperties,
} from "../../../../../../helpers/formatterHelper"

import Autocomplete from "@material-ui/lab/Autocomplete"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import Checkbox from "@material-ui/core/Checkbox"
import { ENTITY_STATUSES } from "../../../../../../constants"
import ErrorMessages from "../../../../../common/Errors"
import { Formik } from "formik"
import { VendorOption } from "../../../../../../redux/types/segmentVendorTypes"
import _ from "lodash"
import clsx from "clsx"
import dayjs from "dayjs"
import { fetchSegmentVendors } from "../../../../../../redux/actions/segmentVendorActions"
import { generalError } from "../../../../../../redux/actions/errorActions"
import styles from "./styles"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const CompanyForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()
  const company = useSelector((state: RootState) => state.companyForm.selectedCompany)
  const isSaved = useSelector((state: RootState) => state.companyForm.isSaved)
  const isLoading = useSelector((state: RootState) => state.companyForm.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.companyForm.isSubmitting)
  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isCreate = endPath === "create"
  const isEdit = endPath === "edit"
  const isInfo = !isEdit && !isCreate

  const isSegmentVendorsLoading = useSelector((state: RootState) => state.segmentVendors.isLoading)
  const segmentVendors = useSelector((state: RootState) => state.segmentVendors.segmentVendors)
  const segmentVendorDropdownOptions = generateSegmentVendorDropdownOptions(
    segmentVendors,
    isSegmentVendorsLoading,
    true
  )

  const shouldRenderForm = !(isSegmentVendorsLoading && isLoading)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    segmentVendors.length === 0 && dispatch(fetchSegmentVendors())
    // when the company is successfully created navigate back to companies list
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isSaved && history.push(`/admin/companies`)
  }, [company, isSaved])

  const selectedCompanySegmentVendors = company?.segmentVendors || []
  const MATERIAL_UI_REMOVE_OPTION = "remove-option"

  const companyStatusOptions = [
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
    { value: ENTITY_STATUSES.ARCHIVED, label: "Archived" },
  ]

  const initialFormValues = {
    name: company.name || "",
    notes: company.notes || "",
    status: company.status || "",
    segmentVendors: company.segmentVendors ? company.segmentVendors : [],
  }

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().max(255),
    notes: Yup.string().max(255),
    status: Yup.string().max(255),
    segmentVendors: Yup.array(Yup.object()),
  })

  const handleFormikSubmit = (values) => {
    const selectedSegmentVendors = values.segmentVendors.map((obj) => ({ id: obj.id }))
    values.segmentVendors = selectedSegmentVendors ? selectedSegmentVendors : []
    if (!isEdit) {
      dispatch(
        createCompany(trimPayloadProperties(_.omit(values, ["status"])) as CreateCompanyPayload)
      )
    } else if (isEdit) {
      dispatch(
        updateCompany(
          trimPayloadProperties({
            id: company.id,
            ...values,
          }) as UpdateCompanyPayload
        )
      )
    }
  }

  return (
    <>
      {shouldRenderForm ? (
        <Box mt={3} mb={3}>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => handleFormikSubmit(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
            }) => {
              return (
                <>
                  <ErrorMessages />
                  <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                    <Card>
                      <CardContent className={classes.companyFormCardLayout}>
                        <Grid container spacing={3} className={classes.roundedBorder}>
                          {/* Name */}
                          <Grid item md={6}>
                            <TextField
                              error={!!(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Company Name
                                </span>
                              }
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                              inputProps={{
                                "data-testid": "company-form-name-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isLoading || isInfo}
                            />
                            {company.createdAt && (
                              <div
                                className={classes.timestamps}
                                data-testid={"company-form-timestamps"}
                              >
                                <Typography>
                                  <b>Created on: </b>
                                  {dayjs(company?.createdAt).format("M-D-YYYY h:mm a")}
                                  <b> Last updated: </b>
                                  {dayjs(company?.updatedAt).format("M-D-YYYY h:mm a")}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                          {/* Status */}
                          {isEdit || isInfo ? (
                            <Grid item md={3}>
                              <TextField
                                select
                                label={
                                  <span className={clsx(isInfo && classes.overrideDisable)}>
                                    Company Status
                                  </span>
                                }
                                name="status"
                                value={values.status}
                                onChange={(e) => {
                                  setFieldValue("status", e.target.value)
                                }}
                                className={classes.dropDownField}
                                inputProps={{
                                  "data-testid": "company-form-status-input",
                                }}
                                InputProps={{
                                  classes: {
                                    disabled: classes.overrideDisable,
                                  },
                                }}
                                disabled={isLoading || isInfo}
                              >
                                {companyStatusOptions.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          ) : (
                            <Hidden smDown>
                              <Grid item md={6} />
                            </Hidden>
                          )}

                          {/* Segment Vendors */}
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              multiple
                              disableClearable
                              id="multiselect-vendors"
                              data-testid={"company-form-vendor-input"}
                              options={segmentVendorDropdownOptions}
                              disableCloseOnSelect
                              value={values.segmentVendors}
                              getOptionSelected={(option, value) => value.id === option.id}
                              getOptionLabel={(option) => (option ? option.name : "")}
                              onChange={(e, newValue: VendorOption[], reason) => {
                                if (reason === MATERIAL_UI_REMOVE_OPTION) {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                  selectedCompanySegmentVendors.every(
                                    (selectedCompanySegmentVendor) =>
                                      newValue.some(
                                        (newSegmentVendor) =>
                                          newSegmentVendor.id === selectedCompanySegmentVendor.id
                                      )
                                  )
                                    ? setFieldValue("segmentVendors", newValue)
                                    : dispatch(
                                        generalError(
                                          "Cannot remove Segment Vendor to Company association. Please work with Product Management to remove Segment Vendors."
                                        )
                                      )
                                } else {
                                  setFieldValue("segmentVendors", newValue)
                                }
                              }}
                              renderOption={(option, { selected }) => (
                                <>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name} {selected}
                                </>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  name={"segmentVendors"}
                                  label={
                                    <span
                                      className={clsx(
                                        isInfo &&
                                          values.segmentVendors.length > 0 &&
                                          classes.overrideDisable
                                      )}
                                    >
                                      Segment Vendors
                                    </span>
                                  }
                                  data-testid={"segment-vendors"}
                                  InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                      disabled: classes.overrideDisable,
                                    },
                                  }}
                                />
                              )}
                              disabled={isLoading || isInfo}
                            />
                          </Grid>
                          <Hidden smDown>
                            <Grid item md={6} />
                          </Hidden>
                          {/* Notes */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={!!(touched.notes && errors.notes)}
                              fullWidth
                              multiline
                              helperText={touched.notes && errors.notes}
                              label={
                                <span
                                  className={clsx(
                                    isInfo && values.notes && classes.overrideDisable
                                  )}
                                >
                                  Notes
                                </span>
                              }
                              name="notes"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.notes}
                              variant="outlined"
                              inputProps={{
                                "data-testid": "company-form-notes-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isLoading || isInfo}
                            />
                          </Grid>
                        </Grid>
                        {!isInfo && (
                          <Box mt={2}>
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={isSubmitting}
                              data-testid={"company-form-submit-button"}
                            >
                              {isCreate && "Create Company"}
                              {isEdit && "Update Company"}
                            </Button>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </form>
                </>
              )
            }}
          </Formik>
        </Box>
      ) : (
        <Box mt={3} mb={3}>
          <Card>
            <CardContent className={classes.loadingForm}>
              <CircularProgress />
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

export default withStyles(styles)(CompanyForm)
