import { Theme, createStyles } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    dropDownField: {
      margin: theme.spacing(1),
      width: "97%",
    },
    filerListButton: {
      margin: theme.spacing(1),
    },
    filterListButtonContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "9px 9px 9px 0",
    },
    domainFilterTypeInput: {
      [theme.breakpoints.up("md")]: {
        marginTop: "56px",
      },
    },
    audienceListAccordion: {
      marginTop: theme.spacing(2),
    },
    dmpSegmentFeeField: {
      marginTop: theme.spacing(2),
    },
    dealIdText: {
      paddingLeft: "4px",
    },
    automaticValueOptimizationButton: {
      border: "1px solid gray",
      borderRadius: "5px",
      padding: "15px 15px",
      textAlign: "center",
      backgroundColor: "#e8f5e9",
    },
    cardStepperPadding: { padding: 24 },
    buttonProgress: {
      color: theme.palette.black,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    dealFormButtonSpacing: {
      margin: theme.spacing(1),
    },

    // TODO: Potentially put this higher up in the css chain, same styles being used in segment groups css file
    segmentGroup: {
      fontWeight: 900,
      marginRight: "10px",
    },
    listSubheader: {
      display: "flex",
      padding: "12px 0",
    },
    formFieldErrors: {
      marginBottom: "10px",
    },

    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
      "& .MuiChip-root ": {
        opacity: "1",
      },
    },
    roundedBorder: {
      border: "1px solid lightgray",
      borderRadius: 5,
      color: "grey",
      marginBottom: "10px",
    },
    slider: {
      color: "lightgray",
      "& .MuiSlider-thumb": {
        backgroundColor: "grey",
      },
      "& .MuiSlider-track": {
        backgroundColor: "grey",
      },
      "& .MuiSlider-rail": {
        backgroundColor: "lightgray",
      },
    },
  })
}
