import React, { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { removeToken } from "../../helpers/loginServices"
import { handleSSLogout } from "../../helpers/sagaHelpers"
import Loading from "../Loading"

const Logout = ({ message }: { message: string }) => {
  const { logout } = useAuth0()
  const [isCampaignBiPmgSessionTokenCleared, setIsCampaignBiPmgSessionTokenCleared] =
    useState(false)

  useEffect(() => {
    handleSSLogout()
      .then(() => setIsCampaignBiPmgSessionTokenCleared(true))
      .catch(() => setIsCampaignBiPmgSessionTokenCleared(true))
  })

  useEffect(() => {
    if (isCampaignBiPmgSessionTokenCleared) {
      removeToken()
      setTimeout(() => {
        logout({ returnTo: window.location.origin })
      }, 1)
    }
  }, [isCampaignBiPmgSessionTokenCleared])

  const logoutMessage = message || "Your session has expired. Please login and try again."
  return <Loading message={logoutMessage} />
}

export default Logout
