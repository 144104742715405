import { Theme, createStyles } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    tagFormCardLayout: {
      padding: 24,
    },
    loadingForm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
      weight: "100%",
    },
    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
      "& .MuiChip-root ": {
        opacity: "1",
      },
    },
    roundedBorder: {
      border: "1px solid lightgray",
      borderRadius: 5,
      color: "grey",
      marginBottom: "10px",
    },
    dropDownField: {
      margin: theme.spacing(1),
      width: "97%",
    },
    disableInputCursor: {
      "& .MuiInput-input": {
        cursor: "not-allowed",
      },
    },
  })
}
