import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { updateUser, updateUserFailed, updateUserSuccess } from "../actions/userActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { UpdateUserPayload } from "../types/userTypes"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

export function* updateUserSaga(action: PayloadAction<UpdateUserPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/users/${action.payload.id}`,
    data: {
      user: _.pick(action.payload, [
        "email",
        "firstName",
        "lastName",
        "notes",
        "companyId",
        "status",
        "roles",
      ]),
    },
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateUserSuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateUserFailed, err)
  }
}

export function* watchUpdateUser(): SagaIterator {
  yield takeEvery(updateUser, updateUserSaga)
}
