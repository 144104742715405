import { API_ROOT, API, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { AxiosRequestConfig } from "axios"
import {
  fetchDealDomainsSuccess,
  fetchDealDomainsFailed,
  fetchDealDomains,
} from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { FetchDealDomainsPayload } from "../types/dealTypes"

export function* fetchDealDomainsSaga(action: PayloadAction<FetchDealDomainsPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/domains/find`,
    data: {
      filters: {
        term: action.payload.term,
      },
      pagination: {
        limit: 100,
        page: action.payload.page,
      },
    },
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(
      fetchDealDomainsSuccess({
        domains: response.data,
        page: parseInt(response.headers["x-pagination-page"]),
        clear: action.payload.clear,
      })
    )
  } catch (err) {
    yield handleSagaError(fetchDealDomainsFailed, err)
  }
}

export function* watchFetchDealDomains(): SagaIterator {
  yield takeEvery(fetchDealDomains, fetchDealDomainsSaga)
}
