import { createStyles } from "@material-ui/core/styles"

export default () => {
  return createStyles({
    logo: {
      width: 250,
      height: 100,
    },
    loadingMessage: {
      textAlign: "center",
    },
  })
}
