import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchAudiencePopulation,
  fetchAudiencePopulationFailed,
  fetchAudiencePopulationSuccess,
} from "../actions/audienceActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchAudiencePopulationPayload } from "../types/audienceTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

export function* fetchAudiencePopulationSaga(
  action: PayloadAction<FetchAudiencePopulationPayload>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/audiences/population`,
    data: action.payload,
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(
      fetchAudiencePopulationSuccess({
        ip: response.data.ip,
        cookie: response.data.cookie,
      })
    )
  } catch (err) {
    yield handleSagaError(fetchAudiencePopulationFailed, err)
  }
}

export function* watchFetchAudiencePopulation(): SagaIterator {
  yield takeEvery(fetchAudiencePopulation, fetchAudiencePopulationSaga)
}
