import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchTags,
  fetchTagsFailed,
  fetchTagsSuccess,
  setTagsLoadingFlag,
} from "../actions/tagActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchTagsPayload } from "../types/tagTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"

export function* fetchTagsSaga(action: PayloadAction<FetchTagsPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.publisherId}/sites/${action.payload.siteId}/tags/find`,
    data: action.payload,
  }
  yield put(setTagsLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page = parseInt(response.headers["x-pagination-page"], 10)
    const totalCount = parseInt(response.headers["x-pagination-total-count"], 10)
    yield put(
      fetchTagsSuccess({
        tags: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchTagsFailed, err)
  }
  yield put(setTagsLoadingFlag(false))
}

export function* watchFetchTags(): SagaIterator {
  yield takeEvery(fetchTags, fetchTagsSaga)
}
