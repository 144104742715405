import { API_ROOT, API, SECTION } from "../../constants"
import _ from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import { AxiosRequestConfig } from "axios"
import { fetchDealByIdSuccess, fetchDealByIdFailed, fetchDealById } from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { forwardTo } from "../../helpers/historyHelper"
import { SagaIterator } from "redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"

export function* fetchDealByIdSaga(action: PayloadAction<number>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/${action.payload}`,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchDealByIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") === "not_found" || "unknown") {
      yield call(forwardTo, "/tools/deals")
    }
    yield handleSagaError(fetchDealByIdFailed, err)
  }
}

export function* watchFetchDealById(): SagaIterator {
  yield takeEvery(fetchDealById, fetchDealByIdSaga)
}
