import { Theme, createStyles } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    roleFormCardLayout: {
      padding: 24,
    },
    dropDownField: {
      margin: theme.spacing(1),
      width: "97%",
    },
    timestamps: {
      margin: theme.spacing(1),
    },
    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
      "& .MuiChip-root ": {
        opacity: "1",
      },
    },
    roundedBorder: {
      border: "1px solid lightgray",
      borderRadius: 5,
      color: "grey",
      marginBottom: "10px",
    },
  })
}
