import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchSiteById,
  fetchSiteByIdFailed,
  fetchSiteByIdSuccess,
  setSiteFormLoadingFlag,
} from "../actions/siteActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchSiteByIdPayload } from "../types/siteTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

export function* fetchSiteByIdSaga(action: PayloadAction<FetchSiteByIdPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.publisherId}/sites/${action.payload.siteId}`,
  }
  yield put(clearErrors())
  yield put(setSiteFormLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchSiteByIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") === "not_found" || "unknown") {
      yield call(forwardTo, `/admin/publishers/${action.payload.publisherId}/sites`)
    }
    yield handleSagaError(fetchSiteByIdFailed, err)
  }
  yield put(setSiteFormLoadingFlag(false))
}

export function* watchFetchSiteById(): SagaIterator {
  yield takeEvery(fetchSiteById, fetchSiteByIdSaga)
}
