import * as Yup from "yup"

import { Box, Button, Card, CardContent, Grid, TextField } from "@material-ui/core"
import { HOMEPAGE_MESSAGE, PERMISSIONS } from "../../../../../constants"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  fetchHomePage,
  resetHomePageState,
  updateHomePage,
} from "../../../../../redux/actions/homePageActions"

import { Alert } from "@material-ui/lab"
import ErrorMessages from "../../../../common/Errors"
import { Formik } from "formik"
import { HomePage } from "../../../../../redux/types/homePageTypes"
import { isPermitted } from "../../../../../helpers/permissionHelper"
import { trimPayloadProperties } from "../../../../../helpers/formatterHelper"
import { useSelector } from "react-redux"

const HomePageEditor = () => {
  const dispatch = useAppDispatch()
  const homePageContent = useSelector((state: RootState) => state.homePage)
  const isLoadingHomePage = useSelector((state: RootState) => state.homePage.isLoadingHomePage)
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  const generalErrors = useSelector((state: RootState) => state.errors.generalErrors)
  const savedHomePageMessage = useSelector(
    (state: RootState) => state.homePage.savedHomePageMessage
  )

  const [isSaveMessageStatusOpen, setIsSaveMessageStatusOpen] = React.useState(true)

  useEffect(() => {
    dispatch(resetHomePageState())
    // get home page contents if super-user or have configuration update permissions
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isPermitted(PERMISSIONS.ADMIN.CONFIGURATION.UPDATE, currentUser) && dispatch(fetchHomePage())
  }, [])

  const initialFormValues = { message: homePageContent.homePage.value }
  const formValidationSchema = Yup.object().shape({
    message: Yup.string().max(1500),
  })

  const restoreDefaultValue = (setFieldValue) => {
    setFieldValue("message", HOMEPAGE_MESSAGE.DEFAULT)
  }

  const handleFormikSubmit = (values) => {
    dispatch(
      updateHomePage(
        trimPayloadProperties({
          value: values.message,
        }) as HomePage
      )
    )
    setIsSaveMessageStatusOpen(true)
    setTimeout(() => setIsSaveMessageStatusOpen(false), 10000)
  }

  return (
    <>
      {generalErrors.length === 0 && savedHomePageMessage && isSaveMessageStatusOpen && (
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Alert
              data-testid="save-success-alert"
              severity="success"
              key="save-succeeded"
              onClose={() => {
                setIsSaveMessageStatusOpen(false)
              }}
            >
              {savedHomePageMessage}
            </Alert>
          </Grid>
        </Grid>
      )}

      <Box mt={3} mb={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={formValidationSchema}
          onSubmit={(values) => handleFormikSubmit(values)}
        >
          {({ errors, handleBlur, handleSubmit, setFieldValue, touched, values }) => {
            return (
              <>
                <ErrorMessages />
                <form onSubmit={handleSubmit}>
                  <Card data-testid={"home-page-editor-container"}>
                    <CardContent style={{ padding: 24 }}>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            multiline
                            minRows={8}
                            error={!!(touched.message && errors.message)}
                            fullWidth
                            helperText={touched.message && errors.message}
                            label="Home Page Message"
                            name="message"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("message", e.target.value)
                            }}
                            required
                            value={values.message}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "home-page-message-input",
                            }}
                            disabled={isLoadingHomePage}
                          />
                        </Grid>
                        <Grid item lg={2} md={3} xs={6}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={isLoadingHomePage}
                            data-testid={"home-page-form-submit-button"}
                          >
                            Save Home Page Message
                          </Button>
                        </Grid>
                        <Grid item lg={2} md={3} xs={6}>
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={isLoadingHomePage}
                            onClick={() => restoreDefaultValue(setFieldValue)}
                            data-testid={"home-page-restore-value-button"}
                          >
                            Restore Default Value
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Box
                    display="flex"
                    data-testid={"home-page-message-view"}
                    justifyContent="center"
                    alignItems="center"
                    minHeight="80vh"
                  >
                    <div
                      title="home-page-message-view-content"
                      dangerouslySetInnerHTML={{ __html: `${values.message}` }}
                    />
                  </Box>
                </form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default HomePageEditor
