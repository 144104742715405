import { createStyles, Theme } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    root: {
      "&.Mui-disabled": {
        pointerEvents: "auto",
      },
    },
    domainFormCardLayout: {
      padding: 24,
    },
    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
      "& .MuiChip-root ": {
        opacity: "1",
      },
    },
    loadingForm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
      weight: "100%",
    },
    roundedBorder: {
      border: "1px solid lightgray",
      borderRadius: 5,
      color: "grey",
      marginBottom: "10px",
    },
    timestamps: {
      margin: theme.spacing(1),
    },
  })
}
