import { call, put, takeEvery } from "redux-saga/effects"
import { handleCustomAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  loginToCampaignBI,
  loginToCampaignBIFailed,
  loginToCampaignBISuccess,
  setDataViewLoading,
} from "../actions/dataViewActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

export function* loginToCampaignBISaga(action: PayloadAction<string>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.CAMPAIGN_BI_PMG_BASE_URL}/auto-login/`,
    withCredentials: true,
    headers: {
      Authorization: `token ${action.payload}`,
    },
  }
  yield put(clearErrors())
  try {
    yield put(setDataViewLoading(true))
    yield call(handleCustomAxiosRequest, ajaxConfig)
    yield put(loginToCampaignBISuccess())
  } catch (err) {
    yield handleSagaError(loginToCampaignBIFailed, err)
  } finally {
    yield put(setDataViewLoading(false))
  }
}

export function* watchLoginToCampaignBI(): SagaIterator {
  yield takeEvery(loginToCampaignBI, loginToCampaignBISaga)
}
