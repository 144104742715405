import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchPricingEstimate,
  fetchPricingEstimateFailed,
  fetchPricingEstimateSuccess,
} from "../actions/pricingEstimateActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchPricingEstimatePayload } from "../types/pricingEstimateTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

export function* fetchPricingEstimateSaga(action: PayloadAction<FetchPricingEstimatePayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/audiences/pricing`,
    data: _.omit(action.payload, "vendorName"),
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(
      fetchPricingEstimateSuccess({
        pricingEstimateData: response.data,
        vendorName: action.payload.vendorName,
      })
    )
  } catch (err) {
    yield handleSagaError(fetchPricingEstimateFailed, err)
  }
}

export function* watchFetchPricingEstimate(): SagaIterator {
  yield takeEvery(fetchPricingEstimate, fetchPricingEstimateSaga)
}
