import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchAndExportDeals,
  fetchAndExportDealsFailed,
  fetchAndExportDealsSuccess,
} from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import { API, API_ROOT, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { FetchListPayload } from "../types/sharedTypes"
import { exportDealsAsCsv } from "../../helpers/csvHelpers"
import _ from "lodash"

export function* fetchAndExportDealsSaga(
  action: PayloadAction<FetchListPayload & { focusedCompanyName: string }>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/find`,
    data: _.omit(action.payload, "focusedCompanyName"),
  }
  // TODO: no actions fired on loading/submitting
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    exportDealsAsCsv(response.data, action.payload.focusedCompanyName)
    yield put(fetchAndExportDealsSuccess("Successfully exported deals"))
  } catch {
    yield handleSagaError(fetchAndExportDealsFailed, "Failed to export deals")
  }
}

export function* watchFetchAndExportDeals(): SagaIterator {
  yield takeEvery(fetchAndExportDeals, fetchAndExportDealsSaga)
}
