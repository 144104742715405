import { createStyles } from "@material-ui/core/styles"

export default () => {
  return createStyles({
    loadingPublisher: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "130px",
      weight: "100%",
    },
    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
    },
    cardPadding: {
      padding: 24,
    },
    roundedBorder: {
      border: "1px solid lightgray",
      borderRadius: 5,
      color: "grey",
      marginBottom: "10px",
    },
  })
}
