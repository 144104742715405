import { call, put, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import {
  fetchPermissions,
  fetchPermissionsFailed,
  fetchPermissionsSuccess,
} from "../actions/permissionActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"

export function* fetchPermissionsSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/permissions/find`,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchPermissionsSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchPermissionsFailed, err)
  }
}

export function* watchFetchPermissionList(): SagaIterator {
  yield takeEvery(fetchPermissions, fetchPermissionsSaga)
}
