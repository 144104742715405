import { Alert } from "@material-ui/lab"
import React from "react"

interface SuccessMessageProps {
  successMessage: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeSuccessMessage: any
}

const SuccessMessage = ({ successMessage, closeSuccessMessage }: SuccessMessageProps) => {
  return successMessage.length ? (
    <div data-testid={"success-message"}>
      <Alert
        severity="success"
        data-testid={successMessage}
        key={successMessage}
        onClose={closeSuccessMessage}
      >
        {successMessage}
      </Alert>
    </div>
  ) : null
}

export default SuccessMessage
