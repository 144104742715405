import { createStyles } from "@material-ui/core/styles"

export default () => {
  return createStyles({
    loadingForm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
      weight: "100%",
    },
  })
}
