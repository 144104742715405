import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createDomain,
  createDomainFailed,
  createDomainSuccess,
  setDomainFormSubmittingFlag,
} from "../actions/domainActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { Domain } from "../types/domainTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

export function* createDomainSaga(action: PayloadAction<Domain>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/domains`,
    data: {
      domain: action.payload,
    },
  }
  yield put(clearErrors())
  yield put(setDomainFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(createDomainSuccess(response.data))
    yield call(forwardTo, "/admin/domains")
  } catch (err) {
    yield handleSagaError(createDomainFailed, err)
  }
  yield put(setDomainFormSubmittingFlag(false))
}

export function* watchCreateDomain(): SagaIterator {
  yield takeEvery(createDomain, createDomainSaga)
}
