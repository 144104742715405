import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchDealBidders,
  fetchDealBiddersFailed,
  fetchDealBiddersSuccess,
} from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { SagaIterator } from "redux-saga"

export function* fetchDealBiddersSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/bidders/find`,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchDealBiddersSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchDealBiddersFailed, err)
  }
}

export function* watchFetchDealBidders(): SagaIterator {
  yield takeEvery(fetchDealBidders, fetchDealBiddersSaga)
}
