import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  setCompanyFormSubmittingFlag,
  updateCompany,
  updateCompanyFailed,
  updateCompanySuccess,
} from "../actions/companyActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { UpdateCompanyPayload } from "../types/companyTypes"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

export function* updateCompanySaga(action: PayloadAction<UpdateCompanyPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/companies/${action.payload.id}`,
    data: {
      company: _.omit(action.payload, ["id"]),
    },
  }
  yield put(clearErrors())
  yield put(setCompanyFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateCompanySuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateCompanyFailed, err)
  }
  yield put(setCompanyFormSubmittingFlag(false))
}

export function* watchUpdateCompany(): SagaIterator {
  yield takeEvery(updateCompany, updateCompanySaga)
}
