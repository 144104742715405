import React from "react"
import Sidebar from "../Sidebar"
import Topbar from "../Topbar"
import clsx from "clsx"
import { renderRoutes } from "react-router-config"
import styles from "./styles"
import { withStyles } from "@material-ui/core/styles"

const Main = ({ classes, route, featureFlags }) => {
  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar />
      <Sidebar featureFlags={featureFlags} />
      <div className={clsx(classes.wrapper, classes.wrapperShift)}>
        <main className={classes.content}>{renderRoutes(route.routes)}</main>
      </div>
    </div>
  )
}

export default withStyles(styles)(Main)
