import { createStyles, Theme } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    queryField: {
      width: 250,
      marginRight: "25px",
    },
    typography: {
      padding: theme.spacing(2),
    },
    okButton: {
      position: "relative",
      bottom: "15px",
      textAlign: "right",
      right: "15px",
    },
    dateRangeButton: {
      minHeight: "56px",
    },
    calendarIcon: {
      marginRight: "10px",
    },
    loaderIcon: {
      display: "flex",
      minHeight: "50px",
      justifyContent: "center",
    },
    "@global": {
      "div.MuiDataGrid-cell.MuiDataGrid-cellWithRenderer > pre": {
        "white-space": "pre-wrap",
        "word-wrap": "break-word",
      },
      ".MuiDataGrid-colCellTitle": {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.black,
      },
      ".MuiDataGrid-columnsContainer": {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      },
      ".MuiDataGrid-row, .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone, .MuiDataGrid-renderingZone":
        {
          "max-height": "none !important",
        },
      ".MuiDataGrid-root .MuiDataGrid-window": {
        position: "relative !important",
      },
      ".MuiDataGrid-root .MuiDataGrid-viewport": {
        "max-height": "none !important",
      },
      ".MuiDataGrid-main > div:nth-child(2) > div:nth-child(1)": {
        height: "auto !important",
      },
      pre: {
        lineHeight: "1.5em",
        paddingTop: "18px",
        paddingBottom: "18px",
      },
    },
  })
}
