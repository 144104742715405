import { Theme, createStyles } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    dropDownField: {
      margin: theme.spacing(1),
      width: "97%",
    },
    timestamps: {
      margin: theme.spacing(1),
    },
    buttonSpacing: {
      margin: theme.spacing(1),
    },
    vendorIdOption: {
      display: "flex",
    },
    roundedBorder: {
      border: "1px solid lightgray",
      borderRadius: 5,
      color: "grey",
      marginTop: "10px",
      marginBottom: "10px",
    },
    topMostContainer: {
      marginTop: "-10px",
    },
    noPadding: {
      padding: "0 !important",
    },
    noBorder: {
      border: "0 !important",
      verticalAlign: "top",
    },
    inlineLabel: {
      display: "flex",
      flexDirection: "column",
      marginRight: "15px",
      justifyContent: "center",
    },
    winningCPMsCell: {
      color: theme.palette.success.dark,
      height: "94px",
      marginTop: "2px",
      display: "flex",
      flexDirection: "column",
    },
    warningLightColor: {
      backgroundColor: "#fff2cc",
    },
    successLightColor: {
      backgroundColor: "#e8f5e9",
    },
    paddedCell: {
      padding: "15px 5px 5px",
      border: "1px solid lightgray",
      marginLeft: "7px",
    },
    infoBox: {
      backgroundColor: "#e8f5e9",
      color: theme.palette.success.dark,
      padding: "15px",
      borderRadius: "3px",
      height: "100%",
      border: "1px solid lightgray",
      textAlign: "center",
    },
    winninPopoverContent: {
      "& ul": {
        padding: "15px",
        "& li": {
          marginBottom: "10px",
        },
      },
    },
    popoverContent: {
      background: "lightyellow",
      fontSize: "14px",
    },
    textColorSuccess: {
      color: theme.palette.success.dark,
      fontWeight: "bold",
    },
    textColorFaded: {
      color: "gray",
      fontWeight: "bold",
    },
    textColorPrimary: {
      color: theme.palette.primary.dark,
    },
    noneBoxShadow: {
      boxShadow: "none",
    },
    displayNone: {
      display: "none",
    },
    audienceStatus: {
      display: "flex",
      justifyContent: "flex-end",
    },
    tableReachPricing: {
      "& th": {
        border: "1px solid lightgray",
        textAlign: "center",
        color: "gray",
      },
      "& tbody": {
        borderLeft: "1px solid lightgray",
      },
      "& tr": {
        textAlign: "center",
      },
      "& td": {
        textAlign: "center",
        border: "1px solid lightgray",
      },
    },
    dealPriceTable: {
      border: "1px solid lightgray",
    },
    customCard: {
      padding: "0",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      margin: "15px",
    },
    displayFlex: {
      display: "flex",
      justifyContent: "center",
    },
    customCardHeader: {
      padding: "0",
    },
    customCardBody: {
      padding: "0",
    },
    customTestBox: {
      border: "1px solid",
      borderColor: theme.palette.secondary.light,
      borderRadius: "3px",
      padding: "5px 14px",
      textAlign: "center",
      width: "250px",
      fontWeight: "bold",
      height: "52px",
    },
    audienceLabelStyle: {
      display: "grid",
      alignItems: "center",
    },
    loadingForm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
      weight: "100%",
    },
    hideArrow: {
      "& svg": {
        display: "none",
      },
    },
    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
    },
  })
}
