import { createStyles } from "@material-ui/core/styles"

export default () => {
  return createStyles({
    csvUploadContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "9px 9px 9px 0",
    },
    csvUploadChips: {
      margin: "0 0 0 4px",
    },
  })
}
