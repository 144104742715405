import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "./redux/store"
import { fetchCurrentUser, updateUserLastLogin } from "./redux/actions/userActions"

import { FEATURE_FLAGS } from "./constants"
import Loading from "./components/Loading/Loading"
import Maintenance from "./components/Maintenance"
import { fetchFeatureFlagByTerm } from "./redux/actions/featureFlagActions"
import { renderRoutes } from "react-router-config"
import routes from "./routes"
import { useSelector } from "react-redux"

const Authorization = ({ auth0User }) => {
  const dispatch = useAppDispatch()
  const isLoadingCurrentUser = useSelector(
    (state: RootState) => state.currentUser.isLoadingCurrentUser
  )
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  const retries = useSelector((state: RootState) => state.currentUser.retries)
  const isPredictiveDealsFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isPredictiveDealsFFEnabled
  )

  useEffect(() => {
    const timeoutId = retries < 5 && setTimeout(() => dispatch(fetchCurrentUser()), 1000)
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [retries])

  useEffect(() => {
    if (currentUser.id) {
      if (isPredictiveDealsFFEnabled === undefined) {
        dispatch(fetchFeatureFlagByTerm(FEATURE_FLAGS.ENABLE_PREDICTIVE_DEALS))
      }
    }
  }, [currentUser.id, isPredictiveDealsFFEnabled])

  if (isLoadingCurrentUser) {
    return retries > 0 ? (
      <Loading message={"Hmmm... Something went wrong loading user data. Retrying..."} />
    ) : (
      <Loading />
    )
  } else {
    if (currentUser.id) {
      if (auth0User) {
        dispatch(
          updateUserLastLogin({
            loggedInAt: auth0User.updated_at.toLocaleString(),
          })
        )
      }
      return renderRoutes(routes(currentUser.dataViews, { isPredictiveDealsFFEnabled }))
    } else if (retries === 5) {
      return <Maintenance />
    }
    return <Loading />
  }
}

export default Authorization
