import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { createUser, createUserFailed, createUserSuccess } from "../actions/userActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { CreateUserPayload } from "../types/userTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

export function* createUserSaga(action: PayloadAction<CreateUserPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/users`,
    data: {
      user: action.payload,
    },
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(createUserSuccess(response.data))
  } catch (err) {
    yield handleSagaError(createUserFailed, err)
  }
}

export function* watchCreateUser(): SagaIterator {
  yield takeEvery(createUser, createUserSaga)
}
