import { call, put, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import {
  fetchAudiences,
  fetchAudiencesFailed,
  fetchAudiencesSuccess,
} from "../actions/audienceActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"

export function* fetchAudiencesSaga(action) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/audiences/find`,
    data: action.payload,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page =
      response.headers["x-pagination-page"] !== undefined
        ? parseInt(response.headers["x-pagination-page"], 10)
        : 0
    const totalCount =
      response.headers["x-pagination-total-count"] !== undefined
        ? parseInt(response.headers["x-pagination-total-count"], 10)
        : 0
    yield put(
      fetchAudiencesSuccess({
        audiences: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchAudiencesFailed, err)
  }
}

export function* watchFetchAudiences(): SagaIterator {
  yield takeEvery(fetchAudiences, fetchAudiencesSaga)
}
