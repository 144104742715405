import { Box, Tab, Tabs, withStyles } from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { Edit as EditIcon, PlusCircle as PlusCircleIcon } from "react-feather"
import { PERMISSIONS } from "../../../../../constants"
import { Link, Route, Switch } from "react-router-dom"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import { fetchDomainById, resetDomainFormState } from "../../../../../redux/actions/domainActions"

import { Domain } from "../../../../../redux/types/domainTypes"
import DomainForm from "./DomainForm"
import NewHeader from "../../../../NewHeader"
import SubdomainsList from "./SubdomainsList"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import styles from "../../../styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (
  formType: {
    isCreate?: boolean
    isEdit?: boolean
    isInfo?: boolean
    isSubdomains?: boolean
  },
  domain: Domain = {} as Domain
): HeaderInfo => {
  const { isCreate, isEdit, isInfo, isSubdomains } = formType
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Domains",
      route: "/admin/domains",
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Domain"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isInfo) {
    headerText = "Domain: "
    if (domain.id) {
      headerText = `Domain: ${domain.name} (${domain.id})`
      breadcrumbs.push({
        name: `${domain.name} (${domain.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/admin/domains/${domain.id}/edit`,
        permission: PERMISSIONS.ADMIN.DOMAIN.UPDATE,
        icon: <EditIcon />,
      })
    }
  }

  if (isEdit) {
    headerText = "Domain: "
    if (domain.id) {
      headerText = `Domain: ${domain.name} (${domain.id})`
      breadcrumbs.push({
        name: `${domain.name} (${domain.id})`,
        route: `/admin/domains/${domain.id}`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  if (isSubdomains) {
    headerText = "Domain: "
    if (domain.id) {
      headerText = `Domain: ${domain.name} (${domain.id}) - All Subdomains`
      breadcrumbs.push({
        name: `${domain.name} (${domain.id})`,
        route: `/admin/domains/${domain.id}`,
      })
      breadcrumbs.push({
        name: `Subdomains`,
      })

      headerButtons.push({
        name: "CREATE NEW SUBDOMAIN",
        route: `/admin/domains/${domain.id}/subdomains/create`,
        permission: PERMISSIONS.ADMIN.DOMAIN.UPDATE,
        icon: <PlusCircleIcon />,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const DomainFormWrapper = ({ match }) => {
  const dispatch = useAppDispatch()

  const domain = useSelector((state: RootState) => state.domainForm.selectedDomain)

  const endPath = match.path.split("/").pop()
  const isCreate = endPath === "create"
  const isEdit = endPath === "edit"
  const isSubdomains = endPath === "subdomains"
  const isInfo = !isEdit && !isCreate && !isSubdomains

  const locationPathname = match.url ?? location.pathname

  useEffect(() => {
    // clean up stale data when component unmounts
    return () => {
      dispatch(clearErrors())
      dispatch(resetDomainFormState())
    }
  }, [])

  // separate useEffects are necessary due to timing issues where navigating from view to edit will result in fields clearing
  useEffect(() => {
    if (!isCreate && !domain.id) dispatch(fetchDomainById(match.params.domainId))
  }, [domain])

  const createPath = "/admin/domains/create"
  const infoPath = `/admin/domains/${domain.id}`
  const editPath = `/admin/domains/${domain.id}/edit`
  const subdomainsPath = `/admin/domains/${domain.id}/subdomains`

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    { isCreate, isEdit, isInfo, isSubdomains },
    domain
  )

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={0}>
        <Tabs data-testid="domain-form-tabs-wrapper" value={locationPathname}>
          {isCreate && (
            <Tab
              value={createPath}
              label={"General"}
              component={Link}
              to={createPath}
              data-testid={"domain-form-general-tab"}
            />
          )}
          {isInfo && domain.id && (
            <Tab
              value={infoPath}
              label={"General"}
              component={Link}
              to={infoPath}
              data-testid={"domain-form-general-tab"}
            />
          )}
          {isSubdomains && (
            <Tab
              value={infoPath}
              label={"General"}
              component={Link}
              to={infoPath}
              data-testid={"domain-form-general-tab"}
            />
          )}
          {isEdit && domain.id && (
            <Tab
              value={editPath}
              label={"General"}
              component={Link}
              to={editPath}
              data-testid={"domain-form-general-tab"}
            />
          )}
          {/* render subdomains tab in all non-create cases */}
          {!isCreate && domain.id && (
            <Tab
              value={subdomainsPath}
              label={"Subdomains"}
              component={Link}
              to={subdomainsPath}
              data-testid={"domain-form-subdomains-tab"}
            />
          )}
        </Tabs>

        <Switch>
          <Route exact={true} path={createPath} render={() => <DomainForm match={match} />} />
          {isInfo && domain.id && (
            <Route exact={true} path={infoPath} render={() => <DomainForm match={match} />} />
          )}
          {isEdit && domain.id && (
            <Route exact={true} path={editPath} render={() => <DomainForm match={match} />} />
          )}
          {!isCreate && domain.id && (
            <Route
              exact={true}
              path={subdomainsPath}
              render={() => <SubdomainsList match={match} />}
            />
          )}
        </Switch>
      </Box>
    </>
  )
}

export default withStyles(styles)(DomainFormWrapper)
