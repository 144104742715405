import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  setDomainFormSubmittingFlag,
  updateDomain,
  updateDomainFailed,
  updateDomainSuccess,
} from "../actions/domainActions"

import { AxiosRequestConfig } from "axios"
import { Domain } from "../types/domainTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

export function* updateDomainSaga(action: PayloadAction<Domain>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/domains/${action.payload.id}`,
    data: {
      domain: _.omit(action.payload, ["id"]),
    },
  }
  yield put(clearErrors())
  yield put(setDomainFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateDomainSuccess(response.data))
    yield call(forwardTo, "/admin/domains")
  } catch (err) {
    yield handleSagaError(updateDomainFailed, err)
  }
  yield put(setDomainFormSubmittingFlag(false))
}

export function* watchUpdateDomain(): SagaIterator {
  yield takeEvery(updateDomain, updateDomainSaga)
}
