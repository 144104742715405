import { Theme, createStyles } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    audienceStatusInactive: {
      backgroundColor: "#dd4b39",
    },
    audienceStatusActive: {
      backgroundColor: "#5cb85c",
    },

    label: {
      display: "inline",
      padding: ".2em .6em .3em",
      fontSize: "75%",
      lineHeight: "1",
      color: "#fff",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "baseline",
      borderRadius: ".25em",
      margin: ".2em",
    },
    queryField: {
      width: 500,
      marginRight: theme.spacing(1),
    },
    audienceStatusField: {
      marginRight: theme.spacing(1),
      width: "25ch",
    },
    audienceSortByField: {
      marginRight: theme.spacing(1),
    },
    scrollTable: {
      overflowX: "auto",
    },
    displayFlex: {
      display: "flex",
    },
    hideArrow: {
      "& svg": {
        display: "none",
      },
    },
  })
}
