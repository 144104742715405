import { MenuItem, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../redux/store"
import { WithStyles, withStyles } from "@material-ui/core/styles"

import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog"
import { PERMISSIONS } from "../../../constants"
import { fetchCompanies } from "../../../redux/actions/companyActions"
import { isPermitted } from "../../../helpers/permissionHelper"
import styles from "./styles"
import { updateUserActiveCompany } from "../../../redux/actions/userActions"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

const ActiveCompanySelector = ({ classes }: WithStyles) => {
  const dispatch = useAppDispatch()
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  const companies = useSelector((state: RootState) => state.companies.allCompanies)
  const isLoadingCompanies = useSelector((state: RootState) => state.companies.isLoading)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const history = useHistory()
  const currentPath = window.location.pathname.split("/")
  const endPath = currentPath[currentPath.length - 1]
  const redirectPath = `/${currentPath[1]}/${currentPath[2]}`

  const isUserPermittedToChangeCompany = currentUser
    ? isPermitted(PERMISSIONS.ADMIN.USER.CHANGE_ACTIVE_COMPANY, currentUser)
    : false

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isUserPermittedToChangeCompany &&
      !companies.length &&
      dispatch(fetchCompanies({ pagination: { paginate: false } }))
  }, [companies, currentUser])

  const getActiveCompanyDropdownValue = () => {
    if (!isUserPermittedToChangeCompany) return currentUser?.companyId ?? ""

    // if list of companies are loading, keep the field blank
    if (!companies.length) return ""
    return currentUser?.activeCompanyId ?? currentUser?.companyId ?? ""
  }

  const handleOnchange = (e) => {
    setSelectedCompany(e.target.value)
    if (redirectPath === "/tools/audiences" && endPath !== "audiences") {
      setIsOpenDialog(true)
    } else {
      dispatch(updateUserActiveCompany(parseInt(e.target.value, 10)))
      setIsOpenDialog(false)
    }
  }

  const handleDialogClose = (shouldReload?: boolean) => {
    setIsOpenDialog(false)
    if (shouldReload === true) {
      dispatch(updateUserActiveCompany(parseInt(selectedCompany, 10)))
      history.push(redirectPath)
    }
  }

  return (
    <>
      <TextField
        select
        size="small"
        label="Company"
        variant="outlined"
        value={getActiveCompanyDropdownValue()}
        onChange={(e) => {
          handleOnchange(e)
        }}
        inputProps={{
          "data-testid": "active-company-dropdown-input",
        }}
        className={classes.activeCompanyDropdownInput}
        disabled={!isUserPermittedToChangeCompany || isLoadingCompanies}
      >
        {companies.length > 0 ? (
          companies.map((company) => (
            <MenuItem key={company.id} value={company.id}>
              {company.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={currentUser?.company?.id} value={currentUser?.company?.id}>
            {currentUser?.company?.name}
          </MenuItem>
        )}
      </TextField>
      <ConfirmationDialog
        id="active-company-change-dialog"
        keepMounted
        open={isOpenDialog}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default withStyles(styles)(ActiveCompanySelector)
