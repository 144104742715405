import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchDealPredictiveAudiences,
  fetchDealPredictiveAudiencesFailed,
  fetchDealPredictiveAudiencesSuccess,
} from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchListPayload } from "../types/sharedTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"

export function* fetchDealPredictiveAudiencesSaga(action: PayloadAction<FetchListPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/predictive-audiences/find`,
    data: action.payload,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchDealPredictiveAudiencesSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchDealPredictiveAudiencesFailed, err)
  }
}

export function* watchFetchDealPredictiveAudiences(): SagaIterator {
  yield takeEvery(fetchDealPredictiveAudiences, fetchDealPredictiveAudiencesSaga)
}
