import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createSubdomain,
  createSubdomainFailed,
  createSubdomainSuccess,
  setSubdomainFormSubmittingFlag,
} from "../actions/subdomainActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { Subdomain } from "../types/subdomainTypes"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

export function* createSubdomainSaga(action: PayloadAction<Subdomain>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/domains/${action.payload.domainId}/subdomains`,
    data: {
      subdomain: action.payload,
    },
  }
  yield put(clearErrors())
  yield put(setSubdomainFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(createSubdomainSuccess(response.data))
    yield call(forwardTo, `/admin/domains/${action.payload.domainId}/subdomains`)
  } catch (err) {
    yield handleSagaError(createSubdomainFailed, err)
  }
  yield put(setSubdomainFormSubmittingFlag(false))
}

export function* watchCreateSubdomain(): SagaIterator {
  yield takeEvery(createSubdomain, createSubdomainSaga)
}
