import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  updateUserActiveCompany,
  updateUserActiveCompanyFailed,
  updateUserActiveCompanySuccess,
} from "../actions/userActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"

export function* updateUserActiveCompanySaga(action: PayloadAction<number>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PUT",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/users/active-company`,
    data: { user: { activeCompanyId: action.payload } },
  }
  try {
    yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateUserActiveCompanySuccess())
  } catch (err) {
    yield handleSagaError(updateUserActiveCompanyFailed, err)
  }
}

export function* watchUpdateUserActiveCompany(): SagaIterator {
  yield takeEvery(updateUserActiveCompany, updateUserActiveCompanySaga)
}
