// If no MXCONFIG_API_URL exists in environment, default will be to use the same host/port as the web server
export const API_ROOT = process.env.MXCONFIG_API_URL || window.location.origin

export const API = {
  V1: "/api/v1",
}

export const DEFAULT_PAGE_TITLE = "Cadent Aperture MX Config"

export const TARGET_AUDIENCE_SIZE = {
  STEP_INCREMENT_VALUE: 250000,
  DEFAULT_VALUE: 1000000,
}

export const OPERATION_TYPES_ENP = { AND: "AND", OR: "OR", NONE: "NONE" }

export const POPULATION_TYPES = {
  IP: "IP",
}

export const REQUIRED_SEGMENT_CSV_HEADERS = {
  EXTERNAL_ID: "external_id",
  HIERARCHY: "hierarchy",
}

//number of segments across groups in audience creation
export const MAX_SEGMENTS_PER_AUDIENCE = 10

export const MAX_SEGMENT_GROUPS = 10

// Max number of domains allowed in deal creation/edit
export const MAX_IAB_CONTENT_CATEGORIES_INPUT = 250
// Max number of domains allowed in deal creation/edit
export const MAX_DOMAINS_INPUT = 250
// Max number of publishers allowed in deal creation/edit
export const MAX_PUBLISHERS_INPUT = 250

// API Search Input debounce value in Milliseconds
export const DEBOUNCE_MS = 200
// looking for better name for this, any suggestions?
export const SECTION = {
  ADMIN: "/admin",
  DATA_VIEWS: "/data-views",
  TOOLS: "/tools",
}

export const END_PATHS = {
  CREATE: "create",
  EDIT: "edit",
  COPY: "copy",
}

export const PERMISSIONS = {
  ADMIN: {
    USER: {
      LIST: {
        VIEW: "admin::user::list::view",
      },
      VIEW: "admin::user::view",
      CREATE: "admin::user::create",
      UPDATE: "admin::user::update",
      CHANGE_ACTIVE_COMPANY: "admin::user::change_active_company",
    },
    ROLE: {
      LIST: {
        VIEW: "admin::role::list::view",
      },
      VIEW: "admin::role::view",
      CREATE: "admin::role::create",
      UPDATE: "admin::role::update",
    },
    COMPANY: {
      LIST: {
        VIEW: "admin::company::list::view",
      },
      VIEW: "admin::company::view",
      CREATE: "admin::company::create",
      UPDATE: "admin::company::update",
    },
    PUBLISHER: {
      LIST: {
        VIEW: "admin::publisher::list::view",
      },
      VIEW: "admin::publisher::view",
      CREATE: "admin::publisher::create",
      UPDATE: "admin::publisher::update",
    },
    DOMAIN: {
      LIST: {
        VIEW: "admin::domain::list::view",
      },
      VIEW: "admin::domain::view",
      CREATE: "admin::domain::create",
      UPDATE: "admin::domain::update",
    },
    CHANGELOG: {
      LIST: {
        VIEW: "admin::changelog::list::view",
      },
    },
    PERMISSION: {
      LIST: {
        VIEW: "admin::permission::list::view",
      },
      VIEW: "admin::permission::view",
    },
    SEGMENT: {
      CREATE: {
        UPLOAD: "admin::segment::create::upload",
      },
    },
    CONFIGURATION: {
      UPDATE: "admin::configuration::update",
    },
  },
  DATA_VIEWS: {
    CAMPAIGN_BI_PMG: {
      VIEW: "data_views::campaign_bi_pmg::view",
    },
    KIBANA: {
      VIEW: "data_views::kibana::view",
    },
  },
  TOOLS: {
    AUDIENCE: {
      LIST: {
        VIEW: "tools::audience::list::view",
      },
      VIEW: "tools::audience::view",
      CREATE: "tools::audience::create",
      UPDATE: "tools::audience::update",
      PRICING: {
        CALCULATE: "tools::audience::pricing::calculate",
      },
    },
    DEAL: {
      LIST: {
        VIEW: "tools::deal::list::view",
      },
      VIEW: "tools::deal::view",
      CREATE: "tools::deal::create",
      UPDATE: "tools::deal::update",
      UPDATE_CMP_FEE: "tools::deal::cmp_fee",
    },
    DEAL_REQUEST: {
      LIST: {
        VIEW: "tools::deal_request::list::view",
      },
      VIEW: "tools::deal_request::view",
      CREATE: "tools::deal_request::create",
      UPDATE: "tools::deal_request::update",
    },
  },
}

export enum HOMEPAGE_MESSAGE {
  DEFAULT = `<p style="text-align: center;"><span style="font-size: 36px; font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';">Cadent Aperture MX Config</span></p><p style="text-align: center;"><span style="font-size: 20px; font-family:'Roboto', 'Helvetica', 'Arial', 'sans-serif';">Shifting gears into better ad performance and efficiencies&nbsp;&nbsp;</span><br></p>`,
  ERROR = "Configuration not found",
  FIELD_NAME = "homepage_message",
}

export enum DMP_SEGMENT_FEE_TYPE {
  FIXED = "Fixed",
  PERCENTAGE = "Percentage",
}

export enum DMP_SEGMENT_FEE {
  DEFAULT = 1,
  PERCENTAGE_AUDIGENT = 30,
}

// Score threshold types
export enum SCORE_THRESHOLD_TYPE {
  DEFAULT = "scaled",
  BALANCED = "balanced",
  FOCUSED = "focused",
  CUSTOM = "custom",
}

export enum SCORE_THRESHOLD_RANGES {
  MAX = 100,
  DEFAULT_MIN = 85,
  BALANCED_MIN = 90,
  FOCUSED_MIN = 95,
  CUSTOM_MIN = 0,
}

export enum AUCTION_TYPE {
  STANDARD = "standard",
  FIXED_PRICE = "fixed_price",
}

export enum MEDIA_TYPE {
  VIDEO = "video",
  DISPLAY = "display",
  ALL = "all",
}

export enum SUPPLY_TYPE {
  APP = "app",
  WEB = "web",
  ALL = "all",
}

export enum DEVICE_TYPE {
  MOBILE = "mobile",
  DESKTOP = "desktop",
  CTV = "ctv",
  ALL = "all",
}

export enum ENTITY_FILTER_TYPE {
  BLOCKLIST = "blocklist",
  ALLOWLIST = "allowlist",
  NONE = "none",
}

export const ERRORS = {
  UNKNOWN: "An unknown error occurred",
}

export enum DEAL_CSV_TYPES {
  DOMAINS = "domains",
  PUBLISHERS = "publishers",
}
export const DEFAULT_CSS_STYLES = {
  FONT_FAMILY: "'Roboto', 'Helvetica', 'Arial', sans-serif",
}

export enum TARGET_TYPES {
  // COOKIE = 1
  IP = 2,
}

export enum ENTITY_STATUSES {
  ALL = "all",
  ACTIVE = "active",
  INACTIVE = "inactive",
  ARCHIVED = "archived",
}

export enum SEGMENT_VENDOR_NAME {
  ENGINE_INSIGHTS = "ENGINE Insights",
  AUDIGENT = "Audigent",
}

export enum MEDIA_FORMAT {
  BVOD = "BVOD",
  PRE_ROLL = "Pre-Roll",
  DISPLAY = "Display",
}

export enum DEAL_REQUEST_STATUS {
  ALL = "All",
  NEW = "New",
  DONE = "Done",
}

export const DEAL_REQUEST_STATUS_OPTIONS = [
  { value: DEAL_REQUEST_STATUS.ALL, label: "All" },
  { value: DEAL_REQUEST_STATUS.NEW, label: "New" },
  { value: DEAL_REQUEST_STATUS.DONE, label: "Done" },
]

export enum TARGETING_TYPE {
  CONTENT_CATEGORY_V1 = "content_category_v1",
  LIVESTREAM = "livestream",
}

export const PAGE_LIMIT = {
  DEFAULT: 50,
  OPTIONS: [25, 50, 100],
}

export const FEATURE_FLAGS = {
  USE_DEVCYCLE: "FF_USE_DEVCYCLE",
  ENABLE_PREDICTIVE_DEALS: "FF_ENABLE_PREDICTIVE_DEALS",
  ENABLE_PREDICTIVE_SCORE_THRESHOLD: "FF_ENABLE_PREDICTIVE_SCORE_THRESHOLD",
  ENABLE_NEW_TARGETING_UI: "FF_ENABLE_NEW_TARGETING_UI",
  ENABLE_LIVESTREAM_TARGETING: "FF_ENABLE_LIVE_STREAM_TARGETING",
}
