import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { fetchRoleById, fetchRoleByIdFailed, fetchRoleByIdSuccess } from "../actions/roleActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

export function* fetchRoleByIdSaga(action: PayloadAction<number>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/roles/${action.payload}`,
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchRoleByIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") === "not_found" || "unknown") {
      yield call(forwardTo, "/admin/roles")
    }
    yield handleSagaError(fetchRoleByIdFailed, err)
  }
}

export function* watchFetchRoleById(): SagaIterator {
  yield takeEvery(fetchRoleById, fetchRoleByIdSaga)
}
