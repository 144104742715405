import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchDomains,
  fetchDomainsFailed,
  fetchDomainsSuccess,
  setDomainsLoadingFlag,
} from "../actions/domainActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchListPayload } from "../types/sharedTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

export function* fetchDomainsSaga(action: PayloadAction<FetchListPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/domains/find`,
    data: action.payload,
  }

  yield put(clearErrors())
  yield put(setDomainsLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page = parseInt(response.headers["x-pagination-page"], 10)
    const totalCount = parseInt(response.headers["x-pagination-total-count"], 10)
    yield put(
      fetchDomainsSuccess({
        domains: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchDomainsFailed, err)
  }
  yield put(setDomainsLoadingFlag(false))
}

export function* watchFetchDomains(): SagaIterator {
  yield takeEvery(fetchDomains, fetchDomainsSaga)
}
