import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  clearSaveFeatureFlagSuccess,
  setFeatureFlagsSubmittingFlag,
  updateFeatureFlags,
  updateFeatureFlagsFailed,
  updateFeatureFlagsSuccess,
} from "../actions/featureFlagActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FeatureFlag } from "../types/featureFlagTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

export function* updateFeatureFlagsSaga(action: PayloadAction<FeatureFlag[]>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/configuration/feature-flags`,
    data: {
      featureFlags: action.payload,
    },
  }
  yield put(clearErrors())
  yield put(clearSaveFeatureFlagSuccess())
  yield put(setFeatureFlagsSubmittingFlag(true))
  try {
    yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateFeatureFlagsSuccess("Successfully updated feature flags"))
  } catch (err) {
    yield handleSagaError(updateFeatureFlagsFailed, err)
  }
  yield put(setFeatureFlagsSubmittingFlag(false))
}

export function* watchUpdateFeatureFlags(): SagaIterator {
  yield takeEvery(updateFeatureFlags, updateFeatureFlagsSaga)
}
