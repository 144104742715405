import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import { DEFAULT_SITE_AUCTION_TYPE, Site } from "../../../../../../redux/types/siteTypes"
import React, { useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
import {
  createSite,
  fetchSiteBlockAttributes,
  fetchSiteBlockCategories,
  fetchSiteBlockTypes,
  updateSite,
} from "../../../../../../redux/actions/siteActions"
import { trimPayloadProperties, validateArray } from "../../../../../../helpers/formatterHelper"

import { Autocomplete } from "@material-ui/lab"
import { ENTITY_FILTER_TYPE } from "../../../../../../constants"
import ErrorMessages from "../../../../../common/Errors"
import { Formik } from "formik"
import _ from "lodash"
import clsx from "clsx"
import { fetchSiteBidders } from "../../../../../../redux/actions/siteActions"
import styles from "./styles"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

// Exporting this out here for test purposes
export const demandFilteringOptions = [
  { value: ENTITY_FILTER_TYPE.NONE, label: "No Filtering" },
  { value: ENTITY_FILTER_TYPE.ALLOWLIST, label: "Allowlist" },
  { value: ENTITY_FILTER_TYPE.BLOCKLIST, label: "Blocklist" },
]

const SiteForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()
  const site = useSelector((state: RootState) => state.siteForm.selectedSite)
  const publisher = useSelector((state: RootState) => state.publisherForm.selectedPublisher)

  const selectableBidders = useSelector((state: RootState) => state.siteForm.bidders)
  const isLoadingBidders = useSelector((state: RootState) => state.siteForm.isLoadingBidders)

  const blockCategoryOptions = useSelector((state: RootState) => state.siteForm.blockCategories)
  const isLoadingBlockCategories = useSelector(
    (state: RootState) => state.siteForm.isLoadingBlockCategories
  )
  const [blockCategoryMainGroups, updateBlockCategoryMainGroups] = useState({})

  const blockAttributeOptions = useSelector((state: RootState) => state.siteForm.blockAttributes)
  const isLoadingBlockAttributes = useSelector(
    (state: RootState) => state.siteForm.isLoadingBlockAttributes
  )

  const blockTypeOptions = useSelector((state: RootState) => state.siteForm.blockTypes)
  const isLoadingBlockTypes = useSelector((state: RootState) => state.siteForm.isLoadingBlockTypes)

  const isSaved = useSelector((state: RootState) => state.siteForm.isSaved)
  const isLoading = useSelector((state: RootState) => state.siteForm.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.siteForm.isSubmitting)
  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isEdit = endPath === "edit"
  const isCreate = endPath === "create"
  const isInfo = !isEdit && !isCreate

  useEffect(() => {
    // when the site is successfully saved navigate back to sites list
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isSaved && history.push(`/admin/publishers/${publisher.id}/sites`)
  }, [site, isSaved])

  useEffect(() => {
    dispatch(fetchSiteBidders())
    dispatch(fetchSiteBlockCategories())
    dispatch(fetchSiteBlockAttributes())
    dispatch(fetchSiteBlockTypes())
  }, [])

  // useEffect block used to prevent unnecessary re-renders
  useEffect(() => {
    // updatedBlockCategoryMainGroups is used for the grouping dropdown options
    // alternative grouping solution in groupIABContentCategories in DealForm.tsx
    const updatedBlockCategoryMainGroups = {}
    // is empty check necessary to prevent double renders when switching from view to edit
    if (blockCategoryOptions.length && _.isEmpty(blockCategoryMainGroups)) {
      blockCategoryOptions.forEach((blockCategory) => {
        if (blockCategory.sub === 0) {
          updatedBlockCategoryMainGroups[blockCategory.main] = blockCategory.name
        }
      })

      updateBlockCategoryMainGroups(updatedBlockCategoryMainGroups)
    }
  }, [blockCategoryOptions])

  const siteBidderMap = site.bidders.map((bidderId) => ({
    id: bidderId,
    name: selectableBidders.find((bidder) => bidder.id === bidderId)?.name || "invalid bidder",
  }))

  const initialFormValues = {
    name: site.name || "",
    revShare: site.revShare ?? "",
    isReduceBid: site.isReduceBid ? "true" : "false",
    demandFiltering: site.demandFiltering || ENTITY_FILTER_TYPE.NONE,
    bidders: siteBidderMap,
    domains: site.domains || [],
    blockCategories: site.blockCategories || [],
    blockAttributes: site.blockAttributes || [],
    blockTypes: site.blockTypes || [],
    appNexusSiteId: site.appNexusSiteId || "N/A",
  }

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().max(255).required(),
    revShare: Yup.number()
      .required()
      .min(0, "Please select a value between 0 and 199")
      .max(199, "Please select a value between 0 and 199"),
    bidders: Yup.array().when("demandFiltering", {
      is: (val) => val === ENTITY_FILTER_TYPE.BLOCKLIST,
      then: Yup.array().min(
        1,
        "At least one bidder is required when blocklist filtering is selected"
      ),
    }),
    domains: Yup.array().test(
      "string type",
      "Domains must not be numbers",
      (domainValues) => !domainValues.some((domainValue) => parseInt(domainValue) === domainValue)
    ),
  })

  const reduceBidOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ]

  const handleFormikSubmit = (values) => {
    const valuesCopy = { ...values }

    valuesCopy.isReduceBid = values.isReduceBid === "true"
    valuesCopy.bidders = values.bidders.map((bidder) => parseInt(bidder.id, 10))

    if (!isEdit) {
      dispatch(
        createSite(trimPayloadProperties({ ...valuesCopy, publisherId: publisher.id }) as Site)
      )
    } else if (isEdit) {
      dispatch(
        updateSite(
          trimPayloadProperties({
            ...valuesCopy,
            id: site.id,
            publisherId: publisher.id,
          }) as Site
        )
      )
    }
  }

  return (
    <>
      {!isLoading ? (
        <Box mt={3} mb={3} className={clsx({ isInfo: classes.cursorOverrideDisable })}>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => handleFormikSubmit(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              touched,
              values,
            }) => {
              return (
                <>
                  <ErrorMessages />
                  <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                    <Card>
                      <CardContent className={classes.siteFormCardLayout}>
                        <Grid container spacing={3} className={classes.roundedBorder}>
                          {/* Site Name */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={!!(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Site name
                                </span>
                              }
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                              inputProps={{ "data-testid": "site-form-name-input" }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/*Rev Share %s*/}
                          <Grid item md={2} xs={12}>
                            <TextField
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Rev Share (%)
                                </span>
                              }
                              name="revShare"
                              type="number"
                              error={!!(touched.revShare && errors.revShare)}
                              helperText={touched.revShare && errors.revShare}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.revShare}
                              required
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "site-form-rev-share-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              onWheel={(e) => (e.target as HTMLElement).blur()}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/*Reduced Bid*/}
                          <Grid item md={2} xs={12}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Reduce Bid
                                </span>
                              }
                              name="isReduceBid"
                              error={!!(touched.isReduceBid && errors.isReduceBid)}
                              helperText={touched.isReduceBid && errors.isReduceBid}
                              value={values.isReduceBid}
                              onChange={handleChange}
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "site-form-reduce-bid-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            >
                              {reduceBidOptions
                                ? reduceBidOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))
                                : null}
                            </TextField>
                          </Grid>

                          {/*Auction Type*/}
                          <Grid item md={2} xs={12}>
                            <TextField
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Auction Type
                                </span>
                              }
                              name="auctionType"
                              className={clsx(classes.dropDownField, classes.disableInputCursor)}
                              type="text"
                              defaultValue={DEFAULT_SITE_AUCTION_TYPE}
                              inputProps={{
                                "data-testid": "site-form-auction-type-input",
                                readOnly: true,
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                            />
                          </Grid>

                          {/*Filtered Bidders*/}
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              options={selectableBidders.filter(
                                (bidder) => bidder.status === "active"
                              )}
                              value={validateArray(values.bidders)}
                              getOptionSelected={(option, value) => value.id === option.id}
                              getOptionLabel={(option) =>
                                option ? `${option.name} (${option.id})` : ""
                              }
                              multiple
                              fullWidth
                              filterSelectedOptions
                              data-testid={"site-form-bidders-input"}
                              onBlur={() => setFieldTouched("bidders")}
                              onChange={(e, newValue) => {
                                setFieldValue("bidders", newValue)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    <span className={clsx(isInfo && classes.overrideDisable)}>
                                      Bidders
                                    </span>
                                  }
                                  name="bidders"
                                  error={!!(touched.bidders && errors.bidders)}
                                  helperText={touched.bidders && errors.bidders}
                                  fullWidth
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                      disabled: classes.overrideDisable,
                                    },
                                  }}
                                />
                              )}
                              disabled={
                                isInfo ||
                                isLoadingBidders ||
                                values.demandFiltering === ENTITY_FILTER_TYPE.NONE
                              }
                            />
                            {values.demandFiltering === ENTITY_FILTER_TYPE.NONE && (
                              <FormHelperText component="div">
                                <Typography
                                  className={classes.helperText}
                                  data-testid={"site-form-bidders-helper-text"}
                                >
                                  ** Select a demand filtering option to add bidders **
                                </Typography>
                              </FormHelperText>
                            )}
                            {values.demandFiltering === ENTITY_FILTER_TYPE.ALLOWLIST &&
                              values.bidders.length === 0 && (
                                <FormHelperText component="div">
                                  <Typography
                                    className={clsx(classes.helperText, classes.helperWarningText)}
                                    data-testid={"site-form-bidders-helper-warning-text"}
                                  >
                                    ** Selecting an allow list with no bidders will effectively
                                    disable this site **
                                  </Typography>
                                </FormHelperText>
                              )}
                          </Grid>

                          {/*Demand Filtering Options*/}
                          <Grid item md={2} xs={12}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Demand Filtering
                                </span>
                              }
                              name="demandFiltering"
                              error={!!(touched.demandFiltering && errors.demandFiltering)}
                              helperText={touched.demandFiltering && errors.demandFiltering}
                              className={classes.dropDownField}
                              value={values.demandFiltering}
                              onChange={(e) => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                e.target.value === ENTITY_FILTER_TYPE.NONE &&
                                  setFieldValue("bidders", [])

                                setFieldValue("demandFiltering", e.target.value)
                              }}
                              inputProps={{
                                "data-testid": "site-form-demand-filtering-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            >
                              {demandFilteringOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>

                          {/*AppNexus Site Id*/}
                          <Grid item md={2} xs={12}>
                            <TextField
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  AppNexus Site ID
                                </span>
                              }
                              name="appNexusSiteId"
                              value={values.appNexusSiteId}
                              className={clsx(classes.dropDownField, classes.disableInputCursor)}
                              inputProps={{
                                "data-testid": "site-form-app-nexus-site-id-input",
                                readOnly: true,
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                            />
                          </Grid>

                          {/*Domains*/}
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              options={[]}
                              value={values.domains}
                              multiple
                              fullWidth
                              freeSolo
                              data-testid={"site-form-domains-input"}
                              onBlur={() => setFieldTouched("domains")}
                              onChange={(e, newValue) => {
                                setFieldValue("domains", newValue)
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    label={
                                      <span className={clsx(isInfo && classes.overrideDisable)}>
                                        Domains
                                      </span>
                                    }
                                    name="domains"
                                    error={!!(touched.domains && errors.domains)}
                                    helperText={touched.domains && errors.domains}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      classes: {
                                        disabled: classes.overrideDisable,
                                      },
                                    }}
                                  />
                                )
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          <Grid item md={6} xs={12} />

                          {/*Block Categories*/}
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              options={blockCategoryOptions}
                              groupBy={(option) => blockCategoryMainGroups[option.main]}
                              value={validateArray(values.blockCategories)}
                              getOptionSelected={(option, value) => value.id === option.id}
                              getOptionLabel={(option) =>
                                option ? `${option.iab} - ${option.name}` : ""
                              }
                              multiple
                              fullWidth
                              filterSelectedOptions
                              data-testid={"site-form-block-categories-input"}
                              onBlur={() => setFieldTouched("blockCategories")}
                              onChange={(e, newValue) => {
                                setFieldValue("blockCategories", newValue)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    <span className={clsx(isInfo && classes.overrideDisable)}>
                                      Block Categories
                                    </span>
                                  }
                                  name={"blockCategories"}
                                  error={!!(touched.blockCategories && errors.blockCategories)}
                                  helperText={touched.blockCategories && errors.blockCategories}
                                  fullWidth
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                      disabled: classes.overrideDisable,
                                    },
                                  }}
                                />
                              )}
                              disabled={isInfo || isLoadingBlockCategories}
                            />
                          </Grid>

                          <Grid item md={6} xs={12} />

                          {/*Block Attributes*/}
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              options={blockAttributeOptions}
                              value={validateArray(values.blockAttributes)}
                              getOptionSelected={(option, value) => value.id === option.id}
                              getOptionLabel={(option) => (option ? option.name : "")}
                              multiple
                              fullWidth
                              filterSelectedOptions
                              data-testid={"site-form-block-attributes-input"}
                              onBlur={() => setFieldTouched("blockAttributes")}
                              onChange={(e, newValue) => {
                                setFieldValue("blockAttributes", newValue)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    <span className={clsx(isInfo && classes.overrideDisable)}>
                                      Block Attributes
                                    </span>
                                  }
                                  name={"blockAttributes"}
                                  error={!!(touched.blockAttributes && errors.blockAttributes)}
                                  helperText={touched.blockAttributes && errors.blockAttributes}
                                  fullWidth
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                      disabled: classes.overrideDisable,
                                    },
                                  }}
                                />
                              )}
                              disabled={isInfo || isLoadingBlockAttributes}
                            />
                          </Grid>

                          <Grid item md={6} xs={12} />

                          {/*Block Types*/}
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              options={blockTypeOptions}
                              value={validateArray(values.blockTypes)}
                              getOptionSelected={(option, value) => value.id === option.id}
                              getOptionLabel={(option) => (option ? option.name : "")}
                              multiple
                              fullWidth
                              filterSelectedOptions
                              data-testid={"site-form-block-types-input"}
                              onBlur={() => setFieldTouched("blockTypes")}
                              onChange={(e, newValue) => {
                                setFieldValue("blockTypes", newValue)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    <span className={clsx(isInfo && classes.overrideDisable)}>
                                      Block Types
                                    </span>
                                  }
                                  name={"blockTypes"}
                                  error={!!(touched.blockTypes && errors.blockTypes)}
                                  helperText={touched.blockTypes && errors.blockTypes}
                                  fullWidth
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                      disabled: classes.overrideDisable,
                                    },
                                  }}
                                />
                              )}
                              disabled={isInfo || isLoadingBlockTypes}
                            />
                          </Grid>
                        </Grid>
                        <Box mt={2}>
                          {!isInfo && (
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={isSubmitting}
                              data-testid={"site-form-submit-button"}
                            >
                              {isCreate && "Create Site"}
                              {isEdit && "Update Site"}
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </form>
                </>
              )
            }}
          </Formik>
        </Box>
      ) : (
        <Box mt={3} mb={3}>
          <Card>
            <CardContent className={classes.loadingForm}>
              <CircularProgress />
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

export default withStyles(styles)(SiteForm)
