import {
  clearCompanies,
  closeSaveCompanySuccess,
  createCompanySuccess,
  fetchCompanies,
  fetchCompaniesFailed,
  fetchCompaniesSuccess,
  updateCompanySuccess,
} from "../actions/companyActions"

import { Company } from "../types/companyTypes"
import { PaginatedComponentState } from "../types/sharedTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialCompanyState extends PaginatedComponentState {
  companies: Company[]
  allCompanies: Company[]
  isLoading: boolean
  companySuccessMessage: string
}

const initialState: InitialCompanyState = {
  companies: [],
  allCompanies: [],
  isLoading: true,
  companySuccessMessage: "",
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Company List action handlers ================================*/
    .addCase(fetchCompanies, (state) => {
      state.isLoading = true
    })
    .addCase(fetchCompaniesSuccess, (state, action) => {
      state.isLoading = false
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      action.payload.isWithoutPaginate
        ? (state.allCompanies = action.payload.companies)
        : (state.companies = action.payload.companies)
      state.pager = action.payload.pager
    })
    .addCase(fetchCompaniesFailed, (state) => {
      state.isLoading = false
    })
    .addCase(clearCompanies, (state) => {
      state.companies = []
      state.isLoading = false
      state.companySuccessMessage = ""
      state.pager = {
        totalCount: 0,
        page: 0,
      }
    })

    /*================================ Company Form action handlers ================================*/
    .addCase(createCompanySuccess, (state, action) => {
      state.companySuccessMessage = `Successfully created company: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updateCompanySuccess, (state, action) => {
      state.companySuccessMessage = `Successfully updated company: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSaveCompanySuccess, (state) => {
      state.companySuccessMessage = initialState.companySuccessMessage
    })
)
