import { DEAL_CSV_TYPES, OPERATION_TYPES_ENP } from "../constants"
import { Deal, DealForExport } from "../redux/types/dealTypes"
import {
  FetchAndExportTagsFromSitesPayload,
  FetchAndExportTagsFromTagsPayload,
} from "../redux/types/tagTypes"

import { FetchAndExportPublishersPayload } from "../redux/types/publisherTypes"
import _ from "lodash"
import dayjs from "dayjs"
import { numberToDollars } from "./formatterHelper"
import { usePapaParse } from "react-papaparse"

// rowOffset is necessary to display the proper row line to the user
const rowOffset = 2
const { jsonToCSV } = usePapaParse()

/**
 * This function takes a CSV file parsed by papa parse and checks to see if it
 * contains the required headers.
 * Returns errors
 *
 * @param parsedCSVData - array with each element representing a row of the uploaded CSV
 * @param requiredHeaders - string[] of headers that are required to be in the CSV
 */
export const validateHeadersForRequiredValues = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  parsedCSVData: any[],
  requiredHeaders: string[]
): string[] => {
  const errors = []
  const csvHeadersArray = Object.keys(parsedCSVData[0] || {})

  const missingRequiredHeaders = []

  requiredHeaders.forEach((requiredHeader) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    csvHeadersArray.indexOf(requiredHeader) === -1 && missingRequiredHeaders.push(requiredHeader)
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  missingRequiredHeaders.length &&
    errors.push(`CSV is missing the following headers: ${missingRequiredHeaders.join(", ")}`)

  return errors
}

/**
 * This function takes a CSV file parsed by papa parse and checks to see if there
 * are any empty values in the given columns
 *
 * @param parsedCSVData - array with each element representing a row of the uploaded CSV
 * @param columnsToCheckForEmptyValues - columns to validate
 */
export const validateColumnsForEmptyValues = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  parsedCSVData: any[],
  columnsToCheckForEmptyValues: string[]
): string[] => {
  if (!parsedCSVData.length) return []
  const errors = []

  // keeps track of cells with empty values by pushing row numbers into object properties
  // representing the csv columns

  // e.g.
  // const emptyColumnValues = {
  //   "external id": [1, 2],
  //   "hierarchy": [2, 4]
  // }
  const emptyColumnValues = columnsToCheckForEmptyValues.reduce((result, columnHeader) => {
    result[columnHeader] = []
    return result
  }, {})

  columnsToCheckForEmptyValues.forEach((columnName) => {
    parsedCSVData.forEach((row, i) => {
      const valueToCheck = row[columnName]
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !valueToCheck && emptyColumnValues[columnName].push(i + rowOffset)
    })
  })

  columnsToCheckForEmptyValues.forEach((columnName) => {
    const emptyRows = emptyColumnValues[columnName]
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !!emptyRows.length && errors.push(`Empty ${columnName} on row(s): ${emptyRows.join(", ")}`)
  })

  return errors
}

/**
 * This function takes a CSV file parsed by papa parse and checks the given columns for duplicates
 * Returns errors
 *
 * @param parsedCSVData - array with each element representing a row of the uploaded CSV
 * @param columnsToCheckForUniqueness - columns to validate
 */
export const validateColumnsForDuplicateValues = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  parsedCSVData: any[],
  columnsToCheckForUniqueness: string[]
): string[] => {
  const errors = []

  // const uniqueColumnValues = {
  //   "external id": {
  //     "exampleExternalId": 1,
  //     "exampleExternalId2": 4,
  //     "exampleExternalId3": 5,
  //   },
  //   "hierarchy": {
  //     "Example > hierarchy > 1": 2,
  //     "Another > Example > hierarchy": 7,
  //   },
  // }
  const uniqueColumnValues = columnsToCheckForUniqueness.reduce((result, columnHeader) => {
    result[columnHeader] = {}
    return result
  }, {})

  // const duplicateTracker = {
  //   "external id": {
  //     "exampleExternalId": [1, 2],
  //     "exampleExternalId2": [4, 7]
  //   },
  //   "hierarchy": {
  //     "Example > hierarchy > 1": [2, 4],
  //     "Another > Example > hierarchy": [7, 21],
  //   },
  // }
  const duplicateTracker = _.cloneDeep(uniqueColumnValues)

  parsedCSVData.forEach((row, i) => {
    columnsToCheckForUniqueness.forEach((columnName) => {
      const valueToCheck = row[columnName]
      const seenValues = uniqueColumnValues[columnName]

      // if seenValues contains current valueToCheck as a key
      // current value is a duplicate
      if (seenValues.hasOwnProperty(valueToCheck)) {
        const seenDuplicateValues = duplicateTracker[columnName]

        // if duplicateTracker does not contain valueToCheck as a key
        // that means this is the first duplicate
        if (!seenDuplicateValues.hasOwnProperty(valueToCheck)) {
          // add valueToCheck as a key in duplicateTracker, and set value to
          // be an array of the row numbers where duplicates are found
          const first = seenValues[valueToCheck]
          seenDuplicateValues[valueToCheck] = [first, i + rowOffset]
        } else {
          // if duplicateTracker already has the key, push current row number to the array
          seenDuplicateValues[valueToCheck].push(i + rowOffset)
        }
      } else {
        // we have not seen the valueToCheck before, so add it to seenValues
        seenValues[valueToCheck] = i + rowOffset
      }
    })
  })

  Object.keys(duplicateTracker).forEach((columnHeader) => {
    const duplicatedValues = duplicateTracker[columnHeader]
    Object.keys(duplicatedValues).forEach((value) => {
      const duplicatedRows = duplicatedValues[value]
      errors.push(`Duplicate ${columnHeader} (${value}) on row(s): ${duplicatedRows.join(", ")}`)
    })
  })

  return errors
}

/**
 * Takes an array of deals and mutates each deal to have properties that are more human readable
 * Returns formatted deals
 *
 * @param deals - array of deals
 */
const formatDealsForExportAsCsv = (deals: Deal[]): DealForExport[] => {
  return deals.map(
    (deal: Deal): DealForExport => ({
      id: deal.id,
      name: deal.name,
      status: deal.status,
      auctionType: _.startCase(deal.auctionType),
      mediaType: _.startCase(deal.mediaType),
      supplyType: deal.supplyType,
      deviceType: _.startCase(deal.deviceType),
      createdAt: dayjs(deal.createdAt).format("LLL"),
      updatedAt: dayjs(deal.updatedAt).format("LLL"),
      dmpSegmentLogic: deal.dmpSegmentLogic !== OPERATION_TYPES_ENP.NONE ? "Yes" : "No",
      externalId: deal.externalId,
      price: numberToDollars(deal.price),
      bidder: deal.bidder.name,
      wseatIds: deal.wseatIds,
      cmpCheck: deal.cmpCheck,
    })
  )
}

/**
 * Takes an array of deals, formats them, and forces a download
 *
 * @param deals - array of deals
 * @param focusedCompanyName - name of user's active company
 */
export const exportDealsAsCsv = (deals: Deal[], focusedCompanyName: string): void => {
  if (!deals.length) return

  const formattedDeals = formatDealsForExportAsCsv(deals)

  const csvHeaders = jsonToCSV(
    {
      fields: [
        "ID",
        "Name",
        "Status",
        "Auction Type",
        "Media Type",
        "Supply Type",
        "Device Type",
        "Created At",
        "Updated At",
        "Data Connected?",
        "External Id",
        "Price",
        "Bidder",
        "W Seats",
        "Cmp Check",
      ],
      data: [],
    },
    { header: true }
  )
  const csvData = jsonToCSV(formattedDeals, { header: false })
  // splitting up headers and the deal data like so is necessary for custom headers since papaparse
  // does not have much functionality with regards to data manipulation.

  const csv = new Blob([csvHeaders + csvData], { type: "text/csv;charset=utf-8;" })
  createAndClickExportDealLink(csv, focusedCompanyName)
}

export const createAndClickExportDealLink = (csv: Blob, focusedCompanyName: string): void => {
  const csvURL = window.URL.createObjectURL(csv)
  const csvDownloadLink = document.createElement("a")
  csvDownloadLink.download = generateCSVFilename(focusedCompanyName)
  csvDownloadLink.href = csvURL
  csvDownloadLink.click()
  csvDownloadLink.remove()
}

export const generateCSVFilename = (focusedCompanyName: string): string => {
  const datetime = dayjs().format("YYYY.DD.MM_HH.mm.ss")
  return `${focusedCompanyName.split(" ").join("_")}.DealsExport.${datetime}.csv`
}

export const exportTagsAsCsvFromSites = (
  csvText: string,
  payload: FetchAndExportTagsFromSitesPayload
) => {
  let fileName = `export__publisher_id=${payload.publisherId}`
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  payload.siteFilters.ids.length > 0 &&
    (fileName += `&selected_site_ids=${payload.siteFilters.ids.join(",")}`)
  saveCsv(csvText, fileName)
}

export const exportTagsAsCsvFromTags = (
  csvText: string,
  payload: FetchAndExportTagsFromTagsPayload
) => {
  let fileName = `export__site_id=${payload.siteId}`
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  payload.tagFilters.ids.length > 0 &&
    (fileName += `&selected_tag_ids=${payload.tagFilters.ids.join(",")}`)

  saveCsv(csvText, fileName)
}

export const exportPublishersAsCsv = (
  csvText: string,
  request: FetchAndExportPublishersPayload
) => {
  const term = request.filters.term
  const status = request.filters.status

  let fileName = "publishers"
  fileName += term ? `__term=${term}` : ""
  fileName += status ? `__status=${status}` : ""

  saveCsv(csvText, fileName)
}

export const saveCsv = (csvText: string, fileName: string) => {
  const csv = new Blob([csvText], { type: "text/csv;charset=utf-8;" })
  const csvURL = window.URL.createObjectURL(csv)
  const csvDownloadLink = document.createElement("a")

  csvDownloadLink.download = `${fileName}.csv`
  csvDownloadLink.href = csvURL
  csvDownloadLink.click()
  csvDownloadLink.remove()
}

const formatListForExportAsCsv = (list, filterName: DEAL_CSV_TYPES): string[] => {
  switch (filterName) {
    case DEAL_CSV_TYPES.PUBLISHERS:
      return list.map((publisher) => ({
        id: publisher.id,
      }))
    case DEAL_CSV_TYPES.DOMAINS:
      return list.map((domain) => ({ name: domain.name }))
    default:
      break
  }
}

export const exportFilterListAsCsv = (
  list: string[],
  opts: { dealName: string; filterName: DEAL_CSV_TYPES }
): void => {
  if (!list.length) return

  const formattedList = formatListForExportAsCsv(list, opts.filterName)

  const csvData = jsonToCSV(formattedList, { header: false })
  const fileName = `${opts.dealName}-${opts.filterName}-list`

  saveCsv(csvData, fileName)
}
