import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createAudience,
  createAudienceFailed,
  createAudienceSuccess,
} from "../actions/audienceActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { CreateAudiencePayload } from "../types/audienceTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

export function* createAudienceSaga(action: PayloadAction<CreateAudiencePayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/audiences`,
    data: {
      audience: _.omit(action.payload, "audienceCreationType"),
    },
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(
      createAudienceSuccess({
        ...response.data,
        audienceCreationType: action.payload.audienceCreationType,
      })
    )
  } catch (err) {
    yield handleSagaError(createAudienceFailed, err)
  }
}

export function* watchCreateAudience(): SagaIterator {
  yield takeEvery(createAudience, createAudienceSaga)
}
