import * as Yup from "yup"

import {
  AUCTION_TYPE,
  DEAL_CSV_TYPES,
  DEVICE_TYPE,
  DMP_SEGMENT_FEE,
  DMP_SEGMENT_FEE_TYPE,
  END_PATHS,
  ENTITY_FILTER_TYPE,
  ENTITY_STATUSES,
  FEATURE_FLAGS,
  MAX_DOMAINS_INPUT,
  MAX_IAB_CONTENT_CATEGORIES_INPUT,
  MAX_PUBLISHERS_INPUT,
  MEDIA_TYPE,
  OPERATION_TYPES_ENP,
  PERMISSIONS,
  SCORE_THRESHOLD_RANGES,
  SCORE_THRESHOLD_TYPE,
  SEGMENT_VENDOR_NAME,
  SUPPLY_TYPE,
  TARGETING_TYPE,
} from "../../../../../constants"
import { Alert, Autocomplete } from "@material-ui/lab"
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  Hidden,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { CreateAndUpdateDealPayload, Deal } from "../../../../../redux/types/dealTypes"
import { FieldArray, Formik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearDealDomains,
  clearPublishers,
  clearUploadedDealDomains,
  clearUploadedDealPublishers,
  createDeal,
  fetchDealBidders,
  fetchDealById,
  fetchDealCountries,
  fetchDealDomains,
  fetchDealExternalAudiences,
  fetchDealIabContentCategories,
  fetchDealPredictiveAudiences,
  fetchDealPublishers,
  resetDealFormState,
  updateDeal,
  uploadDealDomainsSuccess,
  uploadDealPublishersSuccess,
} from "../../../../../redux/actions/dealActions"
import {
  clearFeatureFlags,
  fetchFeatureFlagByTerm,
} from "../../../../../redux/actions/featureFlagActions"
import {
  flattenFormattedSegmentGroups,
  formatRtbxSegmentGroups,
  formatSegmentGroups,
} from "../../../../../helpers/segmentHelpers"
import { trimPayloadProperties, validateArray } from "../../../../../helpers/formatterHelper"

import AudiencesList from "../../Audience/AudiencesList"
import { Bidder } from "../../../../../redux/types/sharedTypes"
import Can from "../../../../common/Can"
import { CloudDownloadOutlined } from "@material-ui/icons"
import CsvUpload from "./CsvUpload"
import DeleteIcon from "@material-ui/icons/Delete"
import { Edit as EditIcon } from "react-feather"
import ErrorMessages from "../../../../common/Errors"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import NewHeader from "../../../../NewHeader/NewHeader"
import { PredictiveAudience } from "../../../../../redux/types/predictiveAudienceTypes"
import SegmentGroupLogicSummary from "../../../../common/SegmentGroupLogicSummary"
import _ from "lodash"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import clsx from "clsx"
import dayjs from "dayjs"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import { exportFilterListAsCsv } from "../../../../../helpers/csvHelpers"
import { fetchSegments } from "../../../../../redux/actions/segmentActions"
import styles from "./styles"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

dayjs.extend(LocalizedFormat)

export const buildHeaderInfo = (
  isCreate,
  isEdit,
  isInfo,
  isCopy,
  deal: Deal = {} as Deal
): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Deals",
      route: "/tools/deals",
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Deal"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit) {
    headerText = "Deal: "
    if (deal.id) {
      headerText = `Deal: ${deal.name} (${deal.id})`
      breadcrumbs.push({
        name: `${deal.name} (${deal.id})`,
        route: `/tools/deals/${deal.id}`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  if (isInfo) {
    headerText = "Deal: "
    if (deal.id) {
      headerText = `Deal: ${deal.name} (${deal.id})`
      breadcrumbs.push({
        name: `${deal.name} (${deal.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/tools/deals/${deal.id}/edit`,
        permission: PERMISSIONS.TOOLS.DEAL.UPDATE,
        icon: <EditIcon />,
      })
    }
  }

  if (isCopy) {
    headerText = "Copy of Deal: "
    if (deal.id) {
      headerText = `Copy of Deal: ${deal.name} (${deal.id})`
      breadcrumbs.push({
        name: `${deal.name} (${deal.id})`,
        route: `/tools/deals/${deal.id}`,
      })

      breadcrumbs.push({
        name: `Copy`,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const DealForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()

  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)

  const isSaved = useSelector((state: RootState) => state.dealForm.isSaved)
  const isLoading = useSelector((state: RootState) => state.dealForm.isLoading)
  const isLoadingBidders = useSelector((state: RootState) => state.dealForm.isLoadingBidders)
  const isLoadingCountries = useSelector((state: RootState) => state.dealForm.isLoadingCountries)
  const isLoadingIabContentCategories = useSelector(
    (state: RootState) => state.dealForm.isLoadingIabContentCategories
  )
  const isLoadingDomains = useSelector((state: RootState) => state.dealForm.isLoadingDomains)
  const isLoadingPublishers = useSelector((state: RootState) => state.dealForm.isLoadingPublishers)
  const isLoadingExternalAudiences = useSelector(
    (state: RootState) => state.dealForm.isLoadingExternalAudiences
  )
  const isLoadingPredictiveAudiences = useSelector(
    (state: RootState) => state.dealForm.isLoadingPredictiveAudiences
  )
  const dealsSegments = useSelector((state: RootState) => state.segments.dealsSegments)

  const selectableIabContentCategories = useSelector(
    (state: RootState) => state.dealForm.iabContentCategories
  )

  const selectableBidders = useSelector((state: RootState) => state.dealForm.bidders)
  const selectableCountries = useSelector((state: RootState) => state.dealForm.countries)

  const selectableExternalAudiences = useSelector(
    (state: RootState) => state.dealForm.externalAudiences
  )
  const selectablePredictiveAudiences = useSelector(
    (state: RootState) => state.dealForm.predictiveAudiences
  )
  const selectableDomains = useSelector((state: RootState) => state.dealForm.domains)
  const domainsPage = useSelector((state: RootState) => state.dealForm.domainsPage)
  const [domainsSearchTerm, setDomainsSearchTerm] = useState("")

  const selectablePublishers = useSelector((state: RootState) => state.dealForm.publishers)
  const publishersPage = useSelector((state: RootState) => state.dealForm.publishersPage)
  const [publishersSearchTerm, setPublishersSearchTerm] = useState("")

  const [selectableTargetingTypes, setSelectableTargetingTypes] = useState([
    {
      value: TARGETING_TYPE.CONTENT_CATEGORY_V1,
      label: "Content Category v1",
      isSelected: false,
    },
  ])

  // state passed into CsvUpload component
  const uploadedDomains = useSelector((state: RootState) => state.dealForm.uploadedDomains)
  const uploadedPublishers = useSelector((state: RootState) => state.dealForm.uploadedPublishers)

  const deal = useSelector((state: RootState) => state.dealForm.selectedDeal)
  const isSubmitting = useSelector((state: RootState) => state.dealForm.isSubmitting)
  const generalErrors = useSelector((state: RootState) => state.errors.generalErrors)

  const isPredictiveDealsFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isPredictiveDealsFFEnabled
  )

  const isPredScoreThresholdFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isPredScoreThresholdFFEnabled
  )

  const isNewTargetingUIFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isNewTargetingUIFFEnabled
  )

  const isLivestreamTargetingFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isLivestreamTargetingFFEnabled
  )

  const isFeatureFlagLoading = useSelector((state: RootState) => state.featureFlags.isLoading)

  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isCreate = endPath === END_PATHS.CREATE
  const isEdit = endPath === END_PATHS.EDIT
  const isCopy = endPath === END_PATHS.COPY
  const isInfo = !isCreate && !isEdit && !isCopy

  const [isCopyDeal, setIsCopyDeal] = useState(false)
  const canCopy = isEdit && deal.status !== ENTITY_STATUSES.ARCHIVED

  const [biddersSearchListInput, setBiddersSearchListInput] = useState("")

  const isFocusedCompanyAudigent =
    currentUser?.focusedCompany?.name === SEGMENT_VENDOR_NAME.AUDIGENT
  const [isDataConnectedDeal, setIsDataConnectedDeal] = useState("false")

  const [iabContentCategoryFilterType, setIabContentCategoryFilterType] = useState(
    ENTITY_FILTER_TYPE.NONE
  )

  const [livestreamFilterType, setLivestreamFilterType] = useState(ENTITY_FILTER_TYPE.NONE)

  useEffect(() => {
    // clean up stale data when component unmounts
    return () => {
      dispatch(clearErrors())
      dispatch(resetDealFormState())
      dispatch(clearFeatureFlags())
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selectableBidders.length === 0 && !isInfo && dispatch(fetchDealBidders())
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selectableCountries.length === 0 && !isInfo && dispatch(fetchDealCountries())
  }, [selectableBidders, selectableCountries, isInfo])

  useEffect(() => {
    // Fetch feature flag to determine if predictive deals is enabled
    if (isPredictiveDealsFFEnabled === undefined) {
      dispatch(fetchFeatureFlagByTerm(FEATURE_FLAGS.ENABLE_PREDICTIVE_DEALS))
    }
    // Fetch feature flag to determine if predictive score threshold is enabled
    if (isPredScoreThresholdFFEnabled === undefined) {
      dispatch(fetchFeatureFlagByTerm(FEATURE_FLAGS.ENABLE_PREDICTIVE_SCORE_THRESHOLD))
    }
    // Fetch feature flag to determine if the new UI for targeting is enabled
    if (isNewTargetingUIFFEnabled === undefined) {
      dispatch(fetchFeatureFlagByTerm(FEATURE_FLAGS.ENABLE_NEW_TARGETING_UI))
    }
    // Fetch feature flag to determine if the live stream targeting is enabled
    if (isLivestreamTargetingFFEnabled === undefined) {
      dispatch(fetchFeatureFlagByTerm(FEATURE_FLAGS.ENABLE_LIVESTREAM_TARGETING))
    }
  }, [
    isPredictiveDealsFFEnabled,
    isPredScoreThresholdFFEnabled,
    isNewTargetingUIFFEnabled,
    isLivestreamTargetingFFEnabled,
  ])

  useEffect(() => {
    const isLivestreamTargetingTypeAdded = selectableTargetingTypes.some(
      (targetingType) => targetingType.value === TARGETING_TYPE.LIVESTREAM
    )
    // If the feature flag is enabled, and the live stream targeting type is not added, add it
    if (isLivestreamTargetingFFEnabled && !isLivestreamTargetingTypeAdded) {
      const updatedSelectableTargetingTypes = [...selectableTargetingTypes]
      updatedSelectableTargetingTypes.push({
        value: TARGETING_TYPE.LIVESTREAM,
        label: "Content Livestream",
        isSelected: false,
      })
      setSelectableTargetingTypes(updatedSelectableTargetingTypes)
    }
  }, [isLivestreamTargetingFFEnabled])

  useEffect(() => {
    // checks if form is create or edit, if edit, fetch deal info and pre-populate form data
    if ((isInfo || isEdit || isCopy) && !deal.id) dispatch(fetchDealById(match.params.dealId))

    setBiddersSearchListInput(deal.bidder.name)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    deal.dmpSegmentLogic !== OPERATION_TYPES_ENP.NONE &&
      isDataConnectedDeal != "legacy" &&
      setIsDataConnectedDeal("legacy")
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    deal.externalAudienceId !== null &&
      isDataConnectedDeal != "audience" &&
      setIsDataConnectedDeal("audience")
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    deal.predictiveAudienceId !== null &&
      isDataConnectedDeal != "predictive" &&
      setIsDataConnectedDeal("predictive")

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    deal.iabContentCategoryFilterType !== ENTITY_FILTER_TYPE.NONE &&
      iabContentCategoryFilterType != deal.iabContentCategoryFilterType &&
      setIabContentCategoryFilterType(deal.iabContentCategoryFilterType as ENTITY_FILTER_TYPE)

    if (
      isLivestreamTargetingFFEnabled &&
      deal.livestreamFilterType !== ENTITY_FILTER_TYPE.NONE &&
      livestreamFilterType !== deal.livestreamFilterType
    ) {
      setLivestreamFilterType(deal.livestreamFilterType as ENTITY_FILTER_TYPE)
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isCopyDeal && history.push(`/tools/deals/${deal.id}/copy`)
    // when the deal is successfully created navigate back to deals list
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isSaved && history.push("/tools/deals")
  }, [deal, isSaved, isCopyDeal])

  //Fetch segments/external audiences/predictive audiences only if dataConnectedDeal selected
  useEffect(() => {
    switch (isDataConnectedDeal) {
      case "legacy":
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        dealsSegments.length === 0 &&
          !isInfo &&
          dispatch(
            fetchSegments({
              filters: {
                segmentVendorId: null,
              },
            })
          )
        break
      case "audience":
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        selectableExternalAudiences.length === 0 &&
          !isInfo &&
          dispatch(
            fetchDealExternalAudiences({
              pagination: { paginate: false },
              filters: { status: "active" },
            })
          )
        break
      case "predictive":
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        selectablePredictiveAudiences.length === 0 &&
          isPredictiveDealsFFEnabled &&
          !isInfo &&
          dispatch(
            fetchDealPredictiveAudiences({
              pagination: { paginate: false },
              sort: { target: "name", order: "ASC" },
              filters: { status: "active" },
            })
          )
        break
    }
  }, [isDataConnectedDeal, isInfo, isPredictiveDealsFFEnabled])

  useEffect(() => {
    // When the filter type is not none and there are no selectable categories, fetch the categories
    if (
      iabContentCategoryFilterType !== ENTITY_FILTER_TYPE.NONE &&
      selectableIabContentCategories.length === 0
    ) {
      dispatch(fetchDealIabContentCategories())
    }
  }, [iabContentCategoryFilterType])

  useEffect(() => {
    if (initialFormValues?.targetingGroups?.length > 0) {
      setSelectableTargetingTypes(
        selectableTargetingTypes.map((targetingType) => {
          return {
            ...targetingType,
            isSelected: initialFormValues?.targetingGroups.some(
              (targetingGroup) => targetingGroup.targetingType === targetingType.value
            ),
          }
        })
      )
    }
  }, [deal])

  const dispatchFetchDealDomains = (searchTerm: string, pageNumber: 0, clear: true): void => {
    // fetch domains only if there is a search term
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    searchTerm.length &&
      dispatch(
        fetchDealDomains({
          term: searchTerm,
          page: pageNumber,
          clear,
        })
      )
  }
  const dispatchFetchDealPublishers = (searchTerm: string, pageNumber: 0, clear: true): void => {
    // fetch publishers only if there is a publsaerch getme
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    searchTerm.length &&
      dispatch(
        fetchDealPublishers({
          term: searchTerm,
          page: pageNumber,
          clear,
        })
      )
  }

  const debounceFetchDealDomains = useCallback(debounceFn(dispatchFetchDealDomains), [])
  const debounceFetchDealPublishers = useCallback(debounceFn(dispatchFetchDealPublishers), [])
  const getDmpSegmentFeeType = () => {
    if (deal.dmpSegmentFeeType) {
      return deal.dmpSegmentFeeType
    } else if (isFocusedCompanyAudigent) {
      return DMP_SEGMENT_FEE_TYPE.PERCENTAGE
    }
    return DMP_SEGMENT_FEE_TYPE.FIXED
  }
  const getDmpSegmentFee = () => {
    if (deal.dmpSegmentFee) {
      return deal.dmpSegmentFee
    } else if (
      isFocusedCompanyAudigent &&
      (deal.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE ||
        deal.dmpSegmentFeeType === null)
    ) {
      return DMP_SEGMENT_FEE.PERCENTAGE_AUDIGENT
    }
    return DMP_SEGMENT_FEE.DEFAULT
  }

  const contentCategoryV1StaticData = {
    targetingType: TARGETING_TYPE.CONTENT_CATEGORY_V1,
    targetingTypeTestId: "iab-content-categories-targeting-type",
    filterTypeFieldName: "iabContentCategoryFilterType",
    filterTypeLabel: "IAB Content Category Filter Type",
    filterTypeTestId: "iab-content-categories-filter-type",
    multiSelectFieldName: "iabContentCategories",
    multiSelectLabel: "IAB Content Categories",
    multiSelectTestId: "iab-content-categories",
  }

  const livestreamStaticData = {
    targetingType: TARGETING_TYPE.LIVESTREAM,
    targetingTypeTestId: "content-livestream-targeting-type",
    filterTypeFieldName: "livestreamFilterType",
    filterTypeLabel: "Content Livestream Filter Type",
    filterTypeTestId: "content-livestream-filter-type",
  }

  const formatTargetingGroups = () => {
    const targetingGroups = []

    const contentCategoryV1 = {
      ...contentCategoryV1StaticData,
      filterType: deal.iabContentCategoryFilterType,
      multiSelection: deal.iabContentCategories,
    }

    const livestream = {
      ...livestreamStaticData,
      filterType: deal.livestreamFilterType,
    }

    const isContentCategoryV1Selected = contentCategoryV1.filterType !== ENTITY_FILTER_TYPE.NONE
    const isLivestreamSelected = livestream.filterType !== ENTITY_FILTER_TYPE.NONE // TODO: replace with ENTITY_FILTER_TYPE.NONE

    if (isContentCategoryV1Selected) {
      targetingGroups.push(contentCategoryV1)
    }

    if (isLivestreamTargetingFFEnabled && isLivestreamSelected) {
      targetingGroups.push(livestream)
    }

    return targetingGroups
  }

  const isOriginalDealDataConnected =
    deal.dmpSegmentLogic !== OPERATION_TYPES_ENP.NONE
      ? "legacy"
      : deal.externalAudienceId != null
      ? "audience"
      : deal.predictiveAudienceId != null
      ? "predictive"
      : "false"
  const initialFormValues = isCreate
    ? {
        status: ENTITY_STATUSES.INACTIVE,
        name: "",
        auctionType: AUCTION_TYPE.STANDARD,
        bidder: null,
        mediaType: MEDIA_TYPE.ALL,
        supplyType: SUPPLY_TYPE.ALL,
        deviceType: DEVICE_TYPE.ALL,
        price: 1,
        cmpCheck: false,
        cmpFee: "",
        iabContentCategoryFilterType: ENTITY_FILTER_TYPE.NONE,
        domainFilterType: ENTITY_FILTER_TYPE.NONE,
        publisherFilterType: ENTITY_FILTER_TYPE.NONE,
        wseatIds: [],
        viewRate: "",
        vcrRate: "",
        countries: [{ id: 840, name: "United States" }],
        iabContentCategories: [],
        domains: [],
        publishers: [],
        segmentGroups: [],
        dataConnected: "false",
        dmpSegmentFeeType: isFocusedCompanyAudigent
          ? DMP_SEGMENT_FEE_TYPE.PERCENTAGE
          : DMP_SEGMENT_FEE_TYPE.FIXED,
        dmpSegmentFee: isFocusedCompanyAudigent
          ? DMP_SEGMENT_FEE.PERCENTAGE_AUDIGENT
          : DMP_SEGMENT_FEE.DEFAULT,
        externalAudienceId: "",
        externalAudience: null,
        predictiveAudienceId: "",
        predictiveAudience: null,
        predScoreThresholdType: null,
        predScoreThresholdMin: null,
        predScoreThresholdMax: null,
        targetingGroups: [],
        livestreamFilterType: ENTITY_FILTER_TYPE.NONE,
      }
    : {
        status: deal.status,
        name: isCopy ? `${deal.name}__copy` : deal.name ?? "",
        auctionType: deal.auctionType,
        bidder: deal?.bidder?.id ? deal.bidder : null,
        mediaType: deal.mediaType,
        supplyType: deal.supplyType,
        deviceType: deal.deviceType,
        price: deal.price || "",
        cmpCheck: deal.cmpCheck,
        cmpFee: deal.cmpFee ?? "",
        iabContentCategoryFilterType: deal.iabContentCategoryFilterType,
        domainFilterType: deal.domainFilterType,
        publisherFilterType: deal.publisherFilterType,
        wseatIds: deal.wseatIds,
        viewRate: deal.viewRate || "",
        vcrRate: deal.vcrRate || "",
        countries: validateArray(deal.countries),
        iabContentCategories:
          selectableIabContentCategories.length > 0 ? validateArray(deal.iabContentCategories) : [],
        domains: validateArray(deal.domains),
        publishers: validateArray(deal.publishers),
        segmentGroups: formatRtbxSegmentGroups(validateArray(deal.segmentGroups)),
        dataConnected: isOriginalDealDataConnected,
        dmpSegmentFeeType: getDmpSegmentFeeType(),
        dmpSegmentFee: getDmpSegmentFee(),
        externalAudienceId: deal.externalAudienceId ?? "",
        externalAudience: deal.externalAudienceId ? deal.externalAudience : null,
        predictiveAudienceId: deal.predictiveAudienceId ?? "",
        predictiveAudience: deal.predictiveAudienceId ? deal.predictiveAudience : null,
        predScoreThresholdType: deal.predScoreThresholdType || null,
        predScoreThresholdMin: deal.predScoreThresholdMin ?? null,
        predScoreThresholdMax: deal.predScoreThresholdMax ?? null,
        targetingGroups: formatTargetingGroups(),
        livestreamFilterType: deal.livestreamFilterType,
      }
  const formValidationSchema = Yup.object().shape({
    // Deal details
    name: Yup.string().min(1).max(255, "Name is too long").required("Name is required"),
    auctionType: Yup.string().required(),
    status: Yup.string(),

    // Terms
    price: Yup.number().positive().nullable(true),
    cmpFee: Yup.number()
      .positive()
      .max(100)
      .nullable(true)
      .test(
        "has-one-decimal",
        "CMP Fee must not have more than one decimal",
        (cmpFee) => cmpFee == null || cmpFee.toString().match(/^\d*\.?\d{0,1}$/) !== null
      ),

    // Demand
    bidder: Yup.object().shape({
      id: Yup.number().min(1).required("Buyer is required"),
      name: Yup.string(),
    }),
    wseatIds: Yup.array()
      .test(
        "digit length",
        "W Seats cannot have more than 9 digits",
        (wseatValues) => !wseatValues.some((seatValue) => seatValue?.length > 9)
      )
      .test(
        "numeric type",
        "W Seats must be numbers",
        (wseatValues) => !wseatValues.some((seatValue) => parseInt(seatValue) != seatValue)
      ),

    // Supply: Who
    dataConnected: Yup.string().oneOf(["false", "legacy", "audience", "predictive"]),
    dmpSegmentFeeType: Yup.string(),
    dmpSegmentFee: Yup.number()
      .when(["dataConnected", "dmpSegmentFeeType"], {
        is: (dataConnected, dmpSegmentFeeType) => {
          return dataConnected !== "false" && dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE
        },
        then: Yup.number()
          .min(0, "Please select a value between 0 and 100")
          .max(100, "Please select a value between 0 and 100")
          .required("DMP Fee Percentage is required for data connected deals")
          .test(
            "has-one-decimal",
            "DMP Fee Percentage must not have more than one decimal",
            (dmpSegmentFee) =>
              dmpSegmentFee === null || dmpSegmentFee?.toString().match(/^\d*\.?\d{0,1}$/) !== null
          ),
      })
      .when(["dataConnected", "dmpSegmentFeeType"], {
        is: (dataConnected, dmpSegmentFeeType) => {
          return dataConnected !== "false" && dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.FIXED
        },
        then: Yup.number()
          .min(0, "Please select a value between 0 and 100")
          .max(100, "Please select a value between 0 and 100")
          .required("DMP Fee is required for data connected deals")
          .test(
            "has-two-decimal",
            "DMP Fee must not have more than two decimal",
            (dmpSegmentFee) =>
              dmpSegmentFee === null || dmpSegmentFee?.toString().match(/^\d*\.?\d{0,2}$/) !== null
          ),
      }),
    externalAudienceId: Yup.number()
      .integer()
      .positive()
      .when("dataConnected", {
        is: "audience",
        then: Yup.number().required("Audience ID is required for data connected deals"),
      }),
    predictiveAudienceId: Yup.number()
      .integer()
      .positive()
      .when("dataConnected", {
        is: "predictive",
        then: Yup.number().required("Predictive Audience ID is required for data connected deals"),
      }),
    predScoreThresholdMin: Yup.number()
      .integer("Minimum score threshold must be an integer")
      .min(0)
      .max(99, "Minimum score threshold must be smaller than or equal to 99")
      .nullable(true)
      .when("predictiveAudienceId", {
        is: (val: number | string) => !!val && isPredScoreThresholdFFEnabled,
        then: Yup.number()
          .required("Minimum score threshold is required")
          .test(
            "is-less-than-max",
            "Minimum score threshold must be at least 1 less than the maximum score threshold",
            (value, context) => value <= context.parent.predScoreThresholdMax - 1
          ),
      }),
    predScoreThresholdMax: Yup.number()
      .integer("Maximum score threshold must be an integer")
      .min(1, "Maximum score threshold must be greater than or equal to 1")
      .max(100, "Maximum score threshold must be smaller than or equal to 100")
      .nullable(true)
      .when("predictiveAudienceId", {
        is: (val: number | string) => !!val && isPredScoreThresholdFFEnabled,
        then: Yup.number()
          .required("Maximum score threshold is required")
          .test(
            "is-more-than-min",
            "Minimum score threshold must be at least 1 less than the maximum score threshold",
            (value, context) => value >= context.parent.predScoreThresholdMin + 1
          ),
      }),
    segmentGroups: Yup.array()
      .transform((segmentGroups) => flattenFormattedSegmentGroups(segmentGroups))
      .when("dataConnected", {
        is: "legacy",
        then: Yup.array().min(1, "At least one segment group is required for data connected deals"),
      }),

    // Supply: What
    iabContentCategories: Yup.array().when("iabContentCategoryFilterType", {
      is: (val) => val && val !== ENTITY_FILTER_TYPE.NONE,
      then: Yup.array(Yup.object())
        .min(1, "At least one category is required when filter type is selected")
        .max(
          MAX_IAB_CONTENT_CATEGORIES_INPUT,
          `Please select fewer than ${MAX_IAB_CONTENT_CATEGORIES_INPUT} categories`
        ),
    }),

    // Supply: Where
    countries: Yup.array(),
    mediaType: Yup.string().max(255),
    supplyType: Yup.string().max(255),
    deviceType: Yup.string().max(255),

    publisherFilterType: Yup.string().oneOf(Object.values(ENTITY_FILTER_TYPE)),
    publishers: Yup.array().when("publisherFilterType", {
      is: (val) => val !== ENTITY_FILTER_TYPE.NONE,
      then: Yup.array(Yup.object())
        .min(1, "At least one publisher is required when filter type is selected")
        .max(MAX_PUBLISHERS_INPUT, `Please select fewer than ${MAX_PUBLISHERS_INPUT} publishers`),
    }),
    domainFilterType: Yup.string().oneOf(Object.values(ENTITY_FILTER_TYPE)),
    domains: Yup.array().when("domainFilterType", {
      is: (val) => val && val !== ENTITY_FILTER_TYPE.NONE,
      then: Yup.array(Yup.object())
        .min(1, "At least one domain is required when filter type is selected")
        .max(MAX_DOMAINS_INPUT, `Please select fewer than ${MAX_DOMAINS_INPUT} domains`),
    }),

    viewRate: Yup.number()
      .integer("Minimum viewability rate must be an integer")
      .strict()
      .positive()
      // Product asked that a max be set but that it also accepts null
      .max(100, "Rate must be smaller than or equal to 100")
      .nullable(true),
    vcrRate: Yup.number()
      .integer("Minimum Video Completion Rate must be an integer")
      .strict()
      .positive()
      // Product asked that a max be set but that it also accepts null
      .max(100, "Rate must be smaller than or equal to 100")
      .nullable(true),
    iabContentCategoryFilterType: isNewTargetingUIFFEnabled
      ? Yup.string().when("targetingGroups", {
          is: (targetingGroups) =>
            targetingGroups.some(
              (targetingGroup) =>
                targetingGroup.targetingType === TARGETING_TYPE.CONTENT_CATEGORY_V1
            ),
          then: Yup.string()
            .oneOf(Object.values(ENTITY_FILTER_TYPE))
            .test(
              "is-not-none",
              "Content Category V1 filter type is required",
              (value) => value !== ENTITY_FILTER_TYPE.NONE
            ),
        })
      : Yup.string().oneOf(Object.values(ENTITY_FILTER_TYPE)),
    livestreamFilterType: isLivestreamTargetingFFEnabled
      ? Yup.string().when("targetingGroups", {
          is: (targetingGroups) =>
            targetingGroups.some(
              (targetingGroup) => targetingGroup.targetingType === TARGETING_TYPE.LIVESTREAM
            ),
          then: Yup.string()
            .oneOf(Object.values(ENTITY_FILTER_TYPE))
            .test(
              "is-not-none",
              "Content Livestream filter type is required",
              (value) => value !== ENTITY_FILTER_TYPE.NONE
            ),
        })
      : null,
  })

  const dealFormStatusOptions = [
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
  ]
  const dataConnectedOptions = [
    { value: "false", label: "Everyone" },
    { value: "legacy", label: "Data Connected", shouldHide: true },
    { value: "audience", label: "Audience Connected" },
    { value: "predictive", label: "Predictive Audience", shouldHide: !isPredictiveDealsFFEnabled },
  ]
  const scoreThresholdOptions = [
    {
      value: SCORE_THRESHOLD_TYPE.DEFAULT,
      label: "Scaled (Default)",
    },
    { value: SCORE_THRESHOLD_TYPE.BALANCED, label: "Balanced" },
    { value: SCORE_THRESHOLD_TYPE.FOCUSED, label: "Focused" },
    { value: SCORE_THRESHOLD_TYPE.CUSTOM, label: "Custom" },
  ]
  const dealTypeOptions = [
    { value: AUCTION_TYPE.STANDARD, label: "Standard" },
    { value: AUCTION_TYPE.FIXED_PRICE, label: "Fixed Price" },
  ]
  const mediaTypeOptions = [
    { value: MEDIA_TYPE.DISPLAY, label: "Display" },
    { value: MEDIA_TYPE.VIDEO, label: "Video" },
    { value: MEDIA_TYPE.ALL, label: "Display And Video" },
  ]
  const supplyTypeOptions = [
    { value: SUPPLY_TYPE.WEB, label: "Web" },
    { value: SUPPLY_TYPE.APP, label: "App" },
    { value: SUPPLY_TYPE.ALL, label: "Web And App" },
  ]
  const deviceTypeOptions = [
    { value: DEVICE_TYPE.DESKTOP, label: "Desktop" },
    { value: DEVICE_TYPE.MOBILE, label: "Mobile" },
    { value: DEVICE_TYPE.CTV, label: "CTV" },
    { value: DEVICE_TYPE.ALL, label: "Desktop, Mobile & CTV" },
  ]
  const entityFilterTypeOptions = [
    { value: ENTITY_FILTER_TYPE.NONE, label: "None" },
    { value: ENTITY_FILTER_TYPE.ALLOWLIST, label: "Allowlist" },
    { value: ENTITY_FILTER_TYPE.BLOCKLIST, label: "Blocklist" },
  ]
  const filterTypeOptions = [
    { value: ENTITY_FILTER_TYPE.ALLOWLIST, label: "Allow" },
    { value: ENTITY_FILTER_TYPE.BLOCKLIST, label: "Block" },
  ]

  const steps = [
    //
    { title: "Deal Details", subTitle: "" },
    { title: "Terms", subTitle: "" },
    { title: "Demand", subTitle: "" },
    { title: "Supply:", subTitle: "Who's Consuming Content?" },
    { title: "Supply:", subTitle: "What Content Are They Consuming?" },
    { title: "Supply:", subTitle: "Where Are They Consuming Content?" },
  ]

  const groupIabContentCategories = (iabContentCategory) => {
    const categoryGroupHeader = selectableIabContentCategories.find(
      ({ iab }) => iab === iabContentCategory.iab.split("-")[0]
    )
    return categoryGroupHeader?.name || "None"
  }

  const handleCopyDealClick = () => {
    setIsCopyDeal(true)
  }

  const handleAudienceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    setFieldValue: (field: string, value: string) => void
  ) => {
    handleChange(event)
    setIsDataConnectedDeal(event.target.value)

    // when switching from one to another, clear the previous values
    switch (event.target.value) {
      case "predictive":
        setFieldValue("externalAudience", null)
        setFieldValue("externalAudienceId", "")
        break
      case "audience":
        setFieldValue("predictiveAudience", null)
        setFieldValue("predictiveAudienceId", "")
        setFieldValue("predScoreThresholdType", null)
        setFieldValue("predScoreThresholdMin", null)
        setFieldValue("predScoreThresholdMax", null)
        break
      case "false":
        setFieldValue("externalAudience", null)
        setFieldValue("externalAudienceId", "")
        setFieldValue("predictiveAudience", null)
        setFieldValue("predictiveAudienceId", "")
        setFieldValue("predScoreThresholdType", null)
        setFieldValue("predScoreThresholdMin", null)
        setFieldValue("predScoreThresholdMax", null)
        break
    }
  }

  const handlePredictiveAudienceChange = (
    newValue: PredictiveAudience,
    values: Deal,
    setFieldValue: (field: string, value: string | number | PredictiveAudience) => void
  ) => {
    if (newValue) {
      setFieldValue("predictiveAudience", newValue)
      setFieldValue("predictiveAudienceId", newValue.id)
      if (values.predScoreThresholdType === null) {
        // set the default score threshold values if there are none
        setFieldValue("predScoreThresholdType", SCORE_THRESHOLD_TYPE.DEFAULT)
        setFieldValue("predScoreThresholdMin", SCORE_THRESHOLD_RANGES.DEFAULT_MIN)
        setFieldValue("predScoreThresholdMax", SCORE_THRESHOLD_RANGES.MAX)
      }
    } else {
      setFieldValue("predictiveAudience", null)
      setFieldValue("predictiveAudienceId", "")
      setFieldValue("predScoreThresholdType", null)
      setFieldValue("predScoreThresholdMin", null)
      setFieldValue("predScoreThresholdMax", null)
    }
  }

  const handlePredScoreThresholdTypeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: (field: string, value: string | number) => void
  ) => {
    setFieldValue("predScoreThresholdType", event.target.value)
    // set the min and max values based on the threshold type
    switch (event.target.value) {
      case SCORE_THRESHOLD_TYPE.DEFAULT:
        setFieldValue("predScoreThresholdMin", SCORE_THRESHOLD_RANGES.DEFAULT_MIN)
        setFieldValue("predScoreThresholdMax", SCORE_THRESHOLD_RANGES.MAX)
        break
      case SCORE_THRESHOLD_TYPE.BALANCED:
        setFieldValue("predScoreThresholdMin", SCORE_THRESHOLD_RANGES.BALANCED_MIN)
        setFieldValue("predScoreThresholdMax", SCORE_THRESHOLD_RANGES.MAX)
        break
      case SCORE_THRESHOLD_TYPE.FOCUSED:
        setFieldValue("predScoreThresholdMin", SCORE_THRESHOLD_RANGES.FOCUSED_MIN)
        setFieldValue("predScoreThresholdMax", SCORE_THRESHOLD_RANGES.MAX)
        break
      case SCORE_THRESHOLD_TYPE.CUSTOM:
        setFieldValue("predScoreThresholdMin", SCORE_THRESHOLD_RANGES.CUSTOM_MIN)
        setFieldValue("predScoreThresholdMax", SCORE_THRESHOLD_RANGES.MAX)
    }
  }

  const handlePredScoreThresholdSliderChange = (
    newValue: number[] | number,
    setFieldValue: (field: string, value: number | string) => void
  ) => {
    // set the threshold type to custom if user changes the slider
    setFieldValue("predScoreThresholdType", SCORE_THRESHOLD_TYPE.CUSTOM)

    // set the min and max values
    setFieldValue("predScoreThresholdMin", newValue[0])
    setFieldValue("predScoreThresholdMax", newValue[1])
  }

  const handlePredScoreThresholdInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    setFieldValue: (field: string, value: number | string) => void
  ) => {
    const newValue = event.target.value === "" ? "" : parseFloat(event.target.value)

    // update the form value
    setFieldValue(fieldName, newValue)

    // set the threshold type to custom if user changes the input
    setFieldValue("predScoreThresholdType", SCORE_THRESHOLD_TYPE.CUSTOM)
  }

  const isDisabled = isInfo || isLoading || isSubmitting
  const getStepContent = (
    step: number,
    values,
    touched,
    setFieldTouched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue
  ) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
            {/*Name*/}
            <Grid item md={6} xs={12}>
              <TextField
                label={<span className={clsx(isInfo && classes.overrideDisable)}>Deal Name</span>}
                name="name"
                error={!!(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.name}
                inputProps={{
                  "data-testid": "deal-form-name-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                disabled={isDisabled}
              >
                Name
              </TextField>
              {deal.createdAt && (
                <Typography>
                  <b>Created on: </b>
                  {dayjs(deal.createdAt).format("M-D-YYYY h:mm a")}
                  {deal.updatedAt && (
                    <>
                      <b> Last updated: </b>
                      {dayjs(deal.updatedAt).format("M-D-YYYY h:mm a")}
                    </>
                  )}
                </Typography>
              )}
            </Grid>
            {/* Auction Type*/}
            <Grid item md={3} xs={12}>
              <TextField
                select
                label={
                  <span className={clsx(isInfo && classes.overrideDisable)}>Auction Type</span>
                }
                name="auctionType"
                error={!!(touched.auctionType && errors.auctionType)}
                helperText={touched.auctionType && errors.auctionType}
                value={values.auctionType}
                onChange={handleChange}
                className={classes.dropDownField}
                inputProps={{
                  "data-testid": "deal-form-auction-type-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                disabled={isDisabled}
              >
                {dealTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/*Status*/}
            <Grid item md={3} xs={12}>
              <TextField
                select
                label={<span className={clsx(isInfo && classes.overrideDisable)}>Status</span>}
                name="status"
                error={!!(touched.status && errors.status)}
                helperText={touched.status && errors.status}
                value={values.status}
                onChange={handleChange}
                className={classes.dropDownField}
                inputProps={{
                  "data-testid": "deal-form-status-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                disabled={isDisabled}
              >
                {dealFormStatusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )
      case 1:
        return (
          <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
            {/*Price*/}
            <Grid item md={2} xs={12}>
              <TextField
                label={<span className={clsx(isInfo && classes.overrideDisable)}>Price ($)</span>}
                name="price"
                className={classes.dropDownField}
                type="number"
                error={!!(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                inputProps={{
                  "data-testid": "deal-form-price-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                onWheel={(e) => (e.target as HTMLElement).blur()}
                disabled={isDisabled}
              />
            </Grid>

            {/*CMP Fee*/}
            <Can
              perform={PERMISSIONS.TOOLS.DEAL.UPDATE_CMP_FEE}
              yes={
                <Grid item md={2} xs={12}>
                  <TextField
                    label={
                      <span
                        className={clsx(isInfo && values.cmpFee > 0 && classes.overrideDisable)}
                      >
                        CMP Fee (%)
                      </span>
                    }
                    name="cmpFee"
                    className={classes.dropDownField}
                    type="number"
                    error={!!(touched.cmpFee && errors.cmpFee)}
                    helperText={touched.cmpFee && errors.cmpFee}
                    placeholder="N/A"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cmpFee}
                    inputProps={{
                      "data-testid": "deal-form-cmp-fee-input",
                      step: "any",
                    }}
                    InputProps={{
                      classes: {
                        disabled: isInfo && values.cmpFee > 0 && classes.overrideDisable,
                      },
                    }}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    disabled={isDisabled}
                  />
                </Grid>
              }
            />

            {/* DMP Fee */}
            {values.dataConnected !== "false" && (
              <Grid item md={2} xs={12}>
                <TextField
                  label={<span className={clsx(isInfo && classes.overrideDisable)}>DMP Fee</span>}
                  name="dmpSegmentFee"
                  className={classes.dropDownField}
                  error={!!(touched.dmpSegmentFee && errors.dmpSegmentFee)}
                  helperText={touched.dmpSegmentFee && errors.dmpSegmentFee}
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dmpSegmentFee}
                  inputProps={{
                    "data-testid": "deal-form-dmp-segment-fee-input",
                  }}
                  InputProps={{
                    startAdornment: values.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.FIXED && (
                      <InputAdornment position="start">CPM $</InputAdornment>
                    ),
                    endAdornment: values.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE && (
                      <InputAdornment position="end">% of Bid</InputAdornment>
                    ),
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  disabled={isDisabled}
                />
                <RadioGroup
                  name="dmpSegmentFeeType"
                  className={classes.group}
                  value={values.dmpSegmentFeeType}
                  data-testid={"deal-form-dmp-fee-type"}
                  onChange={(selectedDmpSegmentFeeType) => {
                    setFieldValue("dmpSegmentFeeType", selectedDmpSegmentFeeType.target.value)
                    if (isFocusedCompanyAudigent) {
                      setFieldValue(
                        "dmpSegmentFee",
                        selectedDmpSegmentFeeType.target.value === DMP_SEGMENT_FEE_TYPE.PERCENTAGE
                          ? DMP_SEGMENT_FEE.PERCENTAGE_AUDIGENT
                          : DMP_SEGMENT_FEE.DEFAULT
                      )
                    }
                  }}
                >
                  <FormControlLabel
                    value={DMP_SEGMENT_FEE_TYPE.FIXED}
                    data-testid={"deal-form-dmp-fee-type-fixed"}
                    control={<Radio />}
                    label={
                      <span className={clsx(isInfo && classes.overrideDisable)}>Flat Rate CPM</span>
                    }
                    disabled={isDisabled}
                  />
                  <FormControlLabel
                    value={DMP_SEGMENT_FEE_TYPE.PERCENTAGE}
                    data-testid={"deal-form-dmp-fee-type-percentage"}
                    control={<Radio />}
                    label={
                      <span className={clsx(isInfo && classes.overrideDisable)}>% of Bid</span>
                    }
                    disabled={isDisabled}
                  />
                </RadioGroup>
              </Grid>
            )}
          </Grid>
        )
      case 2:
        return (
          <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                options={selectableBidders.filter((bidder) => bidder.status === "active")}
                value={values.bidder}
                fullWidth
                getOptionLabel={(option) => (option ? option.name : "")}
                getOptionSelected={(option, value) => value.id === option.id}
                data-testid={"deal-form-bidder-input"}
                onBlur={() => setFieldTouched("bidder")}
                onChange={(e, newValue: Bidder) => {
                  const newBidderValue = newValue ? newValue : ""
                  setFieldValue("bidder", newBidderValue)
                }}
                onInputChange={(e, newInputValue) => {
                  setBiddersSearchListInput(newInputValue)
                }}
                inputValue={biddersSearchListInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<span className={clsx(isInfo && classes.overrideDisable)}>Buyer</span>}
                    name={"bidder"}
                    error={!!(touched.bidder && errors.bidder)}
                    helperText={touched.bidder && errors.bidder?.id}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        disabled: classes.overrideDisable,
                      },
                    }}
                  />
                )}
                disabled={isDisabled || isLoadingBidders}
              />
            </Grid>
            <Hidden smDown>
              <Grid item md={6} />
            </Hidden>
            {/*Allowlist bidder seats*/}
            <Grid item md={6} xs={12}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={values.wseatIds}
                onChange={(event, newValue) => {
                  setFieldValue("wseatIds", newValue)
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span
                        className={clsx(
                          isInfo && values.wseatIds.length > 0 && classes.overrideDisable
                        )}
                      >
                        W Seat(s)
                      </span>
                    }
                    id="deal-form-wseat-ids-input"
                    data-testid="deal-form-wseat-ids-input"
                    error={!!(touched.wseatIds && errors.wseatIds)}
                    helperText={touched.wseatIds && errors.wseatIds}
                    variant="outlined"
                    fullWidth
                    disabled={isDisabled}
                  />
                )}
              />
            </Grid>
            <Hidden smDown>
              <Grid item md={6} />
            </Hidden>
          </Grid>
        )
      case 3:
        return (
          <>
            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              <Grid item md={3} xs={12}>
                <Tooltip
                  title={
                    isEdit && isOriginalDealDataConnected === "false"
                      ? `Switching supply audience from "Everyone" to "Data Connected" is not possible`
                      : ""
                  }
                >
                  {/* Is data connected? input field*/}
                  <TextField
                    select
                    label={
                      <span className={clsx(isInfo && classes.overrideDisable)}>Audience</span>
                    }
                    name="dataConnected"
                    className={classes.dropDownField}
                    error={!!(touched.dataConnected && errors.segmentGroups)}
                    helperText={touched.dataConnected && errors.segmentGroups}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleAudienceChange(e, handleChange, setFieldValue)
                    }}
                    value={values.dataConnected}
                    inputProps={{
                      "data-testid": "deal-form-data-connected-input",
                    }}
                    InputProps={{
                      classes: {
                        disabled: classes.overrideDisable,
                      },
                    }}
                    disabled={isDisabled || (isEdit && isOriginalDealDataConnected === "false")}
                  >
                    {dataConnectedOptions
                      .filter((option) => !option.shouldHide)
                      .map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Tooltip>
              </Grid>
            </Grid>
            {values.dataConnected === "legacy" && (
              <>
                {!isInfo && (
                  <AudiencesList
                    selectedAudience
                    handleSelectAudience={(e, audience) => {
                      const newSegmentGroups = e.target.checked
                        ? formatSegmentGroups(audience.segmentGroups)
                        : formatRtbxSegmentGroups(deal.segmentGroups)
                      setFieldValue("segmentGroups", newSegmentGroups)
                    }}
                    testId={"deal-form-audiences-list"}
                    showHeader={false}
                    showErrors={false}
                    enableNavigation={false}
                    multiSelect={false}
                    selectLimit={6}
                    rowsPerPageOptions={[5]}
                  />
                )}
                <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
                  <Grid item md={6} xs={12}>
                    <ListSubheader className={classes.listSubheader}>
                      <Typography className={classes.segmentGroup}>
                        Currently Targeted Segment Groups:
                      </Typography>
                    </ListSubheader>
                    <SegmentGroupLogicSummary
                      segmentGroupsValue={values.segmentGroups}
                      segmentData={dealsSegments}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {values.dataConnected === "audience" && (
              <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={selectableExternalAudiences}
                    value={values.externalAudience}
                    getOptionSelected={(option, value) => value.segmentId === option.segmentId}
                    getOptionLabel={(option) =>
                      option ? `${option.segmentName} (${option.segmentId})` : ""
                    }
                    fullWidth
                    data-testid={"deal-form-external-audience-id-input"}
                    onBlur={() => setFieldTouched("externalAudienceId")}
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        setFieldValue("externalAudience", newValue)
                        setFieldValue("externalAudienceId", newValue.segmentId)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span className={clsx(isInfo && classes.overrideDisable)}>
                            Select an Audience
                          </span>
                        }
                        name={"externalAudienceId"}
                        error={!!(touched.externalAudienceId && errors.externalAudienceId)}
                        helperText={touched.externalAudienceId && errors.externalAudienceId}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            disabled: classes.overrideDisable,
                          },
                        }}
                      />
                    )}
                    disabled={isDisabled || isLoadingExternalAudiences}
                  />
                </Grid>
              </Grid>
            )}
            {values.dataConnected === "predictive" && (
              <>
                <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      options={selectablePredictiveAudiences}
                      value={values.predictiveAudience}
                      getOptionSelected={(option, value) =>
                        value.predictiveLogicalId === option.predictiveLogicalId
                      }
                      getOptionLabel={(option) =>
                        option ? `${option.name} (${option.predictiveLogicalId})` : ""
                      }
                      fullWidth
                      data-testid={"deal-form-predictive-audience-id-input"}
                      onBlur={() => setFieldTouched("predictiveAudienceId")}
                      onChange={(_e, newValue) => {
                        handlePredictiveAudienceChange(newValue, values, setFieldValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span className={clsx(isInfo && classes.overrideDisable)}>
                              Select a Predictive Audience
                            </span>
                          }
                          name={"predictiveAudienceId"}
                          error={!!(touched.predictiveAudienceId && errors.predictiveAudienceId)}
                          helperText={touched.predictiveAudienceId && errors.predictiveAudienceId}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            classes: {
                              disabled: classes.overrideDisable,
                            },
                          }}
                        />
                      )}
                      disabled={isDisabled || isLoadingPredictiveAudiences}
                    />
                  </Grid>
                </Grid>

                {/* Predictive score threshold  */}
                {isPredScoreThresholdFFEnabled && values.predictiveAudienceId && (
                  <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        select
                        label={
                          <span className={clsx(isInfo && classes.overrideDisable)}>
                            Set Score Threshold
                          </span>
                        }
                        name="predScoreThresholdType"
                        className={classes.dropDownField}
                        error={!!(touched.predScoreThresholdType && errors.predScoreThresholdType)}
                        helperText={touched.predScoreThresholdType && errors.predScoreThresholdType}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handlePredScoreThresholdTypeChange(e, setFieldValue)
                        }}
                        value={values?.predScoreThresholdType}
                        inputProps={{
                          "data-testid": "deal-form-predictive-score-threshold-type-input",
                        }}
                        InputProps={{
                          classes: {
                            disabled: classes.overrideDisable,
                          },
                        }}
                        disabled={isDisabled}
                      >
                        {scoreThresholdOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={3} xs={12} container spacing={2} alignItems="center">
                      <Grid item md={3} xs={12}>
                        <TextField
                          name="predScoreThresholdMin"
                          type="number"
                          value={values.predScoreThresholdMin}
                          error={!!(touched.predScoreThresholdMin && errors.predScoreThresholdMin)}
                          onChange={(event) => {
                            handlePredScoreThresholdInputChange(
                              event,
                              "predScoreThresholdMin",
                              setFieldValue
                            )
                          }}
                          inputProps={{
                            "data-testid": "deal-form-predictive-score-threshold-min-input",
                            min: SCORE_THRESHOLD_RANGES.CUSTOM_MIN,
                            max: SCORE_THRESHOLD_RANGES.MAX - 1,
                          }}
                          variant="outlined"
                          fullWidth
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Slider
                          className={classes.slider}
                          data-testid={"deal-form-predictive-score-threshold-slider-input"}
                          getAriaLabel={() => "Predictive Score Threshold Slider"}
                          value={[
                            values.predScoreThresholdMin || SCORE_THRESHOLD_RANGES.CUSTOM_MIN,
                            values.predScoreThresholdMax || SCORE_THRESHOLD_RANGES.MAX,
                          ]}
                          onChange={(_event, newValue) => {
                            handlePredScoreThresholdSliderChange(newValue, setFieldValue)
                          }}
                          valueLabelDisplay="auto"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name="predScoreThresholdMax"
                          type="number"
                          value={values.predScoreThresholdMax}
                          error={!!(touched.predScoreThresholdMax && errors.predScoreThresholdMax)}
                          onChange={(event) => {
                            handlePredScoreThresholdInputChange(
                              event,
                              "predScoreThresholdMax",
                              setFieldValue
                            )
                          }}
                          inputProps={{
                            "data-testid": "deal-form-predictive-score-threshold-max-input",
                            min: SCORE_THRESHOLD_RANGES.CUSTOM_MIN + 1,
                            max: SCORE_THRESHOLD_RANGES.MAX,
                          }}
                          variant="outlined"
                          fullWidth
                          disabled={isDisabled}
                        />
                      </Grid>
                      <FormHelperText
                        data-testid={"deal-form-predictive-score-threshold-error"}
                        error={
                          !!(
                            (touched.predScoreThresholdMin && errors.predScoreThresholdMin) ||
                            (touched.predScoreThresholdMax && errors.predScoreThresholdMax)
                          )
                        }
                      >
                        {(touched.predScoreThresholdMin && errors.predScoreThresholdMin) ||
                          (touched.predScoreThresholdMax && errors.predScoreThresholdMax)}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </>
        )
      case 4:
        return isNewTargetingUIFFEnabled ? (
          <>
            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              {/* Media Type */}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={<span className={clsx(isInfo && classes.overrideDisable)}>Media</span>}
                  name="mediaType"
                  error={!!(touched.mediaType && errors.mediaType)}
                  helperText={touched.mediaType && errors.mediaType}
                  className={classes.dropDownField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mediaType}
                  inputProps={{
                    "data-testid": "deal-form-media-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {mediaTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              <FieldArray name="targetingGroups">
                {({ push, remove }) => (
                  <>
                    {values.targetingGroups?.map((targeting, index: number) => {
                      const isContentCategoryV1 =
                        targeting.targetingType === TARGETING_TYPE.CONTENT_CATEGORY_V1
                      const isLivestream = targeting.targetingType === TARGETING_TYPE.LIVESTREAM
                      return (
                        <React.Fragment key={targeting.targetingType}>
                          <Grid item md={3} xs={12}>
                            <TextField
                              select
                              name={targeting.targetingType}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Select To Add Targeting
                                </span>
                              }
                              className={classes.dropDownField}
                              value={targeting.targetingType}
                              inputProps={{
                                "data-testid": `deal-form-${targeting.targetingTypeTestId}-input`,
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isDisabled || !!targeting.targetingType}
                            >
                              {selectableTargetingTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  {targeting.filterTypeLabel}
                                </span>
                              }
                              name={targeting.filterTypeFieldName}
                              error={
                                !!(
                                  touched[targeting.filterTypeFieldName] &&
                                  errors[targeting.filterTypeFieldName]
                                )
                              }
                              helperText={
                                touched[targeting.filterTypeFieldName] &&
                                errors[targeting.filterTypeFieldName]
                              }
                              className={classes.dropDownField}
                              value={targeting.filterType || ""}
                              onChange={(e) => {
                                setFieldValue(
                                  `targetingGroups[${index}].filterType`,
                                  e.target.value
                                )
                                if (isContentCategoryV1) {
                                  setIabContentCategoryFilterType(
                                    e.target.value as ENTITY_FILTER_TYPE
                                  )
                                  setFieldValue(
                                    "iabContentCategoryFilterType",
                                    e.target.value as ENTITY_FILTER_TYPE
                                  )
                                }
                                if (isLivestreamTargetingFFEnabled && isLivestream) {
                                  setLivestreamFilterType(e.target.value as ENTITY_FILTER_TYPE)
                                  setFieldValue(
                                    "livestreamFilterType",
                                    e.target.value as ENTITY_FILTER_TYPE
                                  )
                                }
                              }}
                              inputProps={{
                                "data-testid": `deal-form-${targeting.filterTypeTestId}-input`,
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isDisabled}
                            >
                              {filterTypeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>

                          {/* MultiSelect */}
                          <Grid item md={5} xs={12}>
                            {targeting.multiSelectFieldName && (
                              <>
                                <Autocomplete
                                  options={isContentCategoryV1 && selectableIabContentCategories}
                                  value={targeting.multiSelection || []}
                                  multiple
                                  fullWidth
                                  data-testid={`deal-form-${targeting.multiSelectTestId}-input`}
                                  getOptionSelected={(option, value) => value.id === option.id}
                                  groupBy={(option) => {
                                    if (isContentCategoryV1) {
                                      return groupIabContentCategories(option)
                                    }
                                  }}
                                  getOptionLabel={(option) => {
                                    if (selectableIabContentCategories) {
                                      return option ? `${option.iab} ${option.name}` : ""
                                    }
                                  }}
                                  onChange={(event, newValue) => {
                                    if (isContentCategoryV1) {
                                      setFieldValue("iabContentCategories", newValue)
                                      setFieldValue(
                                        `targetingGroups[${index}].multiSelection`,
                                        newValue
                                      )
                                    }
                                  }}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        <span
                                          className={clsx(
                                            isInfo &&
                                              targeting.multiSelection.length > 0 &&
                                              classes.overrideDisable
                                          )}
                                        >
                                          {targeting.multiSelectLabel}
                                        </span>
                                      }
                                      name={targeting.multiSelectFieldName}
                                      error={
                                        !!(
                                          touched[targeting.multiSelectFieldName] &&
                                          errors[targeting.multiSelectFieldName]
                                        )
                                      }
                                      helperText={
                                        touched[targeting.multiSelectFieldName] &&
                                        errors[targeting.multiSelectFieldName]
                                      }
                                      onBlur={handleBlur}
                                      fullWidth
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        classes: {
                                          disabled:
                                            isInfo &&
                                            targeting.multiSelection?.length > 0 &&
                                            classes.overrideDisable,
                                        },
                                      }}
                                    />
                                  )}
                                  disabled={
                                    isDisabled ||
                                    (isContentCategoryV1 && isLoadingIabContentCategories) ||
                                    values[targeting.filterTypeFieldName] ===
                                      ENTITY_FILTER_TYPE.NONE
                                  }
                                />
                                {values[targeting.filterTypeFieldName] ===
                                  ENTITY_FILTER_TYPE.NONE &&
                                  !isInfo && (
                                    <FormHelperText component="div">
                                      <Typography
                                        className={classes.helperText}
                                        data-testid={`deal-form-${targeting.multiSelectTestId}-helper-text`}
                                      >
                                        {`** Select a ${targeting.filterTypeLabel} to add ${targeting.multiSelectLabel} **`}
                                      </Typography>
                                    </FormHelperText>
                                  )}
                              </>
                            )}
                          </Grid>

                          {/* delete button */}
                          <Grid item md={1} xs={12}>
                            <IconButton
                              edge="end"
                              data-testid={`deal-form-${targeting.targetingTypeTestId}-remove-button`}
                              onClick={() => {
                                const selectedIndex = selectableTargetingTypes.findIndex(
                                  (targetingType) => targetingType.value === targeting.targetingType
                                )
                                selectableTargetingTypes[selectedIndex].isSelected = false
                                setSelectableTargetingTypes([...selectableTargetingTypes])
                                remove(index)
                                if (isContentCategoryV1) {
                                  setFieldValue("iabContentCategories", [])
                                  setFieldValue(
                                    "iabContentCategoryFilterType",
                                    ENTITY_FILTER_TYPE.NONE
                                  )
                                }
                                if (isLivestreamTargetingFFEnabled && isLivestream) {
                                  setFieldValue("livestreamFilterType", ENTITY_FILTER_TYPE.NONE)
                                }
                              }}
                              aria-label="delete"
                              disabled={isDisabled}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </React.Fragment>
                      )
                    })}

                    {/* Add New Targeting Input */}
                    {selectableTargetingTypes.filter(({ isSelected }) => !isSelected).length >
                      0 && (
                      <Grid item md={3} xs={12}>
                        <TextField
                          select
                          label={
                            <span className={clsx(isInfo && classes.overrideDisable)}>
                              Select To Add Targeting
                            </span>
                          }
                          value=""
                          className={classes.dropDownField}
                          onChange={(e) => {
                            const selectedTargetingTypeIndex = selectableTargetingTypes.findIndex(
                              (targetingType) => targetingType.value === e.target.value
                            )

                            if (selectedTargetingTypeIndex !== -1) {
                              selectableTargetingTypes[selectedTargetingTypeIndex].isSelected = true
                              setSelectableTargetingTypes([...selectableTargetingTypes])
                            }

                            if (e.target.value === TARGETING_TYPE.CONTENT_CATEGORY_V1) {
                              push(contentCategoryV1StaticData)
                            }

                            if (
                              isLivestreamTargetingFFEnabled &&
                              e.target.value === TARGETING_TYPE.LIVESTREAM
                            ) {
                              push(livestreamStaticData)
                            }
                          }}
                          inputProps={{
                            "data-testid": "deal-form-add-targeting-type-input",
                          }}
                          InputProps={{
                            classes: {
                              disabled: classes.overrideDisable,
                            },
                          }}
                          disabled={isDisabled}
                        >
                          {selectableTargetingTypes
                            .filter(({ isSelected }) => !isSelected)
                            .map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    )}
                  </>
                )}
              </FieldArray>
            </Grid>
          </>
        ) : (
          <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                options={selectableIabContentCategories}
                value={values.iabContentCategories}
                multiple
                fullWidth
                data-testid={"deal-form-iab-content-categories-input"}
                getOptionSelected={(option, value) => value.id === option.id}
                groupBy={(option) => groupIabContentCategories(option)}
                getOptionLabel={(option) => (option ? `${option.iab} ${option.name}` : "")}
                onChange={(event, newValue) => {
                  setFieldValue("iabContentCategories", newValue)
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span
                        className={clsx(
                          isInfo &&
                            values.iabContentCategories.length > 0 &&
                            classes.overrideDisable
                        )}
                      >
                        IAB Content Categories
                      </span>
                    }
                    name={"iabContentCategories"}
                    error={!!(touched.iabContentCategories && errors.iabContentCategories)}
                    helperText={touched.iabContentCategories && errors.iabContentCategories}
                    onBlur={handleBlur}
                    fullWidth
                    placeholder="IAB Content Categories"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        disabled:
                          isInfo &&
                          values.iabContentCategories.length > 0 &&
                          classes.overrideDisable,
                      },
                    }}
                  />
                )}
                disabled={
                  isDisabled ||
                  isLoadingIabContentCategories ||
                  values.iabContentCategoryFilterType === ENTITY_FILTER_TYPE.NONE
                }
              />
              {values.iabContentCategoryFilterType === ENTITY_FILTER_TYPE.NONE && !isInfo && (
                <FormHelperText component="div">
                  <Typography
                    className={classes.helperText}
                    data-testid={"deal-form-iab-content-category-filter-helper-text"}
                  >
                    ** Select a IAB Content Category Filter Type to add IAB Content Categories **
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                select
                label={
                  <span className={clsx(isInfo && classes.overrideDisable)}>
                    IAB Content Category Filter Type
                  </span>
                }
                name="iabContentCategoryFilterType"
                error={
                  !!(touched.iabContentCategoryFilterType && errors.iabContentCategoryFilterType)
                }
                helperText={
                  touched.iabContentCategoryFilterType && errors.iabContentCategoryFilterType
                }
                className={classes.dropDownField}
                value={values.iabContentCategoryFilterType}
                onChange={(e) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  e.target.value === ENTITY_FILTER_TYPE.NONE &&
                    setFieldValue("iabContentCategories", [])

                  setFieldValue("iabContentCategoryFilterType", e.target.value)
                  setIabContentCategoryFilterType(e.target.value as ENTITY_FILTER_TYPE)
                }}
                inputProps={{
                  "data-testid": "deal-form-iab-content-categories-filter-type-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                disabled={isDisabled}
              >
                {entityFilterTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label.replace("list", "")}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* Media Type */}
            <Grid item md={3} xs={12}>
              <TextField
                select
                label={<span className={clsx(isInfo && classes.overrideDisable)}>Media</span>}
                name="mediaType"
                error={!!(touched.mediaType && errors.mediaType)}
                helperText={touched.mediaType && errors.mediaType}
                className={classes.dropDownField}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mediaType}
                inputProps={{
                  "data-testid": "deal-form-media-type-input",
                }}
                InputProps={{
                  classes: {
                    disabled: classes.overrideDisable,
                  },
                }}
                disabled={isDisabled}
              >
                {mediaTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )
      case 5:
        return (
          <>
            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={selectableCountries}
                  value={validateArray(values.countries)}
                  getOptionSelected={(option, value) => value.id === option.id}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  multiple
                  fullWidth
                  filterSelectedOptions
                  data-testid={"deal-form-countries-input"}
                  onBlur={() => setFieldTouched("countries")}
                  onChange={(e, newValue) => {
                    setFieldValue("countries", newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span className={clsx(isInfo && classes.overrideDisable)}>Countries</span>
                      }
                      name={"countries"}
                      error={!!(touched.countries && errors.countries)}
                      helperText={touched.countries && errors.countries}
                      fullWidth
                      onBlur={handleBlur}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        classes: {
                          disabled: classes.overrideDisable,
                        },
                      }}
                    />
                  )}
                  disabled={isDisabled || isLoadingCountries}
                />
              </Grid>
              {/*Supply Type*/}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={
                    <span className={clsx(isInfo && classes.overrideDisable)}>Supply Type</span>
                  }
                  name="supplyType"
                  error={!!(touched.supplyType && errors.supplyType)}
                  helperText={touched.supplyType && errors.supplyType}
                  className={classes.dropDownField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.supplyType}
                  inputProps={{
                    "data-testid": "deal-form-supply-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {supplyTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/*Device Type*/}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={<span className={clsx(isInfo && classes.overrideDisable)}>Devices</span>}
                  name="deviceType"
                  error={!!(touched.deviceType && errors.deviceType)}
                  helperText={touched.deviceType && errors.deviceType}
                  className={classes.dropDownField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deviceType}
                  inputProps={{
                    "data-testid": "deal-form-device-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {deviceTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={clsx(classes.topMostContainer)}>
              {/* Add Publishers */}
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={selectablePublishers}
                  value={values.publishers}
                  getOptionLabel={(option) => (option ? `${option.id} - ${option.name}` : "")}
                  getOptionSelected={(option, value) => value.id === option.id}
                  multiple
                  fullWidth
                  filterSelectedOptions
                  data-testid={"deal-form-publishers-input"}
                  onBlur={() => setFieldTouched("publishers")}
                  onChange={(event, newValue) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    newValue.length || dispatch(clearUploadedDealPublishers())
                    setPublishersSearchTerm("")
                    dispatch(clearPublishers())
                    setFieldValue("publishers", newValue)
                  }}
                  onInputChange={(event, inputValue) => {
                    setPublishersSearchTerm(inputValue)
                    dispatch(clearPublishers())
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    inputValue.length > 1 && debounceFetchDealPublishers(inputValue, 0, true)
                  }}
                  ListboxProps={{
                    onScroll: (event: React.SyntheticEvent) => {
                      const listboxNode = event.currentTarget
                      if (
                        listboxNode.scrollTop + listboxNode.clientHeight ===
                        listboxNode.scrollHeight
                      ) {
                        dispatch(
                          fetchDealPublishers({
                            term: publishersSearchTerm,
                            page: publishersPage + 1,
                            clear: false,
                          })
                        )
                      }
                    },
                  }}
                  loading={isLoadingPublishers}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={
                          <span
                            className={clsx(
                              isInfo && values.publishers.length > 0 && classes.overrideDisable
                            )}
                          >
                            Publishers
                          </span>
                        }
                        name="publishers"
                        error={!!(touched.publishers && errors.publishers)}
                        helperText={touched.publishers && errors.publishers}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            disabled:
                              isInfo && values.publishers.length > 0 && classes.overrideDisable,
                          },
                        }}
                      />
                    )
                  }}
                  disabled={isDisabled || values.publisherFilterType === ENTITY_FILTER_TYPE.NONE}
                />
                {values.publisherFilterType === ENTITY_FILTER_TYPE.NONE && !isInfo && (
                  <FormHelperText component="div">
                    <Typography
                      className={classes.helperText}
                      data-testid={"deal-form-publisher-filter-helper-text"}
                    >
                      ** Select a Publisher Filter Type to add Publishers **
                    </Typography>
                  </FormHelperText>
                )}
              </Grid>
              {/*Publisher Filter Type*/}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={
                    <span className={clsx(isInfo && classes.overrideDisable)}>
                      Publisher Filter Type
                    </span>
                  }
                  name="publisherFilterType"
                  error={!!(touched.publisherFilterType && errors.publisherFilterType)}
                  helperText={touched.publisherFilterType && errors.publisherFilterType}
                  className={classes.dropDownField}
                  onChange={(e) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    e.target.value === ENTITY_FILTER_TYPE.NONE && setFieldValue("publishers", [])

                    setFieldValue("publisherFilterType", e.target.value)
                  }}
                  value={values.publisherFilterType}
                  inputProps={{
                    "data-testid": "deal-form-publisher-filter-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {entityFilterTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label.replace("list", "")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Upload Publishers */}
              {!isInfo && (
                <Grid item md={2}>
                  <CsvUpload
                    values={values}
                    setFieldValue={setFieldValue}
                    fieldValue={"publishers"}
                    type={DEAL_CSV_TYPES.PUBLISHERS}
                    uploadedList={uploadedPublishers}
                    uploadSuccessAction={(formattedCSVData: string[]) => {
                      touched.publishers = true
                      dispatch(uploadDealPublishersSuccess(formattedCSVData))
                    }}
                    clearAction={clearUploadedDealPublishers}
                    disabled={isDisabled || values.publisherFilterType === ENTITY_FILTER_TYPE.NONE}
                    toolTipText={"Upload Publishers"}
                    className={classes.filterListButton}
                  />
                </Grid>
              )}
              {/* Export Publishers */}
              <Grid item md={1}>
                <div className={clsx(classes.filterListButtonContainer, classes.filterListButton)}>
                  <Tooltip title={"Export Publishers"}>
                    <Button
                      data-testid={`deal-form-publishers-export-button`}
                      variant="contained"
                      color="secondary"
                      size="small"
                      component="span"
                      disabled={
                        values.publisherFilterType === ENTITY_FILTER_TYPE.NONE ||
                        !values.publishers.length
                      }
                      onClick={() =>
                        exportFilterListAsCsv(values.publishers, {
                          dealName: deal.name,
                          filterName: DEAL_CSV_TYPES.PUBLISHERS,
                        })
                      }
                    >
                      <CloudDownloadOutlined />
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {/*Add Domains*/}
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={selectableDomains}
                  value={values.domains}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  multiple
                  fullWidth
                  freeSolo
                  data-testid={"deal-form-domains-input"}
                  onBlur={() => setFieldTouched("domains")}
                  onChange={(e, newValue) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    newValue.length || dispatch(clearUploadedDealDomains())
                    setDomainsSearchTerm("")
                    dispatch(clearDealDomains())
                    setFieldValue(
                      "domains",
                      newValue.map((domain) =>
                        typeof domain === "string" ? { id: null, name: domain } : domain
                      )
                    )
                  }}
                  onInputChange={(e, inputValue) => {
                    setDomainsSearchTerm(inputValue)
                    dispatch(clearDealDomains())
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    inputValue.length > 1 && debounceFetchDealDomains(inputValue, 0, true)
                  }}
                  ListboxProps={{
                    onScroll: (event: React.SyntheticEvent) => {
                      const listboxNode = event.currentTarget
                      if (
                        listboxNode.scrollTop + listboxNode.clientHeight ===
                        listboxNode.scrollHeight
                      ) {
                        dispatch(
                          fetchDealDomains({
                            term: domainsSearchTerm,
                            page: domainsPage + 1,
                            clear: false,
                          })
                        )
                      }
                    },
                  }}
                  loading={isLoadingDomains}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={
                          <span
                            className={clsx(
                              isInfo && values.domains.length > 0 && classes.overrideDisable
                            )}
                          >
                            Domains
                          </span>
                        }
                        name="domains"
                        error={!!(touched.domains && errors.domains)}
                        helperText={touched.domains && errors.domains}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            disabled:
                              isInfo && values.domains.length > 0 && classes.overrideDisable,
                          },
                        }}
                      />
                    )
                  }}
                  disabled={isDisabled || values.domainFilterType === ENTITY_FILTER_TYPE.NONE}
                />
                {values.domainFilterType === ENTITY_FILTER_TYPE.NONE && !isInfo && (
                  <FormHelperText component="div">
                    <Typography
                      className={classes.helperText}
                      data-testid={"deal-form-domain-filter-helper-text"}
                    >
                      ** Select a Domain Filter Type to add Domains **
                    </Typography>
                  </FormHelperText>
                )}
              </Grid>
              {/* Domain Filter Type */}
              <Grid item md={3} xs={12}>
                <TextField
                  select
                  label={
                    <span className={clsx(isInfo && classes.overrideDisable)}>
                      Domain Filter Type
                    </span>
                  }
                  name="domainFilterType"
                  error={!!(touched.domainFilterType && errors.domainFilterType)}
                  helperText={touched.domainFilterType && errors.domainFilterType}
                  className={clsx(classes.dropDownField)}
                  onChange={(e) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    e.target.value === ENTITY_FILTER_TYPE.NONE && setFieldValue("domains", [])
                    setFieldValue("domainFilterType", e.target.value)
                  }}
                  value={values.domainFilterType}
                  inputProps={{
                    "data-testid": "deal-form-domain-filter-type-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  disabled={isDisabled}
                >
                  {entityFilterTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label.replace("list", "")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Upload Domains */}
              {!isInfo && (
                <Grid item md={2}>
                  <CsvUpload
                    values={values}
                    setFieldValue={setFieldValue}
                    type={DEAL_CSV_TYPES.DOMAINS}
                    uploadedList={uploadedDomains}
                    uploadSuccessAction={(formattedCSVData: string[]) => {
                      touched.domains = true
                      dispatch(uploadDealDomainsSuccess(formattedCSVData))
                    }}
                    fieldValue={"domains"}
                    clearAction={clearUploadedDealDomains}
                    disabled={isDisabled || values.domainFilterType === ENTITY_FILTER_TYPE.NONE}
                    toolTipText={"Upload Domains"}
                    className={classes.filterListButton}
                  />
                </Grid>
              )}
              {/* Export Domains */}
              <Grid item md={1}>
                <div className={clsx(classes.filterListButtonContainer, classes.filterListButton)}>
                  <Tooltip title={"Export Domains"}>
                    <Button
                      data-testid={`deal-form-domains-export-button`}
                      variant="contained"
                      color="secondary"
                      size="small"
                      component="span"
                      disabled={
                        values.domainFilterType === ENTITY_FILTER_TYPE.NONE ||
                        !values.domains.length
                      }
                      onClick={() =>
                        exportFilterListAsCsv(values.domains, {
                          dealName: deal.name,
                          filterName: DEAL_CSV_TYPES.DOMAINS,
                        })
                      }
                    >
                      <CloudDownloadOutlined />
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Box
                  className={clsx(
                    classes.topMostContainer,
                    classes.automaticValueOptimizationButton
                  )}
                >
                  <Typography>
                    <b>Automatic Venue Optimization</b>
                  </Typography>
                </Box>
              </Grid>
              {/*Minimum Viewability Rate*/}
              <Grid item md={3} xs={12}>
                <TextField
                  label={
                    <span
                      className={clsx(isInfo && values.viewRate > 0 && classes.overrideDisable)}
                    >
                      Minimum Viewability Rate (%)
                    </span>
                  }
                  name="viewRate"
                  type="number"
                  error={!!(touched.viewRate && errors.viewRate)}
                  helperText={touched.viewRate && errors.viewRate}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  value={values.viewRate}
                  inputProps={{
                    "data-testid": "deal-form-min-view-rate-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  disabled={isDisabled}
                />
              </Grid>
              {/*"Minimum Video Completion Rate*/}
              <Grid item md={3} xs={12}>
                <TextField
                  label={
                    <span className={clsx(isInfo && values.vcrRate > 0 && classes.overrideDisable)}>
                      Minimum Video Completion Rate (%)
                    </span>
                  }
                  name="vcrRate"
                  type="number"
                  error={!!(touched.vcrRate && errors.vcrRate)}
                  helperText={touched.vcrRate && errors.vcrRate}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vcrRate}
                  variant="outlined"
                  inputProps={{
                    "data-testid": "deal-form-min-vcr-rate-input",
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.overrideDisable,
                    },
                  }}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </>
        )
      default:
        return "Unknown step"
    }
  }

  const handleFormikSubmit = (values) => {
    const valuesCopy = _.cloneDeep(values)

    valuesCopy.bidderId = valuesCopy.bidder.id
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    valuesCopy.wseatIds.length === 0
      ? delete valuesCopy.wseatIds
      : (valuesCopy.wseatIds = valuesCopy.wseatIds.map((wseatId) => parseInt(wseatId, 10)))

    valuesCopy.segmentGroups =
      valuesCopy.dataConnected === "legacy"
        ? flattenFormattedSegmentGroups(valuesCopy.segmentGroups)
        : []
    valuesCopy.iabContentCategories =
      valuesCopy.iabContentCategoryFilterType === ENTITY_FILTER_TYPE.NONE
        ? []
        : valuesCopy.iabContentCategories.map((iabContentCategory) => iabContentCategory.id)

    switch (valuesCopy.dataConnected) {
      case "false":
        valuesCopy.dmpSegmentFeeFixed = null
        valuesCopy.dmpSegmentFeePercentage = null
        delete valuesCopy.dmpSegmentFeeType
        valuesCopy.externalAudienceId = ""
        valuesCopy.predictiveAudienceId = ""
        valuesCopy.segmentGroups = []
        break
      case "legacy":
        valuesCopy.externalAudienceId = ""
        valuesCopy.externalAudience = null
        valuesCopy.predictiveAudienceId = ""
        valuesCopy.predictiveAudience = null
        break
      case "audience":
        valuesCopy.segmentGroups = []
        valuesCopy.predictiveAudienceId = ""
        valuesCopy.predictiveAudience = null
        break
      case "predictive":
        valuesCopy.segmentGroups = []
        valuesCopy.externalAudienceId = ""
        valuesCopy.externalAudience = null
        break
    }

    if (valuesCopy.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE) {
      valuesCopy.dmpSegmentFeePercentage = valuesCopy.dmpSegmentFee
    }
    if (valuesCopy.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.FIXED) {
      valuesCopy.dmpSegmentFeeFixed = valuesCopy.dmpSegmentFee
    }
    delete valuesCopy.dmpSegmentFee

    valuesCopy.countries = valuesCopy.countries.map((country) => country.id)

    // publishers can be a mix of strings and objects in the edit case
    valuesCopy.publishers = valuesCopy.publishers.map((publisher) =>
      publisher.id ? publisher.id : publisher
    )

    // clear publishers and domains from payload if filter type is set to none
    valuesCopy.publishers =
      valuesCopy.publisherFilterType === ENTITY_FILTER_TYPE.NONE ? [] : valuesCopy.publishers
    valuesCopy.domains =
      valuesCopy.domainFilterType === ENTITY_FILTER_TYPE.NONE ? [] : valuesCopy.domains

    // TODO: potentially need to clear extra fields being sent, but backend should ignore anyways
    if (isCreate || isCopy) {
      dispatch(
        createDeal({
          ...trimPayloadProperties(valuesCopy),
        } as CreateAndUpdateDealPayload)
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isCopy && setIsCopyDeal(false)
    } else if (isEdit) {
      dispatch(
        updateDeal({
          ...trimPayloadProperties({
            id: deal.id,
            ...valuesCopy,
          }),
        } as CreateAndUpdateDealPayload)
      )
    }
  }

  const getSubmitButtonText = (endPath: string): string => {
    switch (endPath) {
      case END_PATHS.CREATE:
        return "Create Deal"
      case END_PATHS.EDIT:
        return "Update Deal"
      case END_PATHS.COPY:
        return "Save Copied Deal"
      default:
        return "Unknown endPath"
    }
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    isCreate,
    isEdit,
    isInfo,
    isCopy,
    deal
  )

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={formValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleFormikSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            setFieldTouched,
            values,
          }) => {
            return (
              <>
                <ErrorMessages />
                <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                  {!isFeatureFlagLoading && (
                    <Card className={classes.roundedBorder}>
                      <CardContent className={classes.cardStepperPadding}>
                        <div>
                          <Stepper activeStep={-1} nonLinear orientation="vertical">
                            {steps.map((label, index) => (
                              <Step
                                expanded={true}
                                key={`${label.title}-${label.subTitle}-${index}`}
                              >
                                <StepLabel>
                                  <Grid container>
                                    <Grid item>
                                      <Typography
                                        display="inline"
                                        key={`${label.title}-${label.subTitle}`}
                                        variant="h4"
                                      >
                                        <b key={`${label.title}-${label.subTitle}-${index}-bold`}>
                                          {label.title}{" "}
                                        </b>{" "}
                                      </Typography>
                                      {label.subTitle && (
                                        <Typography
                                          key={`${label.subTitle}-${index}`}
                                          color="secondary"
                                          display="inline"
                                          variant="h5"
                                        >
                                          {label.subTitle}
                                        </Typography>
                                      )}
                                    </Grid>
                                    {isEdit && index === 0 && (
                                      <Grid item>
                                        <Typography
                                          className={classes.dealIdText}
                                          variant="subtitle1"
                                        >
                                          {`- Deal ID (DID): ${deal.externalId || ""}`}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                </StepLabel>
                                <StepContent>
                                  {getStepContent(
                                    index,
                                    values,
                                    touched,
                                    setFieldTouched,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    setFieldValue
                                  )}
                                </StepContent>
                              </Step>
                            ))}
                          </Stepper>
                        </div>
                        {!isInfo && (
                          <Box mt={2}>
                            {(!_.isEmpty(errors) || generalErrors.length > 0) && (
                              <Alert
                                variant="outlined"
                                severity="error"
                                key="improperly-formatted-form-field"
                                className={classes.formFieldErrors}
                              >
                                Form field(s) are improperly formatted
                              </Alert>
                            )}
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={isDisabled}
                              data-testid={"deal-form-submit-button"}
                            >
                              {isSubmitting && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                              )}
                              {getSubmitButtonText(endPath)}
                            </Button>
                            <Can
                              perform={PERMISSIONS.TOOLS.DEAL.CREATE}
                              yes={
                                <>
                                  {canCopy && (
                                    <Button
                                      className={classes.dealFormButtonSpacing}
                                      variant="contained"
                                      color="secondary"
                                      type="button"
                                      disabled={isDisabled}
                                      onClick={() => {
                                        handleCopyDealClick()
                                      }}
                                      data-testid={"deal-form-copy-button"}
                                    >
                                      Copy Deal
                                    </Button>
                                  )}
                                </>
                              }
                            />
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default withStyles(styles)(DealForm)
