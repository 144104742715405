import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchSites,
  fetchSitesFailed,
  fetchSitesSuccess,
  setSitesLoadingFlag,
} from "../actions/siteActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchSitesPayload } from "../types/siteTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"

export function* fetchSitesSaga(action: PayloadAction<FetchSitesPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.publisherId}/sites/find`,
    data: action.payload,
  }
  yield put(setSitesLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page = parseInt(response.headers["x-pagination-page"], 10)
    const totalCount = parseInt(response.headers["x-pagination-total-count"], 10)
    yield put(
      fetchSitesSuccess({
        sites: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchSitesFailed, err)
  }
  yield put(setSitesLoadingFlag(false))
}

export function* watchFetchSites(): SagaIterator {
  yield takeEvery(fetchSites, fetchSitesSaga)
}
