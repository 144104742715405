import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { updateDeal, updateDealFailed, updateDealSuccess } from "../actions/dealActions"

import { AxiosRequestConfig } from "axios"
import { CreateAndUpdateDealPayload } from "../types/dealTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

export function* updateDealSaga(action: PayloadAction<CreateAndUpdateDealPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/${action.payload.id}`,
    data: _.omit(action.payload, ["id"]),
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateDealSuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateDealFailed, err)
  }
}

export function* watchUpdateDeal(): SagaIterator {
  yield takeEvery(updateDeal, updateDealSaga)
}
