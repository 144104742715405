import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  setTagFormSubmittingFlag,
  updateTag,
  updateTagFailed,
  updateTagSuccess,
} from "../actions/tagActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { SaveTagPayload } from "../types/tagTypes"
import { clearErrors } from "../actions/errorActions"

export function* updateTagSaga(action: PayloadAction<SaveTagPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.publisherId}/sites/${action.payload.siteId}/tags/${action.payload.id}`,
    data: {
      tag: action.payload,
    },
  }
  yield put(clearErrors())
  yield put(setTagFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateTagSuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateTagFailed, err)
  }
  yield put(setTagFormSubmittingFlag(false))
}

export function* watchUpdateTag(): SagaIterator {
  yield takeEvery(updateTag, updateTagSaga)
}
