import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { exportTagsAsCsvFromTags } from "../../helpers/csvHelpers"
import { clearErrors } from "../actions/errorActions"
import {
  fetchAndExportTagsFromTags,
  fetchAndExportTagsFromTagsFailed,
  fetchAndExportTagsFromTagsSuccess,
  setLoadingTagsForExportTagsFlag,
} from "../actions/tagActions"
import { FetchAndExportTagsFromTagsPayload } from "../types/tagTypes"
import _ from "lodash"

export function* fetchAndExportTagsFromTagsSaga(
  action: PayloadAction<FetchAndExportTagsFromTagsPayload>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.publisherId}/sites/${action.payload.siteId}/tags/export`,
    data: _.pick(action.payload, "tagFilters"),
  }

  yield put(clearErrors())
  yield put(setLoadingTagsForExportTagsFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    exportTagsAsCsvFromTags(response.data, action.payload)
    yield put(fetchAndExportTagsFromTagsSuccess("Successfully exported tags"))
  } catch {
    yield handleSagaError(fetchAndExportTagsFromTagsFailed, "Failed to export tags")
  }
  yield put(setLoadingTagsForExportTagsFlag(false))
}

export function* watchFetchAndExportTagsFromTags(): SagaIterator {
  yield takeEvery(fetchAndExportTagsFromTags, fetchAndExportTagsFromTagsSaga)
}
