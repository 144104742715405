import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { createRole, createRoleFailed, createRoleSuccess } from "../actions/roleActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { CreateRolePayload } from "../types/roleTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

export function* createRoleSaga(action: PayloadAction<CreateRolePayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/roles`,
    data: {
      role: action.payload,
    },
  }
  yield put(clearErrors())
  // TODO: create separate loading/submitting flag
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(createRoleSuccess(response.data))
  } catch (err) {
    yield handleSagaError(createRoleFailed, err)
  }
}

export function* watchCreateRole(): SagaIterator {
  yield takeEvery(createRole, createRoleSaga)
}
