import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createCompanyExternalLineItemDisplayName,
  createCompanyExternalLineItemDisplayNameFailed,
  createCompanyExternalLineItemDisplayNameSuccess,
  setCompanyFormSubmittingFlag,
  setExternalLineItemDisplayNameSavedFlag,
} from "../actions/companyActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { ExternalLineItemDisplayName } from "../types/companyTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"

// TODO: For Code Review - Add Tests for this saga

export function* createCompanyExternalLineItemDisplayNameSaga(
  action: PayloadAction<ExternalLineItemDisplayName>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/companies/${action.payload.companyId}/external-line-item-display-names`,
    data: {
      externalLineItemDisplayNameInputs: action.payload,
    },
  }
  yield put(setCompanyFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(createCompanyExternalLineItemDisplayNameSuccess(response.data))
    yield put(setExternalLineItemDisplayNameSavedFlag(false))
  } catch (err) {
    yield handleSagaError(createCompanyExternalLineItemDisplayNameFailed, err)
  }
  yield put(setCompanyFormSubmittingFlag(false))
}

export function* watchCreateCompanyExternalLineItemDisplayName(): SagaIterator {
  yield takeEvery(
    createCompanyExternalLineItemDisplayName,
    createCompanyExternalLineItemDisplayNameSaga
  )
}
