import { createStyles, Theme } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    activeCompanyDropdownInput: {
      width: 250,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "10px",
          borderColor: theme.palette.white,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.white,
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.white,
        },
      },
      "& .MuiInputLabel-formControl": {
        color: theme.palette.white,
      },
      "& .MuiOutlinedInput-input": {
        color: theme.palette.white,
      },
      "& .MuiSelect-icon": {
        color: theme.palette.white,
        "&.Mui-disabled": {
          color: theme.palette.black,
        },
      },
    },
  })
}
