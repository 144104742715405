import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  PlusCircle as PlusCircleIcon,
  Search as SearchIcon,
} from "react-feather"
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { ENTITY_STATUSES, PAGE_LIMIT, PERMISSIONS } from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearAudiences,
  closeSaveAudienceSuccess,
  fetchAudiences,
  setAudiencesLoading,
} from "../../../../../redux/actions/audienceActions"
import { clearErrors, closeError } from "../../../../../redux/actions/errorActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../common/Can"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import Header from "../../../../NewHeader"
import { Link as RouterLink } from "react-router-dom"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import clsx from "clsx"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import { fetchSegmentVendors } from "../../../../../redux/actions/segmentVendorActions"
import { generateSegmentVendorDropdownOptions } from "../../../../../helpers/formatterHelper"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Audiences"
  const headerButtons = [
    {
      name: "CREATE NEW AUDIENCE",
      route: `/tools/audiences/create`,
      permission: PERMISSIONS.TOOLS.AUDIENCE.CREATE,
      disabled: false,
      icon: <PlusCircleIcon />,
    },
  ]

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Audiences",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const AudiencesList = ({
  classes,
  selectedAudience = null,
  handleSelectAudience = null,
  testId = null,
  showHeader = true,
  showErrors = true,
  enableNavigation = true,
  multiSelect = true,
  selectLimit = null,
  rowsPerPageOptions = PAGE_LIMIT.OPTIONS,
}) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.audiences.isLoading)
  const audiences = useSelector((state: RootState) => state.audiences.audiences)
  const errors = useSelector((state: RootState) => state.errors.generalErrors)
  const audienceSuccessMessage = useSelector(
    (state: RootState) => state.audiences.audienceSuccessMessage
  )

  const segmentVendors = useSelector((state: RootState) => state.segmentVendors.segmentVendors)
  const isSegmentVendorsLoading = useSelector((state: RootState) => state.segmentVendors.isLoading)
  const segmentVendorDropdownOptions = generateSegmentVendorDropdownOptions(
    segmentVendors,
    isSegmentVendorsLoading,
    false
  )
  const disableSegmentVendor = segmentVendorDropdownOptions.length < 2
  const pager = useSelector((state: RootState) => state.audiences.pager)

  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "name|DESC",
      label: "Name (descending)",
    },
    {
      value: "name|ASC",
      label: "Name (ascending)",
    },
    {
      value: "createdAt|DESC",
      label: "Date Created (descending)",
    },
    {
      value: "createdAt|ASC",
      label: "Date Created (ascending)",
    },
    {
      value: "updatedAt|DESC",
      label: "Date Updated (descending)",
    },
    {
      value: "updatedAt|ASC",
      label: "Date Updated (ascending)",
    },
  ]

  const [selectedAudiences, setSelectedAudiences] = useState<number[]>([])
  const [query, setQuery] = useState("")

  const [audienceStatusFilter, setAudienceStatusFilter] = useState(ENTITY_STATUSES.ACTIVE)
  const [selectedVendorId, setSelectedVendorId] = useState(0)
  const isVendorsLoading = useSelector((state: RootState) => state.segmentVendors.isLoading)
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })
  const [pageLimit, setPageLimit] = useState(selectLimit ? selectLimit : PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)

  const audiencesListStatusOptions = [
    { value: ENTITY_STATUSES.ALL, label: "All (excluding Archived)" },
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
    { value: ENTITY_STATUSES.ARCHIVED, label: "Archived" },
  ]

  const dispatchFetchAudiences = (value = query) => {
    const fetchAudiencesArgsObj = {
      pagination: {
        limit: pageLimit,
        page: selectedPage,
      },
      sort: {
        value: sortByFilter.value,
        order: sortByFilter.order,
      },
      filters: {
        term: value,
        status: audienceStatusFilter,
        vendorId: selectedVendorId,
      },
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    audienceStatusFilter === ENTITY_STATUSES.ALL && delete fetchAudiencesArgsObj.filters.status
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selectedVendorId === 0 && delete fetchAudiencesArgsObj.filters.vendorId

    dispatch(fetchAudiences(fetchAudiencesArgsObj))
  }

  const dispatchFetchAudiencesOrStopLoading = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    segmentVendors.length ? dispatchFetchAudiences() : dispatch(setAudiencesLoading(false))
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    segmentVendors.length === 0 && dispatch(fetchSegmentVendors())
    return () => {
      dispatch(clearErrors())
      dispatch(clearAudiences())
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selectedAudience?.id && setSelectedAudiences([selectedAudience.id])
  }, [selectedAudience])

  useEffect(() => {
    if (!isVendorsLoading && segmentVendorDropdownOptions[0].name !== "") {
      dispatchFetchAudiencesOrStopLoading()
    }
  }, [
    selectedVendorId,
    pageLimit,
    sortByFilter,
    audienceStatusFilter,
    selectedPage,
    isVendorsLoading,
  ])

  //Set default vendor in vendor dropdown
  useEffect(() => {
    setSelectedVendorId(0)
  }, [segmentVendors])

  const debounceSearch = useCallback(debounceFn(dispatchFetchAudiences), [
    pageLimit,
    sortByFilter,
    audienceStatusFilter,
  ])
  const handleFilterStatusChange = (e) => {
    setAudienceStatusFilter(e.target.value)
    setSelectedPage(0)
  }
  const handleFilterVendorIdChange = (e) => {
    setSelectedVendorId(parseInt(e.target.value))
    setSelectedPage(0)
  }
  const handleQueryChange = (event) => {
    setQuery(event.target.value)
    debounceSearch(event.target.value)
    setSelectedPage(0)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }
  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  // TODO: ugly logic for now, clean up later
  const handleSelectOneAudience = (event, audienceId) => {
    if (multiSelect) {
      if (!selectedAudiences.includes(audienceId)) {
        setSelectedAudiences((prevSelected) => [...prevSelected, audienceId])
      } else {
        setSelectedAudiences((prevSelected) => prevSelected.filter((id) => id !== audienceId))
      }
    } else {
      if (!selectedAudiences.includes(audienceId)) {
        setSelectedAudiences([audienceId])
      } else {
        setSelectedAudiences([])
      }
    }
  }

  const renderSegmentVendorDropdownOptions = () =>
    segmentVendorDropdownOptions.length &&
    segmentVendorDropdownOptions.map((option) => (
      <option key={option.id} value={option.id}>
        {option.name}
      </option>
    ))

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()

  return (
    <>
      {showHeader && (
        <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      )}
      <Box mt={3} mb={3}>
        {errors.length > 0 &&
          showErrors &&
          errors.map((error, i) => (
            <Alert severity="error" key={`${error}-${i}`} onClose={() => dispatch(closeError(i))}>
              {error}
            </Alert>
          ))}
        {audienceSuccessMessage.length > 0 && (
          <Alert
            severity="success"
            key={audienceSuccessMessage}
            onClose={() => dispatch(closeSaveAudienceSuccess())}
          >
            {audienceSuccessMessage}
          </Alert>
        )}
        <Card data-testid="audience-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search audiences"
              value={query}
              variant="outlined"
              data-testid={"audiences-list-search-input"}
            />
            <TextField
              label="Filter By Segment Vendor"
              name="vendorFilter"
              className={clsx(classes.audienceSegmentVendorField, {
                [classes.hideArrow]: disableSegmentVendor,
              })}
              onChange={handleFilterVendorIdChange}
              select
              SelectProps={{ native: true }}
              value={selectedVendorId}
              variant="outlined"
              inputProps={{
                "data-testid": "audiences-list-vendor-filter-input",
              }}
              disabled={disableSegmentVendor}
            >
              {renderSegmentVendorDropdownOptions()}
            </TextField>
            <TextField
              label="Filter By Status"
              name="statusFilter"
              className={classes.audienceStatusField}
              onChange={handleFilterStatusChange}
              select
              SelectProps={{ native: true }}
              value={audienceStatusFilter}
              variant="outlined"
              inputProps={{
                "data-testid": "audiences-list-status-filter-input",
              }}
            >
              {audiencesListStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <TextField
              label="Sort By"
              name="sort"
              className={classes.audienceSortByField}
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"audiences-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={rowsPerPageOptions}
              data-testid={"audiences-list-pagination"}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <Box className={classes.scrollTable}>
            <Table size="small" data-testid={testId}>
              <TableHead>
                <TableRow>
                  <TableCell width="5%" padding="checkbox" />
                  <TableCell data-testid={"audiences-list-id-table-column-header"}>ID</TableCell>
                  <TableCell data-testid={"audiences-list-status-table-column-header"}>
                    Status
                  </TableCell>
                  <TableCell data-testid={"audiences-list-name-table-column-header"}>
                    Name
                  </TableCell>
                  <TableCell data-testid={"audiences-list-vendor-table-column-header"}>
                    Vendor
                  </TableCell>
                  <TableCell data-testid={"audiences-list-target-type-table-column-header"}>
                    Type
                  </TableCell>
                  <TableCell data-testid={"audiences-list-segment-groups-table-column-header"}>
                    Segment Groups
                  </TableCell>
                  <TableCell data-testid={"audiences-list-total-segments-table-column-header"}>
                    Total Segments
                  </TableCell>
                  <TableCell data-testid={"audiences-list-uniques-table-column-header"}>
                    Uniques
                  </TableCell>
                  <TableCell data-testid={"audiences-list-impressions-table-column-header"}>
                    Impressions
                  </TableCell>
                  {/* As per EMXD-5924 only hiding Active Deals column. Considering future scope not removing it */}
                  {/* <TableCell data-testid={"audiences-list-active-deals-table-column-header"}>
                    Active Deals
                  </TableCell> */}

                  {enableNavigation && (
                    <Can
                      perform={[
                        PERMISSIONS.TOOLS.AUDIENCE.VIEW,
                        PERMISSIONS.TOOLS.AUDIENCE.UPDATE,
                        PERMISSIONS.TOOLS.AUDIENCE.CREATE,
                      ]}
                      yes={
                        <TableCell
                          align="right"
                          data-testid={"audiences-list-actions-table-column-header"}
                        >
                          Actions
                        </TableCell>
                      }
                    />
                  )}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody data-testid={"audiences-list-content"}>
                  {audiences.map((audience) => {
                    const isAudienceSelected = selectedAudiences.includes(audience.id)
                    const audienceImpressions = audience.pricingAndReachEstimates?.reduce(
                      (acc, val) => acc + val.reach,
                      0
                    )
                    const canCopy = audience.status !== "archived"
                    return (
                      <TableRow hover key={audience.id} selected={isAudienceSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isAudienceSelected}
                            onChange={(event) => {
                              if (handleSelectAudience) handleSelectAudience(event, audience)
                              handleSelectOneAudience(event, audience.id)
                            }}
                            value={isAudienceSelected}
                          />
                        </TableCell>
                        <TableCell data-testid={`audiences-list-id-${audience.id}`}>
                          {audience.id}
                        </TableCell>

                        <TableCell data-testid={`audiences-list-status-${audience.id}`}>
                          <small
                            className={clsx({
                              [classes.label]: true,
                              [classes.audienceStatusActive]:
                                audience.status === ENTITY_STATUSES.ACTIVE,
                              [classes.audienceStatusInactive]:
                                audience.status === ENTITY_STATUSES.INACTIVE,
                              [classes.audienceStatusArchived]:
                                audience.status === ENTITY_STATUSES.ARCHIVED,
                            })}
                          >
                            {audience.status}
                          </small>
                        </TableCell>

                        <Can
                          perform={PERMISSIONS.TOOLS.AUDIENCE.VIEW}
                          yes={
                            <TableCell>
                              {enableNavigation ? (
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/tools/audiences/${audience.id}`}
                                  variant="h6"
                                  data-testid={`audiences-list-name-${audience.id}`}
                                >
                                  {audience.name}
                                </Link>
                              ) : (
                                audience.name
                              )}
                            </TableCell>
                          }
                          no={
                            <TableCell data-testid={`audiences-list-name-${audience.id}`}>
                              {audience.name}
                            </TableCell>
                          }
                        />
                        <TableCell data-testid={`audiences-list-vendor-name-${audience.id}`}>
                          {audience.vendor ? audience.vendor.name : "-"}
                        </TableCell>
                        <TableCell data-testid={`audiences-list-target-type-${audience.id}`}>
                          {audience.targetType}
                        </TableCell>
                        <TableCell data-testid={`audiences-list-segment-groups-${audience.id}`}>
                          {audience.segmentGroups ? audience.segmentGroups.length : 0}
                        </TableCell>
                        <TableCell data-testid={`audiences-list-total-segments-${audience.id}`}>
                          {audience.segmentGroups.reduce((acc, cur) => {
                            return acc + (cur.length || 0)
                          }, 0)}
                        </TableCell>
                        <TableCell data-testid={`audiences-list-uniques-${audience.id}`}>
                          {audience.population?.toLocaleString()}
                        </TableCell>
                        <TableCell data-testid={`audiences-list-impressions-${audience.id}`}>
                          {audienceImpressions?.toLocaleString()}
                        </TableCell>
                        {/* As per EMXD-5924 only hiding Active Deals column. Considering future scope not removing it */}
                        {/* <TableCell data-testid={`audiences-list-active-deals-${audience.id}`}>
                          {audience.activeDeals ? audience.activeDeals : 0}
                        </TableCell> */}

                        {enableNavigation && (
                          <Can
                            perform={[
                              PERMISSIONS.TOOLS.AUDIENCE.VIEW,
                              PERMISSIONS.TOOLS.AUDIENCE.UPDATE,
                              PERMISSIONS.TOOLS.AUDIENCE.CREATE,
                            ]}
                            yes={
                              <TableCell align="right">
                                {canCopy && (
                                  <Can
                                    perform={[PERMISSIONS.TOOLS.AUDIENCE.CREATE]}
                                    yes={
                                      <IconButton
                                        component={RouterLink}
                                        to={`/tools/audiences/${audience.id}/copy`}
                                        data-testid={`audiences-list-copy-button-${audience.id}`}
                                      >
                                        <SvgIcon fontSize="small">
                                          <FileCopyOutlinedIcon />
                                        </SvgIcon>
                                      </IconButton>
                                    }
                                  />
                                )}
                                <Can
                                  perform={PERMISSIONS.TOOLS.AUDIENCE.UPDATE}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/tools/audiences/${audience.id}/edit`}
                                      data-testid={`audiences-list-edit-button-${audience.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <EditIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                                <Can
                                  perform={PERMISSIONS.TOOLS.AUDIENCE.VIEW}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/tools/audiences/${audience.id}`}
                                      data-testid={`audiences-list-view-button-${audience.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ArrowRightIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                              </TableCell>
                            }
                          />
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={rowsPerPageOptions}
            data-testid={"audiences-list-pagination"}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
    </>
  )
}

export default withStyles(styles)(AudiencesList)
