import { call, put, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import {
  fetchDealRequests,
  fetchDealRequestsSuccess,
  fetchDealRequestsFailed,
  setDealRequestIsLoading,
} from "../actions/dealRequestsActions"
import {
  handleAxiosRequest,
  handleSagaError,
  handleSagaValidationErrors,
} from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import _ from "lodash"

export function* fetchDealRequestsSaga(action) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deal-requests/find`,
    data: action.payload,
  }
  try {
    yield put(setDealRequestIsLoading(true))
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page =
      response.headers["x-pagination-page"] !== undefined
        ? parseInt(response.headers["x-pagination-page"], 10)
        : 0
    const totalCount =
      response.headers["x-pagination-total-count"] !== undefined
        ? parseInt(response.headers["x-pagination-total-count"], 10)
        : 0
    yield put(
      fetchDealRequestsSuccess({
        dealRequests: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchDealRequestsFailed, err)
    if (_.get(err, "response.data.validations")) {
      yield handleSagaValidationErrors(fetchDealRequestsFailed, err.response.data)
    }
  } finally {
    yield put(setDealRequestIsLoading(false))
  }
}

export function* watchFetchDealRequests(): SagaIterator {
  yield takeEvery(fetchDealRequests, fetchDealRequestsSaga)
}
