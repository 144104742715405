import "regenerator-runtime"

import { all, fork } from "redux-saga/effects"

import { SagaIterator } from "@redux-saga/core"
import { watchCreateAudience } from "./createAudience"
import { watchCreateCompany } from "./createCompany"
import { watchCreateCompanyExternalLineItemDisplayName } from "./createCompanyExternalLineItemDisplayName"
import { watchCreateDeal } from "./createDeal"
import { watchCreateDomain } from "./createDomain"
import { watchCreatePublisher } from "./createPublisher"
import { watchCreateRole } from "./createRole"
import { watchCreateSite } from "./createSite"
import { watchCreateSubdomain } from "./createSubdomain"
import { watchCreateTag } from "./createTag"
import { watchCreateUser } from "./createUser"
import { watchFetchAndExportDeals } from "./fetchAndExportDeals"
import { watchFetchAndExportPublishers } from "./fetchAndExportPublishers"
import { watchFetchAndExportTagsFromSites } from "./fetchAndExportTagsFromSites"
import { watchFetchAndExportTagsFromTags } from "./fetchAndExportTagsFromTags"
import { watchFetchApplicationVersion } from "./fetchApplicationVersion"
import { watchFetchAudienceById } from "./fetchAudienceById"
import { watchFetchAudiencePopulation } from "./fetchAudiencePopulation"
import { watchFetchAudiences } from "./fetchAudiences"
import { watchFetchCampaignBIToken } from "./fetchCampaignBIToken"
import { watchFetchChangeLog } from "./fetchChangeLog"
import { watchFetchCompanies } from "./fetchCompanies"
import { watchFetchCompanyById } from "./fetchCompanyById"
import { watchFetchCompanyExternalLineItemDisplayNamesByCompanyId } from "./fetchCompanyExternalLineItemDisplayNamesByCompanyId"
import { watchFetchCurrentUser } from "./fetchCurrentUser"
import { watchFetchDealBidders } from "./fetchDealBidders"
import { watchFetchDealById } from "./fetchDealById"
import { watchFetchDealCountries } from "./fetchDealCountries"
import { watchFetchDealDomains } from "./fetchDealDomains"
import { watchFetchDealExternalAudiences } from "./fetchDealExternalAudiences"
import { watchFetchDealIabContentCategories } from "./fetchDealIabContentCategories"
import { watchFetchDealPredictiveAudiences } from "./fetchDealPredictiveAudiences"
import { watchFetchDealPublishers } from "./fetchDealPublishers"
import { watchFetchDealRequests } from "./fetchDealRequests"
import { watchFetchDeals } from "./fetchDeals"
import { watchFetchDomainById } from "./fetchDomainById"
import { watchFetchDomains } from "./fetchDomains"
import { watchFetchExternalAudiences } from "./fetchExternalAudiences"
import { watchFetchFeatureFlagByTerm } from "./fetchFeatureFlagByTerm"
import { watchFetchFeatureFlags } from "./fetchFeatureFlags"
import { watchFetchHomePage } from "./fetchHomePage"
import { watchFetchPermissionList } from "./fetchPermissions"
import { watchFetchPredictiveAudiences } from "./fetchPredictiveAudiences"
import { watchFetchPricingEstimate } from "./fetchPricingEstimate"
import { watchFetchPublisherByAppNexusId } from "./fetchPublisherByAppNexusId"
import { watchFetchPublisherById } from "./fetchPublisherById"
import { watchFetchPublishers } from "./fetchPublishers"
import { watchFetchRoleById } from "./fetchRoleById"
import { watchFetchRoles } from "./fetchRoles"
import { watchFetchSegmentVendors } from "./fetchSegmentVendors"
import { watchFetchSegments } from "./fetchSegments"
import { watchFetchSellersJson } from "./fetchSellersJson"
import { watchFetchSiteBidders } from "./fetchSiteBidders"
import { watchFetchSiteBlockAttributes } from "./fetchSiteBlockAttributes"
import { watchFetchSiteBlockCategories } from "./fetchSiteBlockCategories"
import { watchFetchSiteBlockTypes } from "./fetchSiteBlockTypes"
import { watchFetchSiteById } from "./fetchSiteById"
import { watchFetchSites } from "./fetchSites"
import { watchFetchSubdomainById } from "./fetchSubdomainById"
import { watchFetchSubdomains } from "./fetchSubdomains"
import { watchFetchTagById } from "./fetchTagById"
import { watchFetchTags } from "./fetchTags"
import { watchFetchUserById } from "./fetchUserById"
import { watchFetchUsers } from "./fetchUsers"
import { watchLoginToCampaignBI } from "./loginToCampaignBI"
import { watchMigratePublisherByAppNexusId } from "./migratePublisherByAppNexusId"
import { watchSegmentUpload } from "./uploadSegment"
import { watchUpdateAudience } from "./updateAudience"
import { watchUpdateCompany } from "./updateCompany"
import { watchUpdateCompanyEnableCampaignBI } from "./updateCompanyEnableCampaignBI"
import { watchUpdateDeal } from "./updateDeal"
import { watchUpdateDealRequests } from "./updateDealRequests"
import { watchUpdateDomain } from "./updateDomain"
import { watchUpdateFeatureFlags } from "./updateFeatureFlags"
import { watchUpdateHomePage } from "./updateHomePage"
import { watchUpdatePublisher } from "./updatePublisher"
import { watchUpdateRole } from "./updateRole"
import { watchUpdateSite } from "./updateSite"
import { watchUpdateSubdomain } from "./updateSubdomain"
import { watchUpdateTag } from "./updateTag"
import { watchUpdateUser } from "./updateUser"
import { watchUpdateUserActiveCompany } from "./updateUserActiveCompany"
import { watchUpdateUserLastLogin } from "./updateUserLastLogin"

export default function* rootSaga(): SagaIterator {
  yield all([
    // current user sagas
    fork(watchFetchCurrentUser),

    // user sagas
    fork(watchFetchUsers),
    fork(watchFetchUserById),
    fork(watchCreateUser),
    fork(watchUpdateUser),
    fork(watchUpdateUserActiveCompany),
    fork(watchUpdateUserLastLogin),

    // role sagas
    fork(watchFetchRoles),
    fork(watchFetchRoleById),
    fork(watchCreateRole),
    fork(watchUpdateRole),

    // permission sagas
    fork(watchFetchPermissionList),

    // company sagas
    fork(watchFetchCompanies),
    fork(watchFetchCompanyById),
    fork(watchCreateCompany),
    fork(watchUpdateCompany),

    // company enable campaign bi
    fork(watchUpdateCompanyEnableCampaignBI),

    // company external line item display names
    fork(watchFetchCompanyExternalLineItemDisplayNamesByCompanyId),
    fork(watchCreateCompanyExternalLineItemDisplayName),

    // deal sagas
    // deal fetching sagas
    fork(watchFetchDeals),
    fork(watchFetchAndExportDeals),
    fork(watchFetchDealById),

    // create/update deal sagas
    fork(watchCreateDeal),
    fork(watchUpdateDeal),

    // deal form sagas
    fork(watchFetchDealBidders),
    fork(watchFetchDealCountries),
    fork(watchFetchDealIabContentCategories),
    fork(watchFetchDealDomains),
    fork(watchFetchDealPublishers),

    // publisher sagas
    fork(watchFetchPublishers),
    fork(watchFetchPublisherById),
    fork(watchCreatePublisher),
    fork(watchUpdatePublisher),
    fork(watchFetchAndExportPublishers),

    // domain sagas
    fork(watchFetchDomains),
    fork(watchFetchDomainById),
    fork(watchCreateDomain),
    fork(watchUpdateDomain),

    // subdomain sagas
    fork(watchFetchSubdomains),
    fork(watchFetchSubdomainById),
    fork(watchCreateSubdomain),
    fork(watchUpdateSubdomain),

    // sellers.json sagas
    fork(watchFetchSellersJson),

    // AppNexus migration sagas
    fork(watchFetchPublisherByAppNexusId),
    fork(watchMigratePublisherByAppNexusId),

    // site sagas
    fork(watchFetchSites),
    fork(watchFetchSiteById),
    fork(watchFetchSiteBidders),
    fork(watchFetchSiteBlockCategories),
    fork(watchFetchSiteBlockAttributes),
    fork(watchFetchSiteBlockTypes),
    fork(watchCreateSite),
    fork(watchUpdateSite),

    // tag sagas
    fork(watchFetchTags),
    fork(watchFetchTagById),
    fork(watchCreateTag),
    fork(watchUpdateTag),
    fork(watchFetchAndExportTagsFromSites),
    fork(watchFetchAndExportTagsFromTags),

    // change log sagas
    fork(watchFetchChangeLog),

    // data views sagas
    fork(watchFetchCampaignBIToken),
    fork(watchLoginToCampaignBI),

    // audience sagas
    fork(watchFetchAudiences),
    fork(watchFetchAudienceById),
    fork(watchCreateAudience),
    fork(watchUpdateAudience),

    // PricingEstimate sagas
    fork(watchFetchPricingEstimate),

    //Audience Population sagas
    fork(watchFetchAudiencePopulation),

    //  segment upload saga
    fork(watchSegmentUpload),

    // segment vendor saga
    fork(watchFetchSegmentVendors),

    fork(watchFetchSegments),

    // application version saga
    fork(watchFetchApplicationVersion),
    fork(watchFetchHomePage),
    fork(watchUpdateHomePage),

    // configuration saga
    fork(watchFetchFeatureFlags),
    fork(watchFetchFeatureFlagByTerm),
    fork(watchUpdateFeatureFlags),

    // Deal Request saga
    fork(watchFetchDealRequests),
    fork(watchUpdateDealRequests),

    // External Audiences sagas
    fork(watchFetchDealExternalAudiences),
    fork(watchFetchExternalAudiences),

    // Predictive Audiences sagas
    fork(watchFetchDealPredictiveAudiences),
    fork(watchFetchPredictiveAudiences),
  ])
}
