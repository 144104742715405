import {
  Button,
  Card,
  CardContent,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core"
import { REQUIRED_SEGMENT_CSV_HEADERS, SEGMENT_VENDOR_NAME } from "../../../../../constants"
import React, { useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  displaySegmentCSVValidationErrors,
  initSegmentCSVUpload,
  uploadSegment,
} from "../../../../../redux/actions/segmentActions"
import {
  validateColumnsForDuplicateValues,
  validateColumnsForEmptyValues,
  validateHeadersForRequiredValues,
} from "../../../../../helpers/csvHelpers"

import { DropzoneDialog } from "material-ui-dropzone"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import { fetchSegmentVendors } from "../../../../../redux/actions/segmentVendorActions"
import { generateSegmentVendorDropdownOptions } from "../../../../../helpers/formatterHelper"
import styles from "./styles"
import { usePapaParse } from "react-papaparse"
import { useSelector } from "react-redux"

interface SegmentUploadProps {
  isLoading?: boolean
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  classes: any
}

const SegmentUpload = ({ classes, isLoading = false }: SegmentUploadProps) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const isSegmentVendorsLoading = useSelector((state: RootState) => state.segmentVendors.isLoading)
  const segmentVendors = useSelector((state: RootState) => state.segmentVendors.segmentVendors)
  const segmentVendorDropdownOptions = generateSegmentVendorDropdownOptions(
    segmentVendors,
    isSegmentVendorsLoading,
    true
  )
  const [selectedSegmentVendor, setSelectedSegmentVendor] = useState({
    name: "No Segment Vendor Available",
    id: 0,
  })
  const [parsedCSVData, setParsedCSVData] = useState([])
  const [selectedFile, setSelectedFile] = useState()
  const { EXTERNAL_ID, HIERARCHY } = REQUIRED_SEGMENT_CSV_HEADERS
  const columnsToValidate = [EXTERNAL_ID, HIERARCHY]
  const { readString } = usePapaParse()

  useEffect(() => {
    //will fetch vendors data for segment uploading
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    segmentVendors.length === 0 && dispatch(fetchSegmentVendors())
  }, [])

  //Set default vendor in vendor dropdown
  useEffect(() => {
    defaultSegmentVendor(0)
  }, [segmentVendors])

  //Handle parsedCSVData data error once data read from csv file
  useEffect(() => {
    const errors = handleParseCSVDataError(parsedCSVData)
    if (selectedFile) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      errors?.length
        ? dispatch(displaySegmentCSVValidationErrors(errors))
        : dispatch(
            uploadSegment({
              vendorId: selectedSegmentVendor.id,
              file: selectedFile,
            })
          )
    }
  }, [parsedCSVData])

  const defaultSegmentVendor = (segmentVendorId) => {
    const segmentVendor = segmentVendorDropdownOptions.find((segmentVendor) => {
      if (
        (segmentVendor.name === SEGMENT_VENDOR_NAME.ENGINE_INSIGHTS && segmentVendorId === 0) ||
        segmentVendor.id === segmentVendorId
      )
        return segmentVendor
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    segmentVendor
      ? setSelectedSegmentVendor(segmentVendor)
      : setSelectedSegmentVendor(segmentVendorDropdownOptions[0])
  }

  const handleSave = (files) => {
    dispatch(clearErrors())
    handleSegmentCSVUpload(files[0])
    setOpen(false)
  }

  //invokes when vendor id changes
  const handleSegmentVendorIdChange = (e) => {
    defaultSegmentVendor(e.target.value)
  }

  const handleParseCSVDataError = (csvData) => {
    let errors = []
    if (csvData && csvData.length) {
      errors = [
        ...validateHeadersForRequiredValues(csvData, columnsToValidate),
        ...validateColumnsForEmptyValues(csvData, columnsToValidate),
        ...validateColumnsForDuplicateValues(csvData, columnsToValidate),
      ]
    }
    return errors
  }

  const handleSegmentCSVUpload = (file) => {
    dispatch(initSegmentCSVUpload())
    // FileReader is native javascript that is used to turn a file object (our csv)
    // into a string that we can parse
    const reader = new FileReader()

    // the code in this block happens after the reader has finished reading the file
    reader.onload = (e) => {
      const stringifiedCSV = e.target.result as string

      readString(stringifiedCSV, {
        delimiter: ",",
        header: true,
        skipEmptyLines: true,
        worker: true,
        complete: (results) => {
          setParsedCSVData(results.data)
        },
      })
      setSelectedFile(file)
    }
    reader.readAsText(file)
  }

  const renderSegmentVendorDropdownOptions = () =>
    segmentVendorDropdownOptions.length > 0 &&
    segmentVendorDropdownOptions.map((option) => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ))

  return (
    <Card>
      <CardContent style={{ padding: 24 }}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={4}>
            <div className={classes.vendorDropdown}>
              <InputLabel htmlFor="segment-vendor" className={classes.inlineLabel}>
                Vendor
              </InputLabel>
              <Select
                id="audience-vendor-id"
                label="vendorId"
                name="segmentVendorId"
                value={selectedSegmentVendor.id || ""}
                onChange={(e) => {
                  handleSegmentVendorIdChange(e)
                }}
                className={classes.dropDownInput}
                inputProps={{
                  "data-testid": "segment-vendor-id-input",
                }}
                disabled={isLoading || isSegmentVendorsLoading}
              >
                {renderSegmentVendorDropdownOptions()}
              </Select>
            </div>
          </Grid>
          <Grid item md={6} xs={6} data-testid={"segment-upload-button"}>
            <Button
              disabled={isLoading}
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
            >
              {"Upload Segment CSV"}
            </Button>
          </Grid>
          <DropzoneDialog
            acceptedFiles={[".csv", "text/csv"]}
            onSave={handleSave}
            submitButtonText={"submit"}
            open={open}
            onClose={() => setOpen(false)}
            showFileNames={true}
            useChipsForPreview
            previewText={"Selected File"}
            filesLimit={1}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(SegmentUpload)
