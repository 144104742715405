import { createStyles, Theme } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    queryField: {
      width: 500,
      marginRight: theme.spacing(1),
    },
    bulkOperations: {
      position: "relative",
    },
    bulkActions: {
      paddingLeft: 4,
      paddingRight: 4,
      marginTop: 6,
      position: "absolute",
      width: "100%",
      zIndex: 2,
      backgroundColor: theme.palette.background.default,
    },
    bulkAction: {
      marginLeft: theme.spacing(2),
    },
    avatar: {
      height: 42,
      width: 42,
      marginRight: theme.spacing(1),
    },
  })
}
