import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchPredictiveAudiences,
  fetchPredictiveAudiencesFailed,
  fetchPredictiveAudiencesSuccess,
  setPredictiveAudiencesLoading,
} from "../actions/predictiveAudienceActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchListPayload } from "../types/sharedTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

export function* fetchPredictiveAudiencesSaga(action: PayloadAction<FetchListPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/predictive-audiences/find`,
    data: action.payload,
  }
  yield put(clearErrors())
  yield put(setPredictiveAudiencesLoading(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page = parseInt(response.headers["x-pagination-page"], 10)
    const totalCount = parseInt(response.headers["x-pagination-total-count"], 10)
    yield put(
      fetchPredictiveAudiencesSuccess({
        predictiveAudiences: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchPredictiveAudiencesFailed, err)
  }
  yield put(setPredictiveAudiencesLoading(false))
}

export function* watchFetchPredictiveAudiences(): SagaIterator {
  yield takeEvery(fetchPredictiveAudiences, fetchPredictiveAudiencesSaga)
}
