import { DMP_SEGMENT_FEE_TYPE, ENTITY_FILTER_TYPE, OPERATION_TYPES_ENP } from "../../constants"
import {
  Deal,
  DealEntity,
  ExternalAudience,
  IabContentCategory,
  PredictiveAudience,
} from "../types/dealTypes"
import {
  clearDealDomains,
  clearDealInfo,
  clearPublishers,
  clearUploadedDealDomains,
  clearUploadedDealPublishers,
  createDeal,
  createDealFailed,
  createDealSuccess,
  fetchDealBidders,
  fetchDealBiddersFailed,
  fetchDealBiddersSuccess,
  fetchDealById,
  fetchDealByIdFailed,
  fetchDealByIdSuccess,
  fetchDealCountries,
  fetchDealCountriesFailed,
  fetchDealCountriesSuccess,
  fetchDealDomains,
  fetchDealDomainsFailed,
  fetchDealDomainsSuccess,
  fetchDealExternalAudiences,
  fetchDealExternalAudiencesFailed,
  fetchDealExternalAudiencesSuccess,
  fetchDealIabContentCategories,
  fetchDealIabContentCategoriesFailed,
  fetchDealIabContentCategoriesSuccess,
  fetchDealPredictiveAudiences,
  fetchDealPredictiveAudiencesFailed,
  fetchDealPredictiveAudiencesSuccess,
  fetchDealPublishers,
  fetchDealPublishersFailed,
  fetchDealPublishersSuccess,
  resetDealFormState,
  updateDeal,
  updateDealFailed,
  updateDealSuccess,
  uploadDealDomainsSuccess,
  uploadDealPublishersSuccess,
} from "../actions/dealActions"

import { Bidder } from "../types/sharedTypes"
import _ from "lodash"
import { createReducer } from "@reduxjs/toolkit"

interface InitialDealState {
  selectedDeal: Deal
  bidders?: Bidder[]
  isLoadingBidders: boolean

  countries: DealEntity[]
  isLoadingCountries: boolean

  domains: DealEntity[]
  uploadedDomains: DealEntity[]
  isLoadingDomains: boolean
  domainsPage: number

  publishers: DealEntity[]
  uploadedPublishers: DealEntity[]
  isLoadingPublishers: boolean
  publishersPage: number

  iabContentCategories: IabContentCategory[]
  isLoadingIabContentCategories: boolean

  isLoading: boolean
  isSaved: boolean
  isSubmitting: boolean
  externalAudiences: ExternalAudience[]
  isLoadingExternalAudiences: boolean
  predictiveAudiences: PredictiveAudience[]
  isLoadingPredictiveAudiences: boolean
}

export const initialState: InitialDealState = {
  selectedDeal: {
    id: null,
    externalId: null,
    notes: null,
    status: "",
    name: "",
    auctionType: "",
    bidder: { id: null, name: "" },
    mediaType: "",
    supplyType: "",
    deviceType: "",
    price: null,
    cmpCheck: false,
    cmpFee: null,
    iabContentCategories: [],
    iabContentCategoryFilterType: ENTITY_FILTER_TYPE.NONE,
    domainFilterType: ENTITY_FILTER_TYPE.NONE,
    publisherFilterType: ENTITY_FILTER_TYPE.NONE,
    wseatIds: [],
    createdAt: "",
    updatedAt: "",
    viewRate: null,
    vcrRate: null,
    countries: [],
    domains: [],
    publishers: [],
    dmpSegmentLogic: OPERATION_TYPES_ENP.NONE,
    segmentGroups: [],
    dmpSegmentFee: null,
    dmpSegmentFeeType: DMP_SEGMENT_FEE_TYPE.FIXED,
    externalAudienceId: null,
    externalAudience: { segmentId: null, segmentName: "", status: "" },
    predictiveAudienceId: null,
    predictiveAudience: { id: null, predictiveLogicalId: null, status: "", name: "" },
    predScoreThresholdType: null,
    predScoreThresholdMin: null,
    predScoreThresholdMax: null,
  },
  bidders: [],
  iabContentCategories: [],
  countries: [],
  domains: [],
  domainsPage: 0,
  uploadedDomains: [],
  publishers: [],
  publishersPage: 0,
  uploadedPublishers: [],
  isLoadingBidders: false,
  isLoadingCountries: false,
  isLoadingDomains: false,
  isLoadingPublishers: false,
  isLoadingIabContentCategories: false,
  isLoading: false,
  isSaved: false,
  isSubmitting: false,
  externalAudiences: [],
  isLoadingExternalAudiences: false,
  predictiveAudiences: [],
  isLoadingPredictiveAudiences: false,
}

export default createReducer(initialState, (builder) =>
  /*============================== Create Deal form action handlers ==============================*/
  builder
    .addCase(createDeal, (state) => {
      state.isSubmitting = true
    })
    .addCase(createDealSuccess, (state) => {
      state.isSaved = true
      state.isSubmitting = false
    })
    .addCase(createDealFailed, (state) => {
      state.isSubmitting = false
    })

    /*============================== Edit Deal form action handlers ==============================*/
    // Fetch Deal
    .addCase(fetchDealById, (state) => {
      state.isLoading = true
    })
    .addCase(fetchDealByIdSuccess, (state, action) => {
      state.isLoading = false
      state.selectedDeal = action.payload
      state.selectedDeal.dmpSegmentFee =
        action.payload.dmpSegmentFeeType === DMP_SEGMENT_FEE_TYPE.PERCENTAGE
          ? action.payload.dmpSegmentFeePercentage
          : action.payload.dmpSegmentFeeFixed
      state.selectedDeal.wseatIds = state.selectedDeal.wseatIds ?? []
    })
    .addCase(fetchDealByIdFailed, (state) => {
      state.isLoading = false
    })
    // Bidders
    .addCase(fetchDealBidders, (state) => {
      state.isLoadingBidders = true
    })
    .addCase(fetchDealBiddersSuccess, (state, action) => {
      state.isLoadingBidders = false
      state.bidders = action.payload
    })
    .addCase(fetchDealBiddersFailed, (state) => {
      state.isLoadingBidders = false
    })
    // Countries
    .addCase(fetchDealCountries, (state) => {
      state.isLoadingCountries = true
    })
    .addCase(fetchDealCountriesSuccess, (state, action) => {
      state.isLoadingCountries = false

      const customSortedCountries = _.cloneDeep(action.payload)
      customSortedCountries.unshift(
        customSortedCountries.splice(
          customSortedCountries.findIndex((country) => country.name === "United States"),
          1
        )[0]
      )
      state.countries = customSortedCountries
    })
    .addCase(fetchDealCountriesFailed, (state) => {
      state.isLoadingCountries = false
    })

    // iab content categories
    .addCase(fetchDealIabContentCategories, (state) => {
      state.isLoadingIabContentCategories = true
    })
    .addCase(fetchDealIabContentCategoriesSuccess, (state, action) => {
      state.isLoadingIabContentCategories = false
      state.iabContentCategories = action.payload
    })
    .addCase(fetchDealIabContentCategoriesFailed, (state) => {
      state.isLoadingIabContentCategories = false
    })
    // Domains
    .addCase(fetchDealDomains, (state) => {
      state.isLoadingDomains = true
    })
    .addCase(fetchDealDomainsSuccess, (state, action) => {
      state.isLoadingDomains = false
      state.domains = action.payload.clear
        ? action.payload.domains
        : state.domains.concat(action.payload.domains)
      state.domainsPage = action.payload.page
    })
    .addCase(fetchDealDomainsFailed, (state) => {
      state.isLoadingDomains = false
    })
    .addCase(uploadDealDomainsSuccess, (state, action) => {
      state.uploadedDomains = action.payload.map((name) => ({
        id: null,
        name,
      }))
    })
    .addCase(clearUploadedDealDomains, (state) => {
      state.uploadedDomains = initialState.uploadedDomains
    })
    .addCase(clearDealDomains, (state) => {
      state.domains = initialState.domains
    })
    // Publishers
    .addCase(fetchDealPublishers, (state) => {
      state.isLoadingPublishers = true
    })
    .addCase(fetchDealPublishersSuccess, (state, action) => {
      state.isLoadingPublishers = false
      state.publishers = action.payload.clear
        ? action.payload.publishers
        : state.publishers.concat(action.payload.publishers)
      state.publishersPage = action.payload.page
    })
    .addCase(fetchDealPublishersFailed, (state) => {
      state.isLoadingPublishers = false
    })
    .addCase(uploadDealPublishersSuccess, (state, action) => {
      state.uploadedPublishers = action.payload.map((id) => ({
        id: parseInt(id, 10),
        name: id,
      }))
    })
    .addCase(clearUploadedDealPublishers, (state) => {
      state.uploadedPublishers = initialState.uploadedPublishers
    })
    .addCase(clearPublishers, (state) => {
      // TODO: how we are currently calling this function is not the best solution
      // minor optimization to reduce re-renders
      state.publishers = state.publishers.length ? [] : state.publishers
    })

    // update deal
    .addCase(updateDeal, (state) => {
      state.isSubmitting = true
    })
    .addCase(updateDealSuccess, (state) => {
      state.isSaved = true
      state.isSubmitting = false
    })
    .addCase(updateDealFailed, (state) => {
      state.isSubmitting = false
    })

    .addCase(clearDealInfo, (state) => {
      state.selectedDeal = initialState.selectedDeal
    })
    .addCase(resetDealFormState, () => initialState)

    /*================================ External Audiences List action handlers ================================*/
    .addCase(fetchDealExternalAudiences, (state) => {
      state.isLoadingExternalAudiences = false
    })
    .addCase(fetchDealExternalAudiencesSuccess, (state, action) => {
      state.isLoadingExternalAudiences = false
      state.externalAudiences = action.payload
    })
    .addCase(fetchDealExternalAudiencesFailed, (state) => {
      state.isLoadingExternalAudiences = false
    })

    /*================================ Predictive Audiences List action handlers ================================*/
    .addCase(fetchDealPredictiveAudiences, (state) => {
      state.isLoadingPredictiveAudiences = true
    })
    .addCase(fetchDealPredictiveAudiencesSuccess, (state, action) => {
      state.isLoadingPredictiveAudiences = false
      state.predictiveAudiences = action.payload
    })
    .addCase(fetchDealPredictiveAudiencesFailed, (state) => {
      state.isLoadingPredictiveAudiences = false
    })
)
