import { createStyles, Theme } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    queryField: {
      width: 500,
      marginRight: theme.spacing(1),
    },
    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
    },
  })
}
