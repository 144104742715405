import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createCompany,
  createCompanyFailed,
  createCompanySuccess,
  setCompanyFormSubmittingFlag,
} from "../actions/companyActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { CreateCompanyPayload } from "../types/companyTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

export function* createCompanySaga(action: PayloadAction<CreateCompanyPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/companies`,
    data: {
      company: action.payload,
    },
  }
  yield put(clearErrors())
  yield put(setCompanyFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(createCompanySuccess(response.data))
  } catch (err) {
    yield handleSagaError(createCompanyFailed, err)
  }
  yield put(setCompanyFormSubmittingFlag(false))
}

export function* watchCreateCompany(): SagaIterator {
  yield takeEvery(createCompany, createCompanySaga)
}
