import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchCampaignBIToken,
  fetchCampaignBITokenFailed,
  fetchCampaignBITokenSuccess,
  setDataViewLoading,
} from "../actions/dataViewActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

const { DATA_VIEWS } = SECTION

export function* fetchCampaignBITokenSaga(action: PayloadAction<number>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${DATA_VIEWS}/campaign-bi/verify/${action.payload}`,
  }
  yield put(clearErrors())
  try {
    yield put(setDataViewLoading(true))
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchCampaignBITokenSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchCampaignBITokenFailed, err)
  } finally {
    yield put(setDataViewLoading(false))
  }
}

export function* watchFetchCampaignBIToken(): SagaIterator {
  yield takeEvery(fetchCampaignBIToken, fetchCampaignBITokenSaga)
}
