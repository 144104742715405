import {
  clearPredictiveAudiences,
  fetchPredictiveAudiences,
  fetchPredictiveAudiencesFailed,
  fetchPredictiveAudiencesSuccess,
} from "../actions/predictiveAudienceActions"

import { PaginatedComponentState } from "../types/sharedTypes"
import { PredictiveAudience } from "../types/predictiveAudienceTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialPredictiveAudienceState extends PaginatedComponentState {
  data: PredictiveAudience[]
  isLoading: boolean
}

const initialState: InitialPredictiveAudienceState = {
  data: [],
  isLoading: true,
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Predictive Audiences List action handlers ================================*/
    .addCase(fetchPredictiveAudiences, (state) => {
      state.isLoading = true
    })
    .addCase(fetchPredictiveAudiencesSuccess, (state, action) => {
      state.isLoading = false
      state.pager = action.payload.pager
      state.data = action.payload.predictiveAudiences
    })
    .addCase(fetchPredictiveAudiencesFailed, (state) => {
      state.isLoading = false
    })
    .addCase(clearPredictiveAudiences, (state) => {
      state.isLoading = false
      state.data = []
      state.pager = {
        totalCount: 0,
        page: 0,
      }
    })
)
