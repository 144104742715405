import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  setCompanyFormSubmittingFlag,
  updateCompanyEnableCampaignBI,
  updateCompanyEnableCampaignBIFailed,
  updateCompanyEnableCampaignBISuccess,
} from "../actions/companyActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { UpdateEnableCampaignBIPayload } from "../types/companyTypes"
import _ from "lodash"

// TODO: Add test for this saga

export function* updateCompanyEnableCampaignBISaga(
  action: PayloadAction<UpdateEnableCampaignBIPayload>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/companies/${action.payload.id}/campaign-bi-settings`,
    data: {
      companyCampaignBISettingsInputs: _.omit(action.payload, ["id"]),
    },
  }
  yield put(setCompanyFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateCompanyEnableCampaignBISuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateCompanyEnableCampaignBIFailed, err)
  }
  yield put(setCompanyFormSubmittingFlag(false))
}

export function* watchUpdateCompanyEnableCampaignBI(): SagaIterator {
  yield takeEvery(updateCompanyEnableCampaignBI, updateCompanyEnableCampaignBISaga)
}
