import { Alert, AlertTitle } from "@material-ui/lab"
// components
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  ListSubheader,
  Popover,
  Typography,
  withStyles,
} from "@material-ui/core"
import {
  MAX_SEGMENTS_PER_AUDIENCE,
  MAX_SEGMENT_GROUPS,
  OPERATION_TYPES_ENP,
  PERMISSIONS,
  POPULATION_TYPES,
  TARGET_TYPES,
} from "../../../../../../constants"
import React, { useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
// actions and types
import {
  clearAudiencePopulation,
  fetchAudiencePopulation,
} from "../../../../../../redux/actions/audienceActions"
import {
  clearPricingEstimateData,
  fetchPricingEstimate,
} from "../../../../../../redux/actions/pricingEstimateActions"
import {
  condenseSegmentGroupsForAudiencePopulation,
  getSegmentsTreeWithVendorId,
  getSegmentsWithVendorId,
} from "../../../../../../helpers/segmentHelpers"
// helper functions
import { generateRandomString, numberWithCommas } from "../../../../../../helpers/formatterHelper"

import AddIcon from "@material-ui/icons/Add"
import Avatar from "@material-ui/core/Avatar"
import DeleteIcon from "@material-ui/icons/Delete"
import Divider from "@material-ui/core/Divider"
import DropdownTreeSelectContainer from "../../../../../common/DropdownTreeSelectContainer"
import GroupAddIcon from "@material-ui/icons/GroupAdd"
import GroupIcon from "@material-ui/icons/Group"
import IconButton from "@material-ui/core/IconButton"
import InfoIcon from "@material-ui/icons/Info"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import PeopleAltSharpIcon from "@material-ui/icons/PeopleAltSharp"
import { SegmentGroup } from "../../../../../../redux/types/audienceTypes"
import SegmentGroupLogicSummary from "../../../../../common/SegmentGroupLogicSummary"
import { StyledReactComponent } from "../../../../../../redux/types/sharedTypes"
import { VendorOption } from "../../../../../../redux/types/segmentVendorTypes"
import { fetchSegments } from "../../../../../../redux/actions/segmentActions"
import { isPermitted } from "../../../../../../helpers/permissionHelper"
import produce from "immer"
import styles from "./styles"
import { useSelector } from "react-redux"

interface SegmentGroupsProps extends StyledReactComponent {
  isLoadingAudience: boolean
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setFieldValue: (string: string, value: any) => void
  audienceTargetType: string
  segmentGroupsValue: SegmentGroup[]
  totalSegmentCount: number
  segmentVendor: VendorOption
  disabled: boolean
}

const SegmentGroups = ({
  isLoadingAudience,
  classes,
  setFieldValue,
  audienceTargetType,
  segmentGroupsValue,
  totalSegmentCount,
  segmentVendor,
  disabled = false,
}: SegmentGroupsProps) => {
  const dispatch = useAppDispatch()
  const audiencePopulation = useSelector(
    (state: RootState) => state.audienceForm.audiencePopulation
  )
  const isSegmentsLoading = useSelector((state: RootState) => state.segments.isSegmentsLoading)
  const [selectedSegmentGroupId, setSelectedSegmentGroup] = useState("0")
  const [anchorElement, setAnchorElement] = useState(null)
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  // set the selected segment group to be first segment group in the event that
  // a) you are editing segment groups and you delete the segment group you were in the process of editing
  // b) you open the page for the first time and want to load the existing segment group

  // The reason why this check is necessary in the edit case is because a default segment group is
  // created for the create case with an id of "0".

  // An Audience with existing segment groups will overwrite the default group, and a segmentGroup object
  // with the id "0" will not exist, so we instead set the selectedSegmentGroup id to be the segment group
  // item at the first index of the existing segment groups
  const segmentsByVendorId = useSelector((state: RootState) => state.segments.segmentsByVendor)
  const segmentTreesByVendorId = useSelector(
    (state: RootState) => state.segments.segmentTreesByVendor
  )
  const [segmentData, setSegmentData] = useState([])
  const [segmentTree, setSegmentTree] = useState([])
  const [segmentGroupRemovalInfo, setSegmentGroupRemovalInfo] = useState({
    idToRemove: "",
    prevSelectedGroupId: "",
  })

  useEffect(() => {
    if (!isLoadingAudience && segmentVendor.id !== 0) {
      loadSegments(segmentVendor.id)
    }
  }, [segmentVendor.id])

  useEffect(() => {
    if (!isLoadingAudience && segmentVendor.id !== 0) {
      const currentSegments = getSegmentsWithVendorId(segmentsByVendorId, segmentVendor.id)
      const currentSegmentTree = getSegmentsTreeWithVendorId(
        segmentTreesByVendorId,
        segmentVendor.id
      )
      if (currentSegmentTree.length !== 0 && currentSegments.length !== 0) {
        setSegmentData(currentSegments)
        setSegmentTree(currentSegmentTree)
      }
    }
  }, [segmentVendor.id, segmentTreesByVendorId])

  //load the segments for selected vendor id
  const loadSegments = (vendorId) => {
    const currentSegments = getSegmentsWithVendorId(segmentsByVendorId, vendorId)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentSegments.length ||
      dispatch(
        fetchSegments({
          filters: {
            segmentVendorId: segmentVendor.id,
          },
        })
      )
  }

  useEffect(() => {
    if (disabled) return
    // condensing each segment group into a comma separated string value
    const segmentCondensedStringArray =
      condenseSegmentGroupsForAudiencePopulation(segmentGroupsValue)

    if (segmentCondensedStringArray.length) {
      if (
        isPermitted(PERMISSIONS.TOOLS.AUDIENCE.CREATE, currentUser) ||
        isPermitted(PERMISSIONS.TOOLS.AUDIENCE.UPDATE, currentUser)
      ) {
        dispatch(
          fetchPricingEstimate({
            groups: segmentCondensedStringArray,
            operation: OPERATION_TYPES_ENP.AND.toLowerCase(),
            vendorId: segmentVendor.id,
            //As per EMXD-5945, Need vendor name in pricingEstimateReducer. Not for api call but to perform Data fee changes for segment vendor in reducer. We can send vendor object as well with name and id, but it's not needed in api request so not changing payload type property object, only adding one new property which will be optional.
            vendorName: segmentVendor.name,
          })
        )
        dispatch(
          fetchAudiencePopulation({
            groups: segmentCondensedStringArray,
            operation: OPERATION_TYPES_ENP.AND.toLowerCase(),
            vendorId: segmentVendor.id,
          })
        )
      }
    } else {
      dispatch(clearPricingEstimateData())
      dispatch(clearAudiencePopulation())
    }

    // if no segment group is selected, set first group to be selected
    if (!segmentGroupsValue.some((segmentGroup) => segmentGroup.id === selectedSegmentGroupId)) {
      setSelectedSegmentGroup(segmentGroupsValue[0]?.id)
    }
  }, [segmentGroupsValue])

  useEffect(() => {
    const currentPopulation =
      audienceTargetType === POPULATION_TYPES.IP ? audiencePopulation.ip : audiencePopulation.cookie
    setFieldValue("population", currentPopulation)
  }, [audiencePopulation])

  const handlePopoverOpen = (event) => {
    setAnchorElement(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorElement(null)
  }

  const isOpen = !!anchorElement

  const getSelectedSegmentGroupIdIndex = () =>
    segmentGroupsValue.findIndex(({ id }) => selectedSegmentGroupId === id)

  // call when any SegmentGroup's delete icon clicked
  useEffect(() => {
    if (segmentGroupRemovalInfo.idToRemove.length) {
      removeSegmentGroup(segmentGroupRemovalInfo.idToRemove)
    }
  }, [segmentGroupRemovalInfo.idToRemove])

  // remove an entire segment group
  const removeSegmentGroup = (idToRemove: string) => {
    const updatedSegmentGroupsValue = segmentGroupsValue.filter(
      (segmentGroup) => idToRemove !== segmentGroup.id
    )
    setFieldValue("segmentGroups", updatedSegmentGroupsValue)
    if (
      idToRemove !== segmentGroupRemovalInfo.prevSelectedGroupId &&
      idToRemove !== updatedSegmentGroupsValue[0].id
    ) {
      setSelectedSegmentGroup(segmentGroupRemovalInfo.prevSelectedGroupId)
    } else {
      setSelectedSegmentGroup(updatedSegmentGroupsValue[0].id)
    }
    setSegmentGroupRemovalInfo({
      idToRemove: "",
      prevSelectedGroupId: "",
    })
  }

  // set SelectedSegmentGroup to group which need to be deleted
  const handleRemoveSegmentGroupClick = (idToRemove: string) => {
    setSegmentGroupRemovalInfo({
      idToRemove: idToRemove,
      prevSelectedGroupId: selectedSegmentGroupId,
    })
    setSelectedSegmentGroup(idToRemove)
  }

  // create a new segment group
  const handleAddSegmentGroupClick = () => {
    if (segmentGroupsValue.length >= MAX_SEGMENT_GROUPS) return

    const newSegmentGroup = {
      name: "Group",
      id: generateRandomString(),
      segments: [],
    }

    const updatedSegmentGroupsValue = [...segmentGroupsValue, newSegmentGroup]

    setFieldValue("segmentGroups", updatedSegmentGroupsValue)
    setSelectedSegmentGroup(newSegmentGroup.id)
  }

  const segmentGroupInfoHelperText = () => {
    return (
      <div className={classes.popoverContent}>
        <ListItem key={`item-popup-infoText`}>
          <div>
            <Typography variant="caption" component="p" gutterBottom>
              When building Segment Groups using “AND” logic, the user must
            </Typography>
            <Typography variant="caption" component="p" gutterBottom>
              match all of the targeted segments and this narrows your reach.
            </Typography>
            <Typography variant="caption" component="p" gutterBottom>
              When building groups using “OR” logic, the user can be present
            </Typography>
            <Typography variant="caption" component="p">
              in any of the segments and this will broaden your reach.
            </Typography>
          </div>
        </ListItem>
      </div>
    )
  }

  // avoid calling this expensive function call multiple times due to re-rendering by formik
  const renderSegmentListItem = (segmentChoice: string, index: number) => {
    const segmentObject = segmentData.find(({ externalId }) => externalId === segmentChoice)

    if (segmentObject) {
      const segmentIPPopulation = segmentObject.populations.find(
        (segmentObject) => segmentObject.segmentTargetTypeId === TARGET_TYPES.IP
      ).population
      return (
        <ListItem key={`item-${segmentObject.externalId}-${index}-${selectedSegmentGroupId}`}>
          <ListItemAvatar>
            <Avatar>
              <PeopleAltSharpIcon color="secondary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            data-testid={`segment-id-${segmentObject.externalId}`}
            primary={segmentObject.hierarchy.join(" > ")}
            secondary={`Segment ID: ${segmentObject.externalId}, Population: ${numberWithCommas(
              segmentIPPopulation
            )}`}
          />
        </ListItem>
      )
    }
  }
  const renderSegmentListItems = () => {
    if (!isLoadingAudience) {
      const currentSegmentGroup = segmentGroupsValue[getSelectedSegmentGroupIdIndex()]
      const segmentsInSegmentGroup = currentSegmentGroup?.segments

      if (segmentsInSegmentGroup?.length) {
        return segmentsInSegmentGroup.map((segment, i) =>
          segment ? renderSegmentListItem(segment, i) : null
        )
      }
    }
    return null
  }

  const renderPopover = () => {
    return (
      <Popover
        id={`popup-info`}
        className={classes.popover}
        open={isOpen}
        data-testid={`segment-groups-popup-info`}
        style={{ pointerEvents: "none" }}
        anchorEl={anchorElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        {segmentGroupInfoHelperText()}
      </Popover>
    )
  }

  const renderSegmentGroupArea = () => {
    return (
      <Grid item md={4}>
        <Card className={classes.segmentGroupAreaCursor}>
          <CardContent>
            <List
              data-testid="audience-segment-groups"
              subheader={
                <ListSubheader className={classes.displayFlex}>
                  <Typography className={classes.segmentGroup}>
                    Segment Groups
                    <InfoIcon
                      className="infoIcon"
                      fontSize="small"
                      onMouseLeave={handlePopoverClose}
                      onMouseEnter={(e) => handlePopoverOpen(e)}
                    />
                  </Typography>
                </ListSubheader>
              }
            >
              {segmentGroupsValue[0]?.name &&
                !isLoadingAudience &&
                segmentGroupsValue.map((segmentGroup: SegmentGroup, i: number) => (
                  <ListItem
                    key={`${segmentGroup.id}-${i}`}
                    selected={selectedSegmentGroupId === segmentGroup.id}
                    onClick={() => setSelectedSegmentGroup(segmentGroup.id)}
                    className={classes.singleSegmentGroup}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <GroupIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      key={`segment-group-id-${segmentGroup.id}`}
                      data-testid={`segment-group-id-${segmentGroup.id}`}
                      primary={`${segmentGroup.name} ${String.fromCharCode(65 + i)} (${
                        segmentGroup.segments?.length
                      })`}
                    />
                    {/* Remove segment group button*/}
                    {!disabled && (
                      <ListItemSecondaryAction data-testid="audience-remove-groups">
                        {segmentGroupsValue.length > 1 && (
                          <IconButton
                            edge="end"
                            data-testid={`segment-group-id-remove-${segmentGroup.id}`}
                            onClick={() => handleRemoveSegmentGroupClick(segmentGroup.id)}
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              {/* Add segment group button*/}
              {!disabled && (
                <ListItem key={`group-maker`} onClick={handleAddSegmentGroupClick}>
                  <ListItemAvatar>
                    <Avatar>
                      <GroupAddIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText primary={`Add Group`} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      data-testid="segment-create-group"
                      onClick={handleAddSegmentGroupClick}
                      aria-label="new group"
                    >
                      <AddIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              <Divider />
              <ListSubheader className={classes.listSubheader}>
                <Typography
                  color={totalSegmentCount > 10 ? "error" : "initial"}
                  className={classes.segmentGroup}
                >
                  {`Total Segments (${totalSegmentCount}/10)`}
                </Typography>
              </ListSubheader>
              <Divider />
              <ListSubheader className={classes.listSubheader}>
                <Typography className={classes.segmentGroup}>Logic Summary</Typography>
              </ListSubheader>
              {segmentGroupsValue[0]?.name && !isLoadingAudience && (
                <SegmentGroupLogicSummary
                  segmentGroupsValue={segmentGroupsValue}
                  segmentData={segmentData}
                />
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  /**
   * Adding selected segment to segment group
   *
   * @param segmentObjects
   */
  const handleSegmentSelect = (segmentObjects) => {
    const selectedSegmentGroupIndex = getSelectedSegmentGroupIdIndex()

    const updatedSegmentExternalIds = segmentObjects.map(
      (segmentObject) => segmentObject.externalId
    )

    const updatedSegmentGroupsValue = produce(segmentGroupsValue, (segmentGroupsValueCopy) => {
      const segmentGroupToUpdate = segmentGroupsValueCopy[selectedSegmentGroupIndex]
      segmentGroupToUpdate.segments = updatedSegmentExternalIds
    })

    setFieldValue("segmentGroups", updatedSegmentGroupsValue)
  }

  const renderSegmentSelectionArea = () => {
    const selectedSegmentGroup = segmentGroupsValue[getSelectedSegmentGroupIdIndex()]
    return (
      <Grid item md={8}>
        {!isLoadingAudience && !isSegmentsLoading ? (
          <Grid item md={12}>
            <DropdownTreeSelectContainer
              data={segmentTree}
              onChange={(currentNode, selectedNodes) => {
                handleSegmentSelect(selectedNodes)
              }}
              keepTreeOnSearch={true}
              keepChildrenOnSearch={true}
              texts={{
                placeholder: `${!disabled ? "Select " : ""}Segments`,
              }}
              mode={"multiSelect"}
              selectedSegmentGroup={selectedSegmentGroup}
              disabled={disabled}
            />
            <List data-testid="audience-selected-segments">{renderSegmentListItems()}</List>
          </Grid>
        ) : (
          <Card>
            <CardContent className={classes.loadingForm}>
              <CircularProgress />
            </CardContent>
          </Card>
        )}
      </Grid>
    )
  }

  return (
    <div className={classes.segmentGroupContainer}>
      {totalSegmentCount >= MAX_SEGMENTS_PER_AUDIENCE && (
        <Box mb={3}>
          <Alert severity="warning" data-testid={"reach-or-exceed-segment-max-limit-warning"}>
            <AlertTitle>Warning</AlertTitle>
            {`You have ${
              totalSegmentCount > MAX_SEGMENTS_PER_AUDIENCE ? "exceeded" : "reached"
            } the limit of 10 segments for this Audience. Please work with your Cadent
            representative to learn more about this limit and alternative ways to design
            your Audience.`}
          </Alert>
        </Box>
      )}
      {renderPopover()}
      <Grid container spacing={3}>
        {renderSegmentGroupArea()}
        {renderSegmentSelectionArea()}
      </Grid>
    </div>
  )
}

export default withStyles(styles)(SegmentGroups)
