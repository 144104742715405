import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  updateAudience,
  updateAudienceFailed,
  updateAudienceSuccess,
} from "../actions/audienceActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { UpdateAudiencePayload } from "../types/audienceTypes"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

export function* updateAudienceSaga(action: PayloadAction<UpdateAudiencePayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/audiences/${action.payload.id}`,
    data: {
      audience: _.omit(action.payload, ["id"]),
    },
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateAudienceSuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateAudienceFailed, err)
  }
}

export function* watchUpdateAudience(): SagaIterator {
  yield takeEvery(updateAudience, updateAudienceSaga)
}
