import { FetchListPayload } from "../types/sharedTypes"
import { FetchPredictiveAudiencesSuccessResponse } from "../types/predictiveAudienceTypes"
import { createAction } from "@reduxjs/toolkit"

/* ===================================== Predictive Audiences List actions ===================================== */
export const fetchPredictiveAudiences = createAction<FetchListPayload>(
  "Effects_FETCH_PREDICTIVE_AUDIENCES"
)
export const fetchPredictiveAudiencesSuccess =
  createAction<FetchPredictiveAudiencesSuccessResponse>("FETCH_PREDICTIVE_AUDIENCES_SUCCESS")
export const fetchPredictiveAudiencesFailed = createAction<string>(
  "FETCH_PREDICTIVE_AUDIENCES_FAILED"
)
export const clearPredictiveAudiences = createAction("CLEAR_PredictiveAudiences")

export const setPredictiveAudiencesLoading = createAction<boolean>(
  "SET_PREDICTIVE_AUDIENCES_LOADING"
)
